import React, { useEffect, useState } from "react";
import background from "../img/sfondo1.jpeg";
import { useNavigate } from "react-router-dom";
import "../index.scss";
import { loginLocal, loginSession } from "./SafeRoute";
import api from "./api";
import logo from "../img/iconFondamenta.png";
const Login = () => {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [remember, setRemember] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  const navigate = useNavigate();
  const [screenSize, getDimension] = useState(window.innerWidth);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const onSubmit = async (e) => {
    e.preventDefault();
    const md5 = require("md5");
    let gID = 0;
    await api
      .post("api/login", {
        username: name,
        password: md5(pass),
      })
      .then((res) => {
        let userAdmin = false;
        const userData = res.data.utente.data;
        gID = res.data.utente.data[0].groupID;
        const token = res.data.utente.token;
        localStorage.setItem("jwt", token);
        localStorage.setItem("Group", gID);
        localStorage.setItem("nomeUtente", res.data.utente.data[0].memberID);
        if (userData.length <= 0) {
          const error = (userData && userData.message) || res.status;
          return Promise.reject(error);
          //controlla se è admin
        } else if (gID === 2) {
          userAdmin = true;
        }
        //controlla se è da ricordare
        if (remember) {
          loginLocal(remember, res.data.utente.token, userAdmin);
        } else {
          loginSession(res.data.utente.token, userAdmin);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          alert("Username o password errati!");
        }
        window.location.reload();
      });

    await api
      .get("api/groupsPermissions", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: gID },
      })
      .then((res) => {
        let permessi = {};

        res.data.data.map((data) => (permessi[data.tableName] = data));

        localStorage.setItem("permission", JSON.stringify(permessi));
      });
    navigate("/home");
  };
  return (
    <div
      className="row flex-md-nowrap flex-wrap gx-0"
      style={{
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="col-lg-8 d-lg-flex d-none col-0"></div>
      <div className="d-flex align-items-middle pt-5 mt-lg-0 mt-5 col-lg-4 col-12">
        <div className="d-flex p-4 pb-5 bg-light rounded-3  col-md-6 col-lg-10 col-11 my-auto mx-auto">
          <form className="row mx-3" onSubmit={onSubmit}>
            <div className="d-flex justify-content-center mb-1">
              <img
                style={{
                  height: screenSize < 768 ? "100px" : "80px",
                  width: screenSize < 768 ? "100px" : "80px",
                }}
                src={logo}
                alt="Fondamenta"
              />
            </div>
            <div className="">
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Username"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="">
              <input
                type="password"
                className="form-control mb-2 "
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <label className="control-label mb-3">
              <input
                type="checkbox"
                name="rememberMe"
                id="rememberMe"
                onChange={(e) => setRemember(!remember)}
                style={{ marginRight: "5px" }}
              />
              Ricordami
            </label>
            <label className="control-label">
              <button
                type="submit"
                className="btn bg-green text-white mb-3 p-2 fs-6 fw-bold w-100"
              >
                Accedi
              </button>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
