import React, { useEffect, useState } from "react";
import api from "./api";
import { useNavigate } from "react-router-dom";
import { CButton } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import ReusableButton from "./Reusable/Buttons";

const columns = [
  {
    field: "Descrizione",
    headerName: "Descrizione",
    flex: 1,
  },
];

const ElencoManutenzioni = () => {
  const [elencoManutenzioni, setElencoManutenzioni] = useState([]);
  const [elencoManutenzioniVisibili, setElencoManutenzioniVisibili] = useState(
    []
  );
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  const handleClick = (percorso) => {
    navigate(percorso);
  };
  const handleChange = (e) => {
    var elencoManutenzioniX = [];
    var i;
    for (i = 0; i < elencoManutenzioni.length; i++) {
      if (
        elencoManutenzioni[i].Descrizione?.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      ) {
        elencoManutenzioniX.push(elencoManutenzioni[i]);
      }
    }
    setElencoManutenzioniVisibili(elencoManutenzioniX);
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/elencoManutenzioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let elenco = res.data.data;
          setElencoManutenzioni(elenco);
          setElencoManutenzioniVisibili(elenco);
          setReady(true);
        });
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">MANUTENZIONI</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center  col">
            <div className="d-flex col-6 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className="mx-3"
              onClick={() =>
                navigate(`/home/elenco-manutenzioni/:id`, {
                  state: { noleggi: [], operazione: "Nuovo" },
                })
              }
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={elencoManutenzioniVisibili}
            columns={columns}
            getRowId={(row) => row.ID}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              const dataElenchi = elencoManutenzioni.filter(
                (elenco) => elenco.ID === params.row.ID
              );
              navigate(`/home/elenco-manutenzioni/${dataElenchi[0].ID}`, {
                state: {
                  elencoManutenzioni: dataElenchi[0],
                  operazione: "Modifica",
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

/* function NavigationPages(props) {
    const navigate = useNavigate();
    return <ElencoManutenzioni {...props} navigate={navigate} />;
} */

export default ElencoManutenzioni;
