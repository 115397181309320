import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormSelect,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
  CFormTextarea,
  CFormSwitch,
} from "@coreui/react";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Divider from "@mui/material/Divider";
import enLocale from "date-fns/locale/en-IE";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from "moment";
import TextField from "@mui/material/TextField";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Autocomplete } from "@mui/material";

const provList = [
  {
    code: "AG",
    name: "Agrigento",
  },
  {
    code: "AL",
    name: "Alessandria",
  },
  {
    code: "AN",
    name: "Ancona",
  },
  {
    code: "AO",
    name: "Aosta",
  },
  {
    code: "AR",
    name: "Arezzo",
  },
  {
    code: "AP",
    name: "Ascoli Piceno",
  },
  {
    code: "AT",
    name: "Asti",
  },
  {
    code: "AV",
    name: "Avellino",
  },
  {
    code: "BA",
    name: "Bari",
  },
  {
    code: "BT",
    name: "Barletta-Andria-Trani",
  },
  {
    code: "BL",
    name: "Belluno",
  },
  {
    code: "BN",
    name: "Benevento",
  },
  {
    code: "BG",
    name: "Bergamo",
  },
  {
    code: "BI",
    name: "Biella",
  },
  {
    code: "BO",
    name: "Bologna",
  },
  {
    code: "BZ",
    name: "Bolzano",
  },
  {
    code: "BS",
    name: "Brescia",
  },
  {
    code: "BR",
    name: "Brindisi",
  },
  {
    code: "CA",
    name: "Cagliari",
  },
  {
    code: "CL",
    name: "Caltanissetta",
  },
  {
    code: "CB",
    name: "Campobasso",
  },
  {
    code: "CE",
    name: "Caserta",
  },
  {
    code: "CT",
    name: "Catania",
  },
  {
    code: "CZ",
    name: "Catanzaro",
  },
  {
    code: "CH",
    name: "Chieti",
  },
  {
    code: "CO",
    name: "Como",
  },
  {
    code: "CS",
    name: "Cosenza",
  },
  {
    code: "CR",
    name: "Cremona",
  },
  {
    code: "KR",
    name: "Crotone",
  },
  {
    code: "CN",
    name: "Cuneo",
  },
  {
    code: "EN",
    name: "Enna",
  },
  {
    code: "FM",
    name: "Fermo",
  },
  {
    code: "FE",
    name: "Ferrara",
  },
  {
    code: "FI",
    name: "Firenze",
  },
  {
    code: "FG",
    name: "Foggia",
  },
  {
    code: "FC",
    name: "Forlì-Cesena",
  },
  {
    code: "FR",
    name: "Frosinone",
  },
  {
    code: "GE",
    name: "Genova",
  },
  {
    code: "GO",
    name: "Gorizia",
  },
  {
    code: "GR",
    name: "Grosseto",
  },
  {
    code: "IM",
    name: "Imperia",
  },
  {
    code: "IS",
    name: "Isernia",
  },
  {
    code: "AQ",
    name: "L’aquila",
  },
  {
    code: "SP",
    name: "La spezia",
  },
  {
    code: "LT",
    name: "Latina",
  },
  {
    code: "LE",
    name: "Lecce",
  },
  {
    code: "LC",
    name: "Lecco",
  },
  {
    code: "LI",
    name: "Livorno",
  },
  {
    code: "LO",
    name: "Lodi",
  },
  {
    code: "LU",
    name: "Lucca",
  },
  {
    code: "MC",
    name: "Macerata",
  },
  {
    code: "MN",
    name: "Mantova",
  },
  {
    code: "MS",
    name: "Massa-Carrara",
  },
  {
    code: "MT",
    name: "Matera",
  },
  {
    code: "ME",
    name: "Messina",
  },
  {
    code: "MI",
    name: "Milano",
  },
  {
    code: "MO",
    name: "Modena",
  },
  {
    code: "MB",
    name: "Monza e Brianza",
  },
  {
    code: "NA",
    name: "Napoli",
  },
  {
    code: "NO",
    name: "Novara",
  },
  {
    code: "NU",
    name: "Nuoro",
  },
  {
    code: "OR",
    name: "Oristano",
  },
  {
    code: "PD",
    name: "Padova",
  },
  {
    code: "PA",
    name: "Palermo",
  },
  {
    code: "PR",
    name: "Parma",
  },
  {
    code: "PV",
    name: "Pavia",
  },
  {
    code: "PG",
    name: "Perugia",
  },
  {
    code: "PU",
    name: "Pesaro e Urbino",
  },
  {
    code: "PE",
    name: "Pescara",
  },
  {
    code: "PC",
    name: "Piacenza",
  },
  {
    code: "PI",
    name: "Pisa",
  },
  {
    code: "PT",
    name: "Pistoia",
  },
  {
    code: "PN",
    name: "Pordenone",
  },
  {
    code: "PZ",
    name: "Potenza",
  },
  {
    code: "PO",
    name: "Prato",
  },
  {
    code: "RG",
    name: "Ragusa",
  },
  {
    code: "RA",
    name: "Ravenna",
  },
  {
    code: "RC",
    name: "Reggio Calabria",
  },
  {
    code: "RE",
    name: "Reggio Emilia",
  },
  {
    code: "RI",
    name: "Rieti",
  },
  {
    code: "RN",
    name: "Rimini",
  },
  {
    code: "RM",
    name: "Roma",
  },
  {
    code: "RO",
    name: "Rovigo",
  },
  {
    code: "SA",
    name: "Salerno",
  },
  {
    code: "SS",
    name: "Sassari",
  },
  {
    code: "SV",
    name: "Savona",
  },
  {
    code: "SI",
    name: "Siena",
  },
  {
    code: "SR",
    name: "Siracusa",
  },
  {
    code: "SO",
    name: "Sondrio",
  },
  {
    code: "SU",
    name: "Sud Sardegna",
  },
  {
    code: "TA",
    name: "Taranto",
  },
  {
    code: "TE",
    name: "Teramo",
  },
  {
    code: "TR",
    name: "Terni",
  },
  {
    code: "TO",
    name: "Torino",
  },
  {
    code: "TP",
    name: "Trapani",
  },
  {
    code: "TN",
    name: "Trento",
  },
  {
    code: "TV",
    name: "Treviso",
  },
  {
    code: "TS",
    name: "Trieste",
  },
  {
    code: "UD",
    name: "Udine",
  },
  {
    code: "VA",
    name: "Varese",
  },
  {
    code: "VE",
    name: "Venezia",
  },
  {
    code: "VB",
    name: "Verbano-Cusio-Ossola",
  },
  {
    code: "VC",
    name: "Vercelli",
  },
  {
    code: "VR",
    name: "Verona",
  },
  {
    code: "VV",
    name: "Vibo valentia",
  },
  {
    code: "VI",
    name: "Vicenza",
  },
  {
    code: "VT",
    name: "Viterbo",
  },
];

const columnsWbs = [
  {
    field: "id",
    headerName: "",
    hide: true,
  },
  {
    field: "descrizione",
    headerName: "Wbs",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];
const columnsLavorazioni = [
  {
    field: "id",
    headerName: "Lavorazione",
    flex: 1,
    hide: true,
  },
  {
    field: "articolo",
    headerName: "Articolo",
    flex: 1,
  },
  {
    field: "descrizione",
    headerName: "Lavorazione",
    flex: 1,
  },
  {
    field: "prezzo",
    headerName: "Prezzo",
    flex: 1,
  },
  {
    field: "descrizione_wbs",
    headerName: "Wbs",
    flex: 1,
  },
];

const DettaglioCantiere = (props) => {
  const [lavorazioni, setLavorazioni] = useState([]);
  const [lavorazioniAuto, setLavorazioniAuto] = useState([]);
  const [descrzioneLavorazioni, setDescrzioneLavorazioni] = useState([]);
  const [provListed, setProvListed] = useState("");

  const [wbsAuto, setWbsAuto] = useState([]);
  const [wbs, setWbs] = useState([]);
  const [wbsSelezionate, setWbsSelezionate] = useState([]);
  const [referenti, setReferenti] = useState([]);
  const [referentiSel, setReferentiSel] = useState([]);
  const [lavorazioniSelezionate, setLavorazioniSelezionate] = useState({
    id: "",
    commessa: "",
    lavorazione: "",
    prezzo: "",
    quantita: "",
    descrizione: "",
  });
  const [cantiere, setCantiere] = useState({
    commessa: "",
    committente: "",
    comune: "",
    indirizzo: "",
    referente: "",
    googleIDE: "",
    data_inizio: null,
    data_fine: null,
    via: "",
    stato: "",
    provincia: "",
    colore: "",
    cig: "",
    cup: "",
    lavorazioni: [],
    protocolloL: 0,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [eliminaLav, setEliminaLav] = useState(0);
  const [eliminaWbs, setEliminaWbs] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleChange = (event) => {
    let can = { ...cantiere };
    can[event.target.name] = event.target.value;
    setCantiere(can);
  };
  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare il Cantiere?"
    );
    if (isExecuted) {
      await api.delete("/api/cantieri", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          commessa: cantiere.commessa,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Cantiere eliminato con successo",
      });
      setOpenSnack(true);
      //navigate(-1);
    }
  };

  const emailSender = async (can) => {
    let lav = "";
    for (let i = 0; i < cantiere.lavorazioni.length; i++) {
      lav += cantiere.lavorazioni[i];
      if (i !== cantiere.lavorazioni.length - 1) {
        lav += ", ";
      }
    }
    let mailOk = window.confirm("Desideri inviare la mail?");
    if (mailOk) {
      //CONTROLLO
      const responseMail = await api.post(
        "api/Mail",
        {
          dataCantiere: can,
          lavorazioni: lav,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Mail inviata correttamente",
      });
      setOpenSnack(true);
    }
  };

  const handleConfirmButton = async () => {
    let operazione = "";
    for (let i = 0; i < cantiere.lavorazioni.length; i++) {
      operazione += cantiere.lavorazioni[i];
      if (i !== cantiere.lavorazioni.length - 1) {
        operazione += ", ";
      }
    }
    let can = { ...cantiere };
    can["utente"] = localStorage.getItem("nomeUtente");
    can["lavorazioni"] = operazione;
    /* if (can.data_inizio !== "" && can.data_inizio !== null) {
      can.data_inizio = moment(can.data_inizio).local()
            .format("YYYY-MM-DD");;
    }
    if (can.data_fine !== "" && can.data_fine !== null) {
      can.data_fine = "";
    } */
    if (location.state.operazione === "Nuovo") {
      try {
        const response = await api.post(
          "api/cantieri",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", cantiere: can },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        location.state.operazione = "Modifica";

        emailSender(can);

        setSnack({
          severity: "success",
          messaggio: "Cantiere creato correttamente",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Impossibile creare il cantiere",
        });
        setOpenSnack(true);
      }
    } else {
      let canDataCorretta = { ...can };
      if (can.data_inizio !== null) {
        canDataCorretta["data_inizio"] = moment(can.data_inizio).format(
          "YYYY-MM-DD"
        );
      }
      if (can.data_fine !== null) {
        canDataCorretta["data_fine"] = moment(can.data_fine).format(
          "YYYY-MM-DD"
        );
      }
      await api.put(
        "api/cantieri",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", cantiere: canDataCorretta },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }

  const handleChangeMultipleSelect = (event) => {
    let can = { ...cantiere };
    can[event.target.name] = event.target.value;
    setCantiere(can);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      const resLavorazioni = await api.get("api/lavorazioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const lav = resLavorazioni.data.data.map((el) => el.descrizione);
      setDescrzioneLavorazioni(lav);

      if (location.state.operazione === "Modifica") {
        await api
          .get("api/cantieri_lavorazioni", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((res) => {
            const cantLav = res.data.data.filter(
              (el) => el.commessa === location.state.cantiere.commessa
            );
            setLavorazioni(cantLav);
          });
      }
      if (location.state.operazione === "Modifica") {
        await api
          .get("api/cantieri_wbs", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((res) => {
            let elenco = res.data.data;
            const cantWbs = res.data.data.filter(
              (el) => el.commessa === location.state.cantiere.commessa
            );
            setWbs(cantWbs);
          });
      }
      const res = await api.get("api/referenti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let elencoRef = res.data.data;

      let ref = [];
      ref.push({ label: "Seleziona...", value: "" });
      for (let i = 0; i < elencoRef.length; i++) {
        ref.push({
          indexSel: elencoRef.indexOf(elencoRef[i]),
          label: elencoRef[i].Nome + " - " + elencoRef[i].Telefono,
          value: elencoRef[i].ID,
        });
      }
      setReferenti(ref);
      if (location.state.operazione === "Modifica") {
        let refSel = ref.filter(
          (el) => el.value === parseInt(location.state.cantiere.referente)
        );
        setReferentiSel(refSel);
      }

      await api
        .get("api/lavorazioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let lav = [];
          lav.push({ label: "SELEZIONA...", value: "" });
          for (let i = 0; i < res.data.data.length; i++) {
            lav.push({
              label: res.data.data[i].descrizione,
              value: res.data.data[i].id,
            });
          }
          setLavorazioniAuto(lav);
        });

      api
        .get("api/wbs", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let wbs = [];
          wbs.push({ label: "SELEZIONA...", value: "" });
          for (let i = 0; i < res.data.data.length; i++) {
            wbs.push({
              label: res.data.data[i].descrizione,
              value: res.data.data[i].id,
            });
          }
          setWbsAuto(wbs);
        });
      if (location.state.operazione === "Modifica") {
        const cantiere = location.state.cantiere;
        if (cantiere.colore === "") {
          cantiere.colore = `#${Math.floor(Math.random() * 16777215).toString(
            16
          )}`;
        }
        if (cantiere.lavorazioni !== null && cantiere.lavorazioni !== "") {
          cantiere.lavorazioni = cantiere.lavorazioni.split(", ");
        } else {
          cantiere.lavorazioni = [];
        }
        setCantiere(cantiere);
      } else {
        setCantiere(cantiere);
      }
      const listedProv = provList.map((el) => el.code);
      setProvListed(listedProv);
    };
    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Cantieri</span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.state.search !== undefined) {
                  navigate("/home/cantieri", {
                    state: {
                      search: location.state.search,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>

          {/* BODY */}
          <div className="card-body">
            <div className="col">
              <div className="row d-flex">
                <div className="col-md-3">
                  <CFormFloating className="mb-md-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      name="commessa"
                      maxlength="4"
                      value={cantiere.commessa}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Commessa</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="d-flex p-2 justify-content-center col-md-3">
                  <input
                    className="rounded border"
                    style={{ height: "40px", width: "40px" }}
                    type="color"
                    name="colore"
                    id="DescrizioneInput"
                    value={cantiere.colore}
                    onChange={(event) => {
                      let can = { ...cantiere };
                      can.colore = event.target.value;
                      setCantiere(can);
                    }}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).lavorazioni
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      name="committente"
                      value={cantiere.committente}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Committente</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.comune}
                      name="comune"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Comune</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.indirizzo}
                      name="indirizzo"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Indirizzo</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.stato}
                      name="stato"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Stato</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <Autocomplete
                      value={cantiere.provincia}
                      onChange={(event, newValue) => {
                        let can = { ...cantiere };
                        can.provincia = newValue;
                        setCantiere(can);
                      }}
                      name="provincia"
                      options={provListed}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Provincia" />
                      )}
                    />
                  </CFormFloating>
                  {/* <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.provincia}
                      name="provincia"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Provincia</CFormLabel>
                  </CFormFloating> */}
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-md-6">
                  <CFormFloating
                    className="col-12 mb-3"
                    style={{ width: "100%" }}
                  >
                    <CFormSelect
                      id="floatingSelect"
                      options={referenti}
                      value={parseInt(cantiere.referente)}
                      onChange={(
                        e /* handleSelectReferenti(e, "referente", 0) */
                      ) => {
                        let indexSel = e.nativeEvent.target.selectedIndex;
                        let cant = { ...cantiere };
                        cant["referente"] = e.target.value;
                        setCantiere(cant);
                        setReferentiSel(lavorazioni[indexSel]);
                      }}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Referente</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.googleIDE}
                      name="googleIDE"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Google Maps</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <div className="d-flex row justify-content-center">
                <div className="col-md-6 mb-2">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={enLocale}
                  >
                    <DatePicker
                      label="data inizio"
                      value={cantiere.data_inizio}
                      renderInput={(params) => (
                        <TextField {...params} className="col-12" />
                      )}
                      onChange={(newValue) => {
                        let can = { ...cantiere };
                        can["data_inizio"] = moment(newValue)
                          .local()
                          .format("YYYY-MM-DD");
                        setCantiere(can);
                      }}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </LocalizationProvider>
                </div>

                <div className="col-md-6">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={enLocale}
                  >
                    <DatePicker
                      label="data fine"
                      value={cantiere.data_fine}
                      renderInput={(params) => (
                        <TextField {...params} className="col-12" />
                      )}
                      onChange={(newValue) => {
                        let can = { ...cantiere };
                        can["data_fine"] = moment(newValue)
                          .local()
                          .format("YYYY-MM-DD");
                        setCantiere(can);
                      }}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="d-flex row justify-content-center">
                <div className="col-md-6 mb-2">
                  <CFormFloating>
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.cig}
                      name="cig"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>CIG</CFormLabel>
                  </CFormFloating>
                </div>

                <div className="col-md-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      value={cantiere.cup}
                      name="cup"
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>CUP</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <div className="d-flex row justify-content-center">
                {descrzioneLavorazioni ? (
                  <FormControl
                    className="col-md-6 col-12 d-flex items-align-center"
                    style={{ padding: "0 12px" }}
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Lavorazioni
                    </InputLabel>

                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-multiple-name-label"
                      name="lavorazioni"
                      id="demo-multiple-name"
                      multiple
                      value={cantiere.lavorazioni}
                      onChange={handleChangeMultipleSelect}
                      input={<OutlinedInput label="Lavorazioni" />}
                    >
                      {descrzioneLavorazioni.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                <div className="col-md-6 d-flex items-align-center">
                  <FormControlLabel
                    checked={cantiere.protocolloL === 1}
                    onChange={(e) => {
                      let can = { ...cantiere };
                      if (e.target.checked) {
                        can.protocolloL = 1;
                      } else {
                        can.protocolloL = 0;
                      }
                      setCantiere(can);
                    }}
                    control={<Switch color="primary" />}
                    label="Protocollo Legalità"
                    labelPlacement="start"
                  />
                </div>
              </div>
              <Divider className="my-3" />
              {location.state.operazione === "Modifica" ? (
                <div className="row d-flex justify-content-center flex-md-nowrap flex-wrap">
                  <div className="col-md-5 col-12">
                    <h2>Wbs</h2>
                    <div className="justify-content-end d-flex">
                      <ModalWbs
                        operazione="Nuovo"
                        cantiere={cantiere.commessa}
                        wbsAuto={wbsAuto}
                        eliminaWbs={eliminaWbs}
                        numeroWbs={wbs.length}
                      />
                      <ModalWbs
                        operazione="Modifica"
                        cantiere={cantiere.commessa}
                        wbs={wbsSelezionate}
                        wbsAuto={wbsAuto}
                        eliminaWbs={eliminaWbs}
                      />
                    </div>
                    <div
                      className="py-2"
                      style={{ height: 450, width: "100%" }}
                    >
                      <DataGridPro
                        components={{ Toolbar: CustomToolbar }}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={wbs}
                        columns={columnsWbs}
                        getRowId={(row) => row.id}
                        columnVisibilityModel={columnVisibilityModel}
                        pageSize={5}
                        onRowDoubleClick={(params) => {
                          const button = document.getElementById("btnWbs");
                          button.click();
                          setEliminaWbs(params.row.id);
                          setWbsSelezionate(params.row);
                        }}
                        onRowClick={(params) => {
                          setEliminaWbs(params.row.id);
                          setWbsSelezionate(params.row);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 col-12">
                    <h2>Lavorazioni</h2>
                    <div className="justify-content-end d-flex">
                      <ModalLav
                        operazione="Nuovo"
                        cantiere={cantiere.commessa}
                        lavorazioniAuto={lavorazioniAuto}
                        eliminaLav={eliminaLav}
                        numeroLav={lavorazioni.length}
                        wbs={wbs}
                      />
                      <ModalLav
                        operazione="Modifica"
                        cantiere={cantiere.commessa}
                        lavorazione={lavorazioniSelezionate}
                        lavorazioniAuto={lavorazioniAuto}
                        eliminaLav={eliminaLav}
                        wbs={wbs}
                      />
                    </div>
                    <div className="row d-flex">
                      <div
                        className="py-2"
                        style={{ height: 450, width: "100%" }}
                      >
                        <DataGridPro
                          components={{ Toolbar: CustomToolbar }}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          rows={lavorazioni}
                          columns={columnsLavorazioni}
                          columnVisibilityModel={columnVisibilityModel}
                          getRowId={(row) => row.id}
                          pageSize={5}
                          onRowDoubleClick={(params) => {
                            const button = document.getElementById("btnLav");
                            button.click();
                            setEliminaLav(params.row.id);
                            setLavorazioniSelezionate(params.row);
                          }}
                          onRowClick={(params) => {
                            setEliminaLav(params.row.id);
                            setLavorazioniSelezionate(params.row);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between">
            <div className="gap-2 d-flex justify-content-between flex-md-nowrap flex-wrap col-6">
              <ReusableButton
                text="STAMPA"
                icon="printer"
                size="xs"
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                onClick={() => {
                  let can = { ...cantiere };
                  can.referente = can.referente_descrizione;
                  navigate(`/home/macchinari/stampa/${cantiere.commessa}`, {
                    state: {
                      cantiere: can,
                      stampa: "CantiereDettaglio",
                    },
                  });
                }}
              />
              <ReusableButton
                text="EMAIL"
                icon="envelope"
                size="xs"
                onClick={() => {
                  let can = { ...cantiere };
                  can["utente"] = localStorage.getItem("nomeUtente");
                  emailSender(can);
                }}
                className={
                  JSON.parse(localStorage.getItem("Group")) !== 2 &&
                  JSON.parse(localStorage.getItem("Group")) !== 9
                    ? "d-none"
                    : location.state.operazione === "Nuovo"
                    ? "d-none"
                    : "d-flex"
                }
              />
            </div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                className={`bg-red ${
                  JSON.parse(localStorage.getItem("permission")).cantieri
                    .allowDelete !== 3
                    ? "d-none"
                    : location.state.operazione === "Nuovo"
                    ? "d-none"
                    : "d-flex"
                }`}
                size="xs"
                onClick={() => handleDeleteButton()}
                style={{
                  display:
                    location.state.operazione === "Nuovo"
                      ? "None"
                      : JSON.parse(localStorage.getItem("permission")).cantieri
                          .allowDelete === 0
                      ? "none"
                      : "block",
                }}
              />
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleConfirmButton()}
                style={{
                  display:
                    JSON.parse(localStorage.getItem("permission")).cantieri
                      .allowEdit === 0
                      ? "none"
                      : "block",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DettaglioCantiere;

const ModalWbs = (props) => {
  const [open, setOpen] = useState(false);
  const [wbsAuto, setWbsAuto] = useState([]);
  const [wbs, setWbs] = useState({
    cantiere: "",
    id_wbs: "",
    descrizione: "",
  });
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleClickOpen = () => {
    if (props.operazione === "Modifica") {
      let wbs = { ...props.wbs };
      setWbs(wbs);
      setOpen(true);
    } else {
      if (props.numeroWbs === 100) {
        setSnack({
          severity: "error",
          messaggio: "Limite massimo Wbs raggiunto!",
        });
        setOpenSnack(true);
      } else {
        let wbsSel = { ...wbs };
        wbsSel.cantiere = props.cantiere;
        setWbs(wbsSel);
        setOpen(true);
      }
    }
    setWbsAuto(props.wbsAuto);
  };
  const handleChange = (event) => {
    let wbsSelezionato = { ...wbs };
    wbsSelezionato[event.target.name] = event.target.value;
    setWbs(wbsSelezionato);
  };
  const handleSave = async () => {
    if (props.operazione === "Modifica") {
      const response = await api.put(
        "api/cantieri_wbs",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          cantieri_wbs: wbs,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
      setOpen(false);
    } else {
      const response = await api.post(
        "api/cantieri_wbs",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          cantieri_wbs: wbs,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      wbs["id"] = response.data.message.insertId;
      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const handleDeleteButton = async (idCantiere) => {
    let isExecuted = window.confirm("Sei sicuro di voler cancellare la wbs?");
    if (isExecuted) {
      await api.delete("/api/cantieri_wbs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: idCantiere,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Wbs eliminata con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {props.operazione === "Nuovo" ? (
        <>
          <ReusableButton
            text="AGGIUNGI"
            icon="plus-circle"
            size="xxs"
            onClick={handleClickOpen}
          />
        </>
      ) : (
        <>
          {props.eliminaWbs !== 0 ? (
            <>
              <ReusableButton
                text="MODIFICA"
                icon="pencil"
                size="xxs"
                id="btnWbs"
                className="mx-2"
                onClick={handleClickOpen}
              />
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xxs"
                className="bg-red"
                onClick={() => {
                  handleDeleteButton(props.eliminaWbs);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="position-absolute top-90 start-50 translate-middle my-auto overflow-y-auto overflow-x-hidden d-fixed right-0 left-0 h-modal md:h-screen flex bg-white bg-opacity-50 justify-center w-100"
        style={{ display: open ? "" : "none", zIndex: "100", height: "25%" }}
      >
        <div className="p-md-4 p-0 w-full mx-auto my-auto flex place-content-center justify-center w-100 w-md-50">
          {/* <!-- Modal content --> */}
          <div className="mt-5 pt-5 p-md-5 p-0">
            {/* CARD CONTAINER */}
            <div className="card text-center  border-classic">
              {/* HEADER */}
              <div className="card-body">
                <div className="row d-flex">
                  <div className="col-md-12">
                    <CFormFloating
                      className="col-6 py-2"
                      style={{ width: "100%" }}
                    >
                      <CFormSelect
                        id="floatingSelect"
                        name="id_wbs"
                        options={wbsAuto}
                        value={parseInt(wbs.id_wbs)}
                        onChange={handleChange}
                      />
                      <CFormLabel>Wbs</CFormLabel>
                    </CFormFloating>
                  </div>
                </div>
              </div>
              {/* FOOTER */}
              <div className="card-footer d-flex justify-content-between">
                <div className=" d-flex justify-content-start">
                  <button
                    className="btn bg-green text-white fs-6"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <i className="bi bi-x fs-6"></i> CHIUDI
                  </button>
                </div>

                <div className=" d-flex justify-content-end">
                  <button
                    className="btn bg-green text-white fs-6"
                    onClick={() => handleSave()}
                    /* style={{
                      display:
                        props.operazione === "Modifica" ? "none" : "block",
                    }} */
                  >
                    <i className="bi bi-check fs-5"></i> SALVA
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalLav = (props) => {
  const [open, setOpen] = useState(false);
  const [wbsAuto, setWbsAuto] = useState([]);
  const [lavorazioneAuto, setLavorazioneAuto] = useState([]);
  const [lavorazione, setLavorazione] = useState({
    commessa: "",
    lavorazione: "",
    prezzo: "",
    articolo: "",
    descrizione: "",
    note: "",
  });
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleClickOpen = () => {
    if (props.operazione === "Modifica") {
      let lav = { ...props.lavorazione };
      lav.lavorazione = lav.id_lavorazione;
      //lav.id_wbs = props.wbs;
      setLavorazione(lav);
      setOpen(true);
    } else {
      if (props.numeroLav === 100) {
        setSnack({
          severity: "error",
          messaggio: "Limite massimo Lavorazioni raggiunto!",
        });
        setOpenSnack(true);
      } else if (props.wbs.length === 0) {
        setSnack({
          severity: "error",
          messaggio: "Crea una Wbs",
        });
        setOpenSnack(true);
      } else {
        let lav = { ...lavorazione };
        lav.commessa = props.cantiere;
        setLavorazione(lav);
        setOpen(true);
      }
    }
    let wbsSel = [];
    wbsSel.push({ label: "SELEZIONA...", value: "" });
    for (let i = 0; i < props.wbs.length; i++) {
      wbsSel.push({
        label: props.wbs[i].descrizione,
        value: props.wbs[i].id,
      });
    }
    setWbsAuto(wbsSel);
    setLavorazioneAuto(props.lavorazioniAuto);
  };
  const handleChange = (event) => {
    let lav = { ...lavorazione };
    lav[event.target.name] = event.target.value;
    setLavorazione(lav);
  };
  const handleSave = async () => {
    if (props.operazione === "Modifica") {
      const response = await api.put(
        "api/cantieri_lavorazioni",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          cantieri_lavorazioni: lavorazione,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      const response = await api.post(
        "api/cantieri_lavorazioni",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          cantieri_lavorazioni: lavorazione,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      lavorazione["id"] = response.data.message.insertId;
      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const handleDeleteButton = async (idCantiere) => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la lavorazione?"
    );
    if (isExecuted) {
      await api.delete("/api/cantieri_lavorazioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: idCantiere,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Lavorazione eliminata con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {props.operazione === "Nuovo" ? (
        <>
          <ReusableButton
            text="AGGIUNGI"
            icon="plus-circle"
            size="xxs"
            onClick={handleClickOpen}
          />
        </>
      ) : (
        <>
          {props.eliminaLav !== 0 ? (
            <>
              <ReusableButton
                text="MODIFICA"
                icon="pencil"
                size="xxs"
                id="btnLav"
                className="mx-2"
                onClick={handleClickOpen}
              />
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xxs"
                className="bg-red"
                onClick={() => {
                  handleDeleteButton(props.eliminaLav);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="position-absolute top-90 start-50 translate-middle my-auto overflow-y-auto overflow-x-hidden d-fixed right-0 left-0 h-modal md:h-screen flex bg-white bg-opacity-50 justify-center w-100"
        style={{ display: open ? "" : "none", zIndex: "100", height: "25%" }}
      >
        <div className="w-md-50 w-100 p-0 p-md-4 mx-auto my-auto flex place-content-center justify-center">
          {/* <!-- Modal content --> */}
          <div className="mt-5 pt-5 p-md-5 p-0">
            {/* CARD CONTAINER */}
            <div className="card text-center  border-classic">
              {/* HEADER */}
              <div className="card-body">
                <div className="row d-flex">
                  <div className="col-md-6">
                    <CFormFloating
                      className="col-6 py-2"
                      style={{ width: "100%" }}
                    >
                      <CFormSelect
                        id="floatingSelect"
                        name="lavorazione"
                        options={lavorazioneAuto}
                        value={parseInt(lavorazione.lavorazione)}
                        onChange={handleChange}
                      />
                      <CFormLabel>Lavorazione</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="col-md-6">
                    <CFormFloating
                      className="col-6 py-2"
                      style={{ width: "100%" }}
                    >
                      <CFormSelect
                        id="floatingSelect"
                        name="id_wbs"
                        options={wbsAuto}
                        value={parseInt(lavorazione.id_wbs)}
                        onChange={handleChange}
                      />
                      <CFormLabel>Wbs</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="col-md-6">
                    <CFormFloating className="mb-3">
                      <CFormInput
                        type="text"
                        id="DescrizioneInput"
                        name="prezzo"
                        value={lavorazione.prezzo}
                        onChange={handleChange}
                      />
                      <CFormLabel>Prezzo</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="col-md-6">
                    <CFormFloating className="mb-3">
                      <CFormInput
                        type="text"
                        id="DescrizioneInput"
                        name="articolo"
                        value={lavorazione.articolo}
                        onChange={handleChange}
                      />
                      <CFormLabel>Articolo</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="col-md-12">
                    <CFormFloating className="mb-3">
                      <CFormTextarea
                        style={{ height: 100 }}
                        type="text"
                        name="note"
                        id="AnnoAcquistoInput"
                        value={lavorazione.note}
                        onChange={handleChange}
                      />
                      <CFormLabel>Note</CFormLabel>
                    </CFormFloating>
                  </div>
                </div>
              </div>
              {/* FOOTER */}
              <div className="card-footer d-flex justify-content-between">
                <div className="d-flex justify-content-start">
                  <button
                    className="btn bg-green text-white fs-6"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <i className="bi bi-x fs-6"></i> CHIUDI
                  </button>
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    className="btn bg-green text-white fs-6"
                    onClick={() => handleSave()}
                  >
                    <i className="bi bi-check fs-5"></i> SALVA
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
