import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./api";
import { CButton, CSpinner } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import ReusableButton from "./Reusable/Buttons";
const columns = [
  {
    field: "id",
    headerName: "id",
    width: 100,
    hide: true,
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    width: 300,
  },
];

const Wbs = () => {
  const [wbs, setWbs] = useState([]);
  const [wbsVisibili, setWbsVisibili] = useState([]);
  const [wbsCaricati, setWbsCaricati] = useState(false);
  const navigate = useNavigate();

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });
  const handleChange = (e) => {
    var wbsX = [];
    var i;
    for (i = 0; i < wbs.length; i++) {
      if (
        wbs[i].descrizione?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        wbsX.push(wbs[i]);
      }
    }
    setWbsVisibili(wbsX);

    if (e.target.value === "") {
      // perchè se è vuoto ricarico tutto
      setWbsVisibili(wbs);
    }
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/wbs", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const wbs = res.data.data;
          setWbs(wbs);
          setWbsVisibili(wbs);
          setWbsCaricati(true);
        });
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">WBS</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center  col">
            <div className="d-flex col-6 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className="mx-3"
              onClick={() =>
                navigate(`/home/wbs/dettaglio-wbs`, {
                  state: { wbs: [], operazione: "Nuovo" },
                })
              }
              /* style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).wbs
                    .allowInsert === 0
                    ? "None"
                    : "Block",
              }} */
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={wbsVisibili}
            columns={columns}
            getRowId={(row) => row.id}
            columnVisibilityModel={columnVisibilityModel}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              const dataWbs = wbs.filter((wbs) => wbs.id === params.row.id);
              navigate(`/home/wbs/dettaglio-wbs`, {
                state: {
                  wbs: dataWbs[0],
                  operazione: "Modifica",
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Wbs;
