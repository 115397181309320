import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "https://portale.fondamentasrl.it/"
console.log(`Using API URL: ${apiUrl}`);

const call = axios.create({
  baseUrl: apiUrl
});
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDE5OTY3ODR9.UdIvvrZxaq7anKpTOLmWEGbm5F8NkhVs9xgib9jAykU";

call.defaults.baseURL = apiUrl;
call.defaults.headers.common["Authorization"] = `Bearer ${token}`;
call.defaults.headers.post["Content-Type"] = "application/json";

export default call;
