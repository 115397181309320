import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormTextarea,
  CFormCheck,
  CCollapse,
  CCol,
  CFormSwitch,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
} from "@coreui/react";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";

import enLocale from "date-fns/locale/en-IE";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import { Backdrop, CircularProgress } from "@mui/material";
const NewManutenzioni = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fileCheck, setFileCheck] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [manutenzione, setManutenzione] = useState({
    utente: "",
    data: "",
    cantiere: "",
    matricola: "",
    descrizione: "",
    matr_costr: "",
    manutenz_tipo: "",
    ore_km: "",
    tipologia: "",
    costo: "",
    documento: "",
    ore_scadenza: 0,
    manutenzione: [],
    note: "",
    meccanico: "",
  });
  const [names, setNames] = useState([]);
  const [descrizione, setDescrizione] = useState("");
  const [matricole, setMatricole] = useState([]);
  const [cantieri, setCantieri] = useState([]);
  const [meccanici, setMeccanici] = useState([]);
  const [scadenza, setScadenza] = useState(false);
  const [searchReady, setSearchReady] = useState(false);
  const [funiCatene, setFuniCatene] = useState(false);
  const [letturaOre, setLetturaOre] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const nomeUtente = localStorage.getItem("nomeUtente");
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const loadFile = async (filex, matricola, data) => {
    setOpenBackdrop(true);
    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", matricola);
    formData.append("data", data);
    formData.append(
      "nomeManutenzione",
      matricola + "_" + moment(data).format("YYMMDD") + ".pdf"
    );
    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setOpenBackdrop(false);
        setSnack({ severity: "success", messaggio: "Ok" });
        setOpenSnack(true);
        setFileCheck(true);
      });
  };

  const load = async () => {
    const response = await fetch("/data/manutenzione.csv");
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder("utf-8");
    const csv = await decoder.decode(result.value);
    return csv.split("\r\n");
  };
  const handleChange = (event) => {
    let manut = { ...manutenzione };

    manut[event.target.name] = event.target.value;

    setManutenzione(manut);
  };

  const manageFiles = async (e, file, matricola) => {
    //let f = cFile.split("/");

    await api
      .post(
        "api/ftp",
        {
          file: file,
          percorso: "/" + matricola + "/manutenzioni/" + file,
          operazione: "prendi",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + file);
      });
  };

  const handleRadiotipoClick = (value) => {
    if (value === "Lettura") {
      setLetturaOre(true);
    } else {
      setLetturaOre(false);
    }
    let manut = { ...manutenzione };
    manut["tipologia"] = value;
    setManutenzione(manut);
  };
  const handleRadioClick = (value) => {
    let manut = { ...manutenzione };

    manut["manutenz_tipo"] = value;

    setManutenzione(manut);
  };
  const handleChangeMultipleSelect = (event) => {
    let manut = { ...manutenzione };
    manut[event.target.name] = event.target.value;
    setManutenzione(manut);
  };
  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la manutenzione?"
    );
    if (isExecuted) {
      await api.delete("/api/manutenzioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: manutenzione.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Manutenzione eliminata con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  };
  const handleConfirmButton = async () => {
    let operazione = "";
    for (let i = 0; i < manutenzione.manutenzione.length; i++) {
      operazione += manutenzione.manutenzione[i];
      if (i !== manutenzione.manutenzione.length - 1) {
        operazione += ", ";
      }
    }
    let manut = manutenzione;

    try {
      manut["utente"] = localStorage.getItem("nomeUtente");
      manut["manutenzione"] = operazione;
      if (
        location.state.operazione === "Nuovo" ||
        location.state.operazione === "MacchinarioNuovo"
      ) {
        const response = await api.post(
          "api/manutenzioni",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", manutenzione: manut },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        manut["id"] = response.data.message.insertId; //ottengo l'id dell'elemento appena inserito

        location.state.operazione = "Modifica";
      } else {
        let manutDataCorretta = { ...manut };
        manutDataCorretta["data"] = moment(manut.data).format("YYYY-MM-DD");
        await api.put(
          "api/manutenzioni",
          {
            auth: "a5d42c3ed7977afa2d8e922569e7940b",
            manutenzione: manutDataCorretta,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      }

      manut["manutenzione"] = manut["manutenzione"].split(", ");

      setSnack({
        severity: "success",
        messaggio: "Salvataggio effettuato con successo",
      });
      setOpenSnack(true);
      location.state.operazione = "Modifica";
      /* setTimeout(() => {
        navigate(-1);
      }, 1000); */
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante il salvataggio",
      });
      setOpenSnack(true);
    }
  };
  const handleChangeDescrizione = async (newValue) => {
    const res = await api.get("api/macchinari", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const macch = res.data.data.filter(
      (macchinario) => macchinario.matricola === newValue
    );
    let manut = manutenzione.descrizione;
    manut = macch[0].descrizione;
    setDescrizione(manut);
  };
  useEffect(() => {
    const fetchData = async () => {
      await api
        .get("api/elencoManutenzioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let elenco = res.data.data;
          setNames(
            elenco.map((el) => {
              return el.Descrizione;
            })
          );
        });
      api
        .get("api/macchinari", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const matricole = res.data.data.map(
            (matricola) => matricola.matricola
          );
          setMatricole(matricole);

          //sostituzione DESCRIZIONE
          if (location.state.operazione === "Modifica") {
            const macch = res.data.data.filter(
              (macchinario) =>
                macchinario.matricola === location.state.manutenzione.matricola
            );
            let manut = manutenzione.descrizione;
            manut = macch[0].descrizione;
            setDescrizione(manut);

            //CHECK FILE FTP
          }
        });
      api
        .get("api/cantieri", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const cantieri = res.data.data.map((cantiere) => cantiere.commessa);
          setCantieri(cantieri);
        });

      api
        .get("api/meccanici", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const meccanici = res.data.data.map((mecc) => mecc.descrizione);
          setMeccanici(meccanici);
        });
      setSearchReady(true);
      if (location.state.operazione === "Nuovo") {
        let manutenzioneTemp = manutenzione;
        manutenzioneTemp["data"] = moment(new Date());
        //manutenzioneTemp["data"] = location.state.manutenzioni;
        setManutenzione(manutenzioneTemp);
      } else if (location.state.operazione === "MacchinarioNuovo") {
        let manutenzioneTemp = manutenzione;
        manutenzioneTemp["data"] = moment(new Date());
        manutenzioneTemp["matricola"] = location.state.manutenzione;
        setManutenzione(manutenzioneTemp);
      } else {
        const manut = location.state.manutenzione;
        if (manut.manutenzione !== null && manut.manutenzione !== "") {
          manut.manutenzione = manut.manutenzione.split(", ");
        } else {
          manut.manutenzione = [];
        }
        if (manut.tipologia === "Lettura") {
          setLetturaOre(true);
        }
        manut.ore_scadenza !== 0 ? setScadenza(true) : setScadenza(false);
        setManutenzione(manut);

        let res = await api.post(
          "api/ftp",
          {
            file:
              manut.matricola +
              "_" +
              moment(manut.data).format("YYMMDD") +
              ".pdf",
            matricola: manut.matricola,
            operazione: "exist",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setFileCheck(res.data);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Manutenzioni</span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.state.search !== undefined) {
                  navigate("/home/manutenzioni", {
                    state: {
                      search: location.state.search,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>

          {/* BODY */}
          <div className="card-body">
            <div className="d-flex col-md-12 gap-2 flex-md-nowrap flex-wrap">
              <CFormFloating className="col-md-6 col-12">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enLocale}
                >
                  <DatePicker
                    label="Data"
                    value={manutenzione.data}
                    renderInput={(params) => (
                      <TextField {...params} className="col-12" />
                    )}
                    onChange={(newValue) => {
                      let can = manutenzione;
                      can["data"] = moment(newValue)
                        .local()
                        .format("YYYY-MM-DD");
                      setManutenzione(can);
                      setFuniCatene(true);
                    }}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </LocalizationProvider>
              </CFormFloating>

              <div className="row d-flex flex-nowrap gx-0 col-md-6 col-12">
                <CFormFloating
                  className="mb-md-3 mb-2 col-10 col-md-11"
                  style={{
                    height: "100%",
                    width:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "100%"
                        : "",
                  }}
                >
                  {searchReady ? (
                    <Autocomplete
                      value={manutenzione.cantiere}
                      onChange={(event, newValue) => {
                        let manut = { ...manutenzione };
                        manut["cantiere"] = newValue;
                        setManutenzione(manut);
                      }}
                      id="controllable-states-demo"
                      options={cantieri}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Cantiere" />
                      )}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .manutenzioni.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  ) : (
                    ""
                  )}
                </CFormFloating>
                <CButton
                  className="btn btn-green mb-md-3 mb-2 col-2 col-md-1"
                  style={{
                    display:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "none"
                        : "block",
                  }}
                  onClick={() =>
                    navigate(`/home/cantieri/dettaglio-cantiere`, {
                      state: { cantiere: [], operazione: "Nuovo" },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </CButton>
              </div>
            </div>

            <div className="d-flex col-md-12 gap-md-2 flex-md-nowrap flex-wrap">
              <div className="row d-flex flex-nowrap gx-0 col-md-6 col-12">
                <CFormFloating
                  className="mb-md-3 mb-2 col-10 col-md-11"
                  style={{
                    height: "100%",
                    width:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "100%"
                        : "",
                  }}
                >
                  {searchReady ? (
                    <Autocomplete
                      value={manutenzione.matricola}
                      onChange={(event, newValue) => {
                        let manut = { ...manutenzione };
                        manut["matricola"] = newValue;
                        manut["matr_costr"] = newValue;
                        manut["descrizione"] = newValue;
                        setManutenzione(manut);
                        handleChangeDescrizione(newValue);
                      }}
                      id="controllable-states-demo"
                      options={matricole}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Matricola" />
                      )}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .manutenzioni.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  ) : (
                    ""
                  )}
                </CFormFloating>
                <CButton
                  className="btn btn-green mb-md-3 mb-2 col-2 col-md-1"
                  style={{
                    display:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "none"
                        : "block",
                  }}
                  onClick={() =>
                    navigate(`/home/macchinari/:id`, {
                      state: { macchinario: [], operazione: "Nuovo" },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </CButton>
              </div>

              <CFormFloating className="mb-3 col-md-6 col-12">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  value={descrizione}
                  disabled={true}
                />
                <CFormLabel>Descrizione</CFormLabel>
              </CFormFloating>
            </div>

            <div className="d-flex col-md-12 gap-2 flex-md-nowrap flex-wrap">
              <div className="col-md-6 col-12 d-flex justify-content-start flex-wrap">
                <span>Tipo Manutenzione</span>
                <CFormFloating className="mb-3 col-12">
                  <CFormCheck
                    className="col-6 d-flex justify-content-start"
                    type="radio"
                    name="tipologia"
                    id="flexRadioesterna2"
                    label="Interna"
                    checked={
                      manutenzione.tipologia === "Interna" ? true : false
                    }
                    value="Interna"
                    onChange={(e) => handleRadiotipoClick("Interna")}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormCheck
                    className="col-6 d-flex justify-content-start"
                    type="radio"
                    name="tipologia"
                    id="flexRadioesterna"
                    label="Esterna"
                    checked={
                      manutenzione.tipologia === "Esterna" ? true : false
                    }
                    value="Esterna"
                    onChange={(e) => handleRadiotipoClick("Esterna")}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormCheck
                    className="col-6 d-flex justify-content-start"
                    type="radio"
                    name="tipologia"
                    id="flexRadioesterna3"
                    label="Lettura Ore"
                    checked={
                      manutenzione.tipologia === "Lettura" ? true : false
                    }
                    value="Lettura"
                    onChange={(e) => handleRadiotipoClick("Lettura")}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </CFormFloating>
              </div>

              <div className="col-md-6 col-12 d-flex justify-content-start flex-wrap">
                <span>Tipologia</span>
                <CFormFloating className="mb-3 col-12">
                  <CFormCheck
                    className="col-6 d-flex justify-content-start"
                    type="radio"
                    name="manutez_tipo"
                    id="flexRadioDefault2"
                    label="Ordinaria"
                    checked={
                      manutenzione.manutenz_tipo === "Ordinaria" ? true : false
                    }
                    value="Ordinaria"
                    onChange={(e) => handleRadioClick("Ordinaria")}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormCheck
                    className="col-6 d-flex justify-content-start"
                    type="radio"
                    name="manutez_tipo"
                    id="flexRadioDefault3"
                    label="Straordinaria"
                    checked={
                      manutenzione.manutenz_tipo === "Straordinaria"
                        ? true
                        : false
                    }
                    value="Straordinaria"
                    onChange={(e) => handleRadioClick("Straordinaria")}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormCheck
                    className="col-6 d-flex justify-content-start"
                    type="radio"
                    name="manutez_tipo"
                    id="flexRadioDefault4"
                    label="Altro"
                    checked={
                      manutenzione.manutenz_tipo === "Altro" ? true : false
                    }
                    value="Altro"
                    onChange={(e) => handleRadioClick("Altro")}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </CFormFloating>
              </div>
            </div>

            <div className="d-flex col-md-12 gap-2 flex-md-nowrap flex-wrap">
              <CFormFloating className="mb-3 col-md-6 col-12">
                <CFormInput
                  type="number"
                  name="ore_km"
                  id="TargaInput"
                  value={manutenzione.ore_km}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).manutenzioni
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>Ore/Km</CFormLabel>
              </CFormFloating>

              {letturaOre ? (
                ""
              ) : (
                <CFormFloating className="mb-3 col-md-6 col-12">
                  <CFormInput
                    type="number"
                    name="costo"
                    id="TargaInput"
                    value={manutenzione.costo}
                    onChange={handleChange}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormLabel>Costo</CFormLabel>
                </CFormFloating>
              )}
            </div>

            {letturaOre ? (
              ""
            ) : (
              <>
                <div className="d-flex col-md-12 gap-2 flex-md-nowrap flex-wrap ">
                  <FormControl
                    className="col-md-6 col-12"
                    sx={{ marginBottom: "15px" }}
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Manutenzione
                    </InputLabel>

                    <Select
                      style={{ width: "100%" }}
                      labelId="demo-multiple-name-label"
                      name="manutenzione"
                      id="demo-multiple-name"
                      multiple
                      value={manutenzione.manutenzione}
                      onChange={handleChangeMultipleSelect}
                      input={<OutlinedInput label="Manutenzione" />}
                      //MenuProps={MenuProps}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .manutenzioni.allowEdit === 0
                          ? true
                          : false
                      }
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CFormFloating className="mb-3 gap-2 align-items-center d-flex justify-content-start col-md-6 col-12">
                    <CFormSwitch
                      className=""
                      label="Aggiungere Scadenza"
                      checked={scadenza}
                      id="formSwitch"
                      onChange={(e) => {
                        e.target.checked
                          ? setScadenza(true)
                          : setScadenza(false);
                      }}
                    />
                    <CCollapse visible={scadenza} className="col">
                      <CCol sm={12}>
                        <CFormFloating>
                          <CFormTextarea
                            className=""
                            type="text"
                            name="ore_scadenza"
                            id="AnnoAcquistoInput"
                            value={manutenzione.ore_scadenza}
                            onChange={handleChange}
                            disabled={
                              JSON.parse(localStorage.getItem("permission"))
                                .manutenzioni.allowEdit === 0
                                ? true
                                : false
                            }
                          />
                          <CFormLabel>Scadenza</CFormLabel>
                        </CFormFloating>
                      </CCol>
                    </CCollapse>
                  </CFormFloating>
                </div>

                <CFormFloating className="mb-3">
                  <CFormTextarea
                    style={{ height: 100 }}
                    type="text"
                    name="note"
                    id="AnnoAcquistoInput"
                    value={manutenzione.note}
                    onChange={handleChange}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormLabel>Note</CFormLabel>
                </CFormFloating>
                {searchReady ? (
                  <Autocomplete
                    className="mb-3"
                    value={manutenzione.meccanico}
                    onChange={(event, newValue) => {
                      let tipo = { ...manutenzione };
                      tipo["meccanico"] = newValue;
                      setManutenzione(tipo);
                    }}
                    id="controllable-states-demo"
                    options={meccanici}
                    getOptionLabel={(option) => `${option}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Meccanici" />
                    )}
                    disabled={
                      JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowEdit === 0
                        ? true
                        : false
                    }
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </div>

          {/* FOOTER */}
          <div className="card-footer gap-2 d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="d-flex justify-content-start gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="STAMPA"
                icon="printer"
                size="xxs"
                onClick={() => {
                  navigate(`/home/macchinari/stampa/${manutenzione.utente}`, {
                    state: {
                      manutenzione: manutenzione,
                      stampa: "ManutenzioneDettaglio",
                    },
                  });
                }}
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
              />
            </div>

            <div className="row d-flex justify-content-between gx-0 gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="Documenti manutenzione"
                icon="folder"
                size="xxs"
                onClick={() =>
                  window.open(
                    "https://portale.dscloud.me:7036/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance&launchParam=openfile%3D%252FPORTALE%252F" +
                      manutenzione.matricola +
                      "/manutenzioni%252F#/signin"
                  )
                }
                className={`${
                  location.state.operazione === "Modifica"
                    ? "d-block"
                    : "d-none"
                }`}
              />

              <ReusableButton
                text="Carica documento"
                icon="upload"
                size="xxs"
                onClick={() =>
                  document.getElementById("fileManutenzione").click()
                }
                className={
                  !fileCheck && location.state.operazione === "Modifica"
                    ? "d-block"
                    : "d-none"
                }
              />
              <ReusableButton
                text="Documento manutenzione presente"
                icon="check"
                size="xxs"
                onClick={
                  fileCheck
                    ? (e) =>
                        manageFiles(
                          e,
                          manutenzione.matricola +
                            "_" +
                            moment(manutenzione.data).format("YYMMDD") +
                            ".pdf",
                          manutenzione.matricola
                        )
                    : null
                }
                className={`${fileCheck ? "d-block" : "d-none"}`}
              />
              <ReusableButton
                text="Documento manutenzione non presente"
                icon="x"
                size="xxs"
                className={`bg-red  ${
                  !fileCheck && location.state.operazione === "Modifica"
                    ? "d-block"
                    : "d-none"
                }`}
                style={
                  fileCheck ? { display: "None!important" } : { display: "" }
                }
              />
            </div>

            <input
              id="fileManutenzione"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={(e) =>
                loadFile(
                  e.target.files[0],
                  manutenzione.matricola,
                  manutenzione.data
                )
              }
            />

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xs"
                className={`bg-red ${
                  JSON.parse(localStorage.getItem("permission")).manutenzioni
                    .allowDelete === 1 && nomeUtente === manutenzione.utente
                    ? "d-flex"
                    : JSON.parse(localStorage.getItem("permission"))
                        .manutenzioni.allowDelete === 3
                    ? "d-flex"
                    : "d-none"
                }`}
                onClick={() => handleDeleteButton()}
              />
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleConfirmButton()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewManutenzioni;
