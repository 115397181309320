import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import { CButton, CSpinner } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";

import api from "./api";
import TextField from "@mui/material/TextField";

import moment from "moment";
import ReusableButton from "./Reusable/Buttons";

const columns = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    flex: 1,
  },
  {
    field: "utente",
    headerName: "Utente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 100,
    headerAlign: "center",
    align: "center",
    sort: "des",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matricola",
    headerName: "Matricola",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    width: 100,
    headerAlign: "center",
    align: "center",
  } /* 
  {
    field: "matr_costr",
    headerName: "Matricola Costruttore",
    width: 100,
    headerAlign: "center",
    align: "center",
  }, */,
  {
    field: "manutenz_tipo",
    headerName: "Tipo Manutenzione",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ore_km",
    headerName: "Ore/Km",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "note",
    headerName: "Note",
    width: 500,
    height: 200,
    headerAlign: "center",
  },
  {
    field: "meccanico",
    headerName: "Meccanico",
    width: 150,
    headerAlign: "center",
  },
];

const Manutenzioni = (props) => {
  const [manutenzioni, setManutenzioni] = useState([]);
  const [manutenzioniVIsibili, setManutenzioniVIsibili] = useState([]);
  const [macchinariCaricati, setMacchinariCaricati] = useState(false);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });

  const applyFilter = (e, val, manut) => {
    var manutenioniX = [];
    var i;
    for (i = 0; i < manut.length; i++) {
      if (
        manut[i].matricola?.toLowerCase().includes(val.toLowerCase()) ||
        manut[i].utente?.toLowerCase().includes(val.toLowerCase()) ||
        manut[i].descrizione?.toLowerCase().includes(val.toLowerCase()) ||
        manut[i].cantiere?.toString().includes(val.toLowerCase()) ||
        manut[i].manutenz_tipo?.toLowerCase().includes(val.toLowerCase()) ||
        manut[i].ore_km?.toString().includes(val.toLowerCase()) ||
        manut[i].note?.toLowerCase().includes(val.toLowerCase()) ||
        manut[i].meccanico?.toLowerCase().includes(val.toLowerCase())
      ) {
        manutenioniX.push(manut[i]);
      }
    }
    setManutenzioniVIsibili(manutenioniX);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setSearchValue("");
    }
    var manutenioniX = [];
    var i;
    for (i = 0; i < manutenzioni.length; i++) {
      if (
        manutenzioni[i].matricola
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].utente
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].descrizione
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].cantiere
          ?.toString()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].manutenz_tipo
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].ore_km
          ?.toString()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].note
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        manutenzioni[i].meccanico
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        manutenioniX.push(manutenzioni[i]);
      }
    }
    setManutenzioniVIsibili(manutenioniX);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/manutenzioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let manutenzioni = res.data.data;
          if (
            JSON.parse(localStorage.getItem("permission")).manutenzioni
              .allowView === 1
          ) {
            manutenzioni = manutenzioni.filter(
              (manutenzione) =>
                manutenzione.utente === localStorage.getItem("nomeUtente")
            );
          }
          setMacchinariCaricati(true);
          setManutenzioni(manutenzioni);
          if (location.state.search === "") {
            setManutenzioniVIsibili(manutenzioni);
          } else {
            setSearchValue(location.state.search);
            applyFilter("search", location.state.search, manutenzioni);
          }
        });
    };
    fetchData();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">MANUTENZIONI</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center  col">
            <div className="d-flex col-6 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  value={searchValue}
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className="mx-3"
              onClick={() =>
                navigate("/home/manutenzioni/:id", {
                  state: {
                    manutenzioni: moment(new Date())
                      .local()
                      .format("YYYY-MM-DD"),
                    operazione: "Nuovo",
                  },
                })
              }
              style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).manutenzioni
                    .allowInsert === 0
                    ? "None"
                    : "Block",
              }}
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={manutenzioniVIsibili}
            columns={columns}
            getRowId={(row) => row.id}
            columnVisibilityModel={columnVisibilityModel}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "data", sort: "desc" }],
              },
            }}
            onRowClick={(params) => {
              const dataManutenzioni = manutenzioni.filter(
                (manutenzione) => manutenzione.id === params.row.id
              );
              if (searchValue !== "") {
                navigate(
                  `/home/manutenzioni/${dataManutenzioni[0].matricola}`,
                  {
                    state: {
                      manutenzione: dataManutenzioni[0],
                      operazione: "Modifica",
                      search: searchValue,
                    },
                  }
                );
              } else {
                navigate(
                  `/home/manutenzioni/${dataManutenzioni[0].matricola}`,
                  {
                    state: {
                      manutenzione: dataManutenzioni[0],
                      operazione: "Modifica",
                      search: searchValue,
                    },
                  }
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Manutenzioni;
