import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CButton,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import api from "./api";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridExceljsProcessInput,
} from "@mui/x-data-grid-pro";
import ReusableButton from "./Reusable/Buttons";
import GlobalSnackbar from "./Snackbar";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { CropLandscapeOutlined } from "@mui/icons-material";

const columns = [
  {
    field: "id_prod",
    hide: true,
  },
  {
    field: "id",
    headerName: "Progressivo",
    flex: 1,
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    flex: 1,
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    flex: 1,
  },
  {
    field: "utente",
    headerName: "Utente",
    flex: 1,
  },
  {
    field: "wbs",
    headerName: "Wbs",
    flex: 1,
  },
  {
    field: "lavorazioni",
    headerName: "Lavorazioni",
    flex: 1,
  },
  {
    field: "quantita",
    headerName: "Qantità",
    flex: 1,
  },
  /* {
    field: "noleggio",
    headerName: "Noleggio",
    flex: 1,
  }, */
  {
    field: "attrezzatura",
    headerName: "Attrezzatura",
    flex: 1,
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    flex: 1,
  },
  {
    field: "operatori",
    headerName: "Operatori",
    flex: 1,
  },
  {
    field: "note",
    headerName: "Note",
    flex: 1,
  },
  {
    field: "produzione",
    headerName: "Produzione",
    flex: 1,
  },
];

const Poduzione = () => {
  const [produzioni, setProduzioni] = useState([]);
  const [listaLavorazioni, setListaLavorazioni] = useState([]);
  const [produzioniVisibili, setProduzioniVisibili] = useState([]);
  const [produzioniSel, setProduzioniSel] = useState([]);
  const [produzioniCaricati, setProduzioniCaricati] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [valueProduzione, setValueProduzione] = useState("JET GROUTING (ml)");
  const navigate = useNavigate();
  const location = useLocation();
  const group = localStorage.getItem("Group");
  const nomeUtente = localStorage.getItem("nomeUtente");
  const [openSnack, setOpenSnack] = useState(false);

  const [mesi, setMesi] = useState([{}]);
  const [tableMonth, setTableMonth] = useState({ nome: "", val: 0, anno: 0 });

  const [nomiMesi, setNomiMesi] = useState([
    {
      nome: "Gennaio",
      val: 1,
      anno: 0,
    },
    {
      nome: "Febbraio",
      val: 2,
      anno: 0,
    },
    {
      nome: "Marzo",
      val: 3,
      anno: 0,
    },
    {
      nome: "Aprile",
      val: 4,
      anno: 0,
    },
    {
      nome: "Maggio",
      val: 5,
      anno: 0,
    },
    {
      nome: "Giugno",
      val: 6,
      anno: 0,
    },
    {
      nome: "Luglio",
      val: 7,
      anno: 0,
    },
    {
      nome: "Agosto",
      val: 8,
      anno: 0,
    },
    {
      nome: "Settembre",
      val: 9,
      anno: 0,
    },
    {
      nome: "Ottobre",
      val: 10,
      anno: 0,
    },
    {
      nome: "Novembre",
      val: 11,
      anno: 0,
    },
    {
      nome: "Dicembre",
      val: 12,
      anno: 0,
    },
  ]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id_prod: false,
    brokerId: false,
    status: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const applyFilter = (e, val, prod) => {
    var produzioneX = [];
    var i;
    for (i = 0; i < prod.length; i++) {
      if (
        prod[i].id?.toString().includes(val.toLowerCase()) ||
        prod[i].utente?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].cantiere?.toString().includes(val.toLowerCase()) ||
        prod[i].wbs?.toString().includes(val.toLowerCase()) ||
        prod[i].wbs?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].lavorazioni?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].operatori?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].quantita?.toString().includes(val.toLowerCase()) ||
        prod[i].attrezzatura?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].descrizione?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].note?.toLowerCase().includes(val.toLowerCase()) ||
        prod[i].produzione?.toString().includes(val.toLowerCase())
      ) {
        produzioneX.push(prod[i]);
      }
    }
    setProduzioniVisibili(produzioneX);
  };

  const handleDeleteProduzione = async () => {
    if (produzioniSel.length === 0) {
      setSnack({ severity: "error", messaggio: "Selezionare un produzione" });
      setOpenSnack(true);
    } else {
      if (
        window.confirm(
          "ATTENZIONE, sei sicuro di voler eliminare le produzioni selezionate?"
        )
      ) {
        try {
          await api.delete("/api/produzione", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            data: {
              id: produzioniSel.join(","),
            },
          });
          setSnack({
            severity: "success",
            messaggio: "Produzione eliminata con successo",
          });
          setOpenSnack(true);
          window.location.reload();
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'eliminazione",
          });
          setOpenSnack(true);
        }
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setSearchValue("");
    }
    setProduzioniVisibili([]);
    var produzioneX = [];
    var i;
    for (i = 0; i < produzioni.length; i++) {
      if (
        produzioni[i].id?.toString().includes(e.target.value.toLowerCase()) ||
        produzioni[i].utente
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].cantiere
          ?.toString()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].wbs
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].lavorazioni
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].operatori
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].quantita
          ?.toString()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].attrezzatura
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].descrizione
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].note
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        produzioni[i].produzione
          ?.toString()
          .includes(e.target.value.toLowerCase())
      ) {
        produzioneX.push(produzioni[i]);
      }
    }
    setProduzioniVisibili(produzioneX);
  };

  const handlePrezzoProduzione = async () => {
    const resProd = await api.get("api/produzione", {
      params: { id: produzioniSel },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const produzioniDaAggiornare = resProd.data.data;
    for (let i = 0; i < produzioniDaAggiornare.length; i++) {
      let prod = produzioniDaAggiornare[i];
      prod.produzione = prod.quantita * parseInt(prod.prezzo);
      prod["data"] = moment(prod.data).format("YYYY-MM-DD");
      delete prod.prezzo;
      try {
        const resProd = await api.put(
          "api/produzione",
          {
            auth: "a5d42c3ed7977afa2d8e922569e7940b",
            produzione: prod,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({ severity: "success", messaggio: "Ricalcolo effettuato" });
        setOpenSnack(true);
        const res = await api.get("api/produzione_padre", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const getProd = res.data.data;
        setProduzioniCaricati(true);
        if (searchValue === "") {
          if (group === "12") {
            let prodUtente = getProd.filter((el) => el.utente === nomeUtente);
            setProduzioniVisibili(prodUtente);
          } else {
            setProduzioniVisibili(getProd);
          }
        } else {
          setSearchValue(searchValue);
          applyFilter("search", searchValue, getProd);
        }
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante il ricalcolo",
        });
        setOpenSnack(true);
      }
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      const res = await api.get("api/produzione_padre", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const prod = res.data.data;
      setProduzioniCaricati(true);
      setProduzioni(prod);
      let currentMonth = new moment(Date()).format("MM");
      let currentAnno = new moment(Date()).format("YYYY");
      handleMonth(
        parseInt(currentMonth),
        parseInt(currentAnno),
        prod,
        valueProduzione
      );

      const resLav = await api.get("api/lavorazioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const lav = resLav.data.data.map((el) => el.descrizione);

      setListaLavorazioni(lav);

      if (location.state.search === "") {
        if (group === "12") {
          let prodUtente = prod.filter((el) => el.utente === nomeUtente);
          setProduzioniVisibili(prodUtente);
        } else {
          setProduzioniVisibili(prod);
        }
      } else {
        setSearchValue(location.state.search);
        applyFilter("search", location.state.search, prod);
      }
    };
    fetchData();
  }, []);

  const handleMonth = async (currentMonth, currentAnno, produz, value) => {
    let month = currentMonth;
    let anno = currentAnno;
    if (currentMonth < 1) {
      month = 12;
      anno = anno - 1;
    } else if (currentMonth > 12) {
      month = 1;
      anno = anno + 1;
    }

    let meseCorrente = {};

    nomiMesi.map((el) => {
      if (el.val === month) {
        meseCorrente = el;
      }
    });
    meseCorrente.anno = anno;

    setTableMonth(meseCorrente);

    const filteredProd = produz?.filter((el) => {
      //modifico la formattazione per fare funzionare il filter
      let mix = meseCorrente.val;
      mix = mix.length === 1 ? "0" + mix : mix;

      return (
        parseInt(moment(el.data).format("MM")) +
          "-" +
          parseInt(moment(el.data).format("YYYY")) ===
        mix + "-" + meseCorrente.anno
      );
    });

    if (filteredProd.length !== 0) {
      const dateInizioFine = filteredProd?.map((el) => el.data);
      const cantieri = filteredProd?.map((el) => el.cantiere);
      let primaData = dateInizioFine[dateInizioFine.length - 1];
      let ultimaData = moment(dateInizioFine[0], "YYYY-MM").daysInMonth();
      const resProd = await api.get("api/produzione", {
        params: {
          giorni: [1, ultimaData],
          anno: moment(primaData).format("YYYY"),
          mese: moment(primaData).format("MM"),
          commesse: cantieri,
          val: value,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMesi(resProd.data.data);
    } else {
      setMesi([{ val: "PRODUZIONI NON PRESENTI" }]);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">PRODUZIONE</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center row flex-wrap flex-md-nowrap justify-content-center">
            <div className="d-flex col-md-4 col-12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  value={searchValue}
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
                <div
                  style={{ cursor: "pointer" }}
                  className="input-group-prepend"
                  onClick={() => {
                    setSearchValue("");
                    return applyFilter("search", "", produzioni);
                  }}
                >
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-x-lg fs-6`}></i>
                  </span>
                </div>
              </div>
            </div>
            <ReusableButton
              text="RICALCOLA"
              icon="arrow-clockwise"
              size="xxs"
              className={`mt-md-0 mt-2 ${
                produzioniSel.length === 0 ? "d-none" : "d-flex"
              }`}
              onClick={handlePrezzoProduzione}
            />

            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className="mt-md-0 mt-2"
              onClick={() =>
                navigate(`/home/produzione/dettaglio-produzione`, {
                  state: {
                    produzione: [],
                    operazione: "Nuovo",
                    operazionePadre: "Nuovo",
                    utente: nomeUtente,
                  },
                })
              }
            />
            <ReusableButton
              text="EXCEL"
              icon="file-earmark-spreadsheet"
              size="xxs"
              className="mt-md-0 mt-2"
              onClick={() => {
                document.getElementById("printpProduzione").click();
              }}
            />
            <ReusableButton
              text="ELIMINA"
              icon="trash"
              size="xxs"
              className="bg-red mt-md-0 mt-2"
              onClick={handleDeleteProduzione}
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            columnVisibilityModel={columnVisibilityModel}
            rowHeight={22}
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={produzioniVisibili}
            columns={columns}
            getRowId={(row) => row.id_prod}
            pageSize={20}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              const dataProd = produzioni.filter(
                (prod) => prod.id === params.row.id
              );
              if (searchValue !== "") {
                navigate(`/home/produzione/dettaglio-produzione`, {
                  state: {
                    commessa: dataProd[0].commessa,
                    produzione: dataProd[0],
                    operazione: "Modifica",
                    search: searchValue,
                    operazionePadre: "Modifica",
                    utente: dataProd[0].utente,
                  },
                });
              } else {
                navigate(`/home/produzione/dettaglio-produzione`, {
                  state: {
                    commessa: dataProd[0].commessa,
                    produzione: dataProd[0],
                    operazione: "Modifica",
                    search: searchValue,
                    utente: dataProd[0].utente,
                  },
                });
              }
            }}
            onRowSelectionModelChange={(newSelection) =>
              setProduzioniSel(newSelection)
            }
            selectionModel={produzioniSel}
          />
        </div>

        <div className="p-3">
          <div className="d-flex justify-content-center align-items-center mb-3 col-md-6 col-12 mx-auto gap-2 flex-wrap flex-md-nowrap">
            <div className="col-md-6">
              <CFormFloating className="col-6 py-2" style={{ width: "100%" }}>
                <CFormSelect
                  id="floatingSelect"
                  name="lavorazione"
                  options={listaLavorazioni}
                  value={valueProduzione}
                  onChange={(e) => {
                    setValueProduzione(e.target.value);
                  }}
                />
                <CFormLabel>Lavorazione</CFormLabel>
              </CFormFloating>
            </div>
            <CFormFloating className="col-md-2 col-12">
              <ReusableButton
                onClick={() => {
                  handleMonth(
                    tableMonth.val,
                    tableMonth.anno,
                    produzioni,
                    valueProduzione
                  );
                }}
                text="FILTRA"
                icon="funnel"
                size="xs"
                id="btnAgg"
                className="my-auto m-0"
              />
            </CFormFloating>
          </div>
          <div className="d-flex justify-content-center align-items-baseline flex-wrap flex-md-nowrap">
            <h2
              className="col-md-2"
              onClick={() => {
                handleMonth(
                  tableMonth.val - 1,
                  tableMonth.anno,
                  produzioni,
                  valueProduzione
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-arrow-left-circle-fill"></i>
            </h2>
            <h1 className="col-md-3 col-8">
              {tableMonth.nome + " " + tableMonth.anno}
            </h1>
            <h2
              className="col-md-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleMonth(
                  tableMonth.val + 1,
                  tableMonth.anno,
                  produzioni,
                  valueProduzione
                );
              }}
            >
              <i className="bi bi-arrow-right-circle-fill"></i>
            </h2>
          </div>
          {mesi[0].val !== "PRODUZIONI NON PRESENTI" ? (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>COMMESSA</th>
                    {Object.keys(mesi[0])?.map((el) => {
                      if (
                        el !== "commessa" &&
                        el !== "committente" &&
                        el !== "indirizzo"
                      ) {
                        return <th>{el.length === 1 ? "0" + el : el}</th>;
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {mesi.map((el) => (
                    <tr>
                      <Tooltip
                        title={el.committente + ", " + el.indirizzo}
                        placement="top"
                      >
                        <th scope="row">{el.commessa}</th>
                      </Tooltip>
                      {Object.keys(el).map((row) => {
                        if (
                          row !== "commessa" &&
                          row !== "committente" &&
                          row !== "indirizzo"
                        ) {
                          return (
                            <td
                              style={{
                                border: "2px white solid",
                                borderRadius: "8px",
                                backgroundColor:
                                  el[row] === 0
                                    ? "grey"
                                    : el[row] === 1
                                    ? "#2d338a"
                                    : "red",
                              }}
                            ></td>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h5>PRODUZIONI NON PRESENTI</h5>
          )}
        </div>
      </div>
      <div style={{ display: "none" }}>
        <ReactHtmlTableToExcel
          id="printpProduzione"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={"Produzioni"}
          sheet={"Produzioni"}
          buttonText="Produzioni"
        />
      </div>
      <table
        id="table-to-xls"
        style={{ marginTop: 10, display: "None" }}
        cellSpacing={0}
        border={0}
      >
        <tr>
          <th>ID</th>
          <th>ID Intestazione</th>
          <th>Data</th>
          <th>Commessa</th>
          <th>Utente</th>
          <th>Wbs</th>
          <th>Lavorazione</th>
          <th>Quantità</th>
          <th>Noleggio</th>
          <th>Attrezzatura</th>
          <th>Descrizione</th>
          <th>Operatore</th>
          <th>Note</th>
          <th>Produzione</th>
        </tr>
        {produzioniVisibili?.map((prod, index) => (
          <tr key={prod.id_prod}>
            <td>{prod.id_prod}</td>
            <td>{prod.id}</td>
            <td>{moment(prod.data).format("YYYY-MM-DD")}</td>
            <td>{prod.cantiere}</td>
            <td>{prod.utente}</td>
            <td>{prod.wbs}</td>
            <td>{prod.lavorazioni}</td>
            <td>{prod.quantita}</td>
            <td>{prod.noleggio}</td>
            <td>{prod.attrezzatura}</td>
            <td>{prod.descrizione}</td>
            <td>{prod.operatori}</td>
            <td>{prod.note}</td>
            <td>{prod.produzione}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default Poduzione;
