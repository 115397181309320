export const DetailControlled = ({
  headerText,
  closeModal,
  footerLeft,
  footerRight,
  children,
}) => {
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic mx-4 mx-md-0">
        {/* HEADER */}
        <div className="card-header position-relative">
          <span className="fs-4 col">{headerText}</span>
          <span
            className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          >
            <i className="bi bi-x fs-1 my-auto"></i>
          </span>
        </div>
        {/* BODY */}
        <div className="card-body">{children}</div>
        {/* FOOTER */}
        <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
          {/* LEFT */}
          <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap">
            {footerLeft}
          </div>
          {/* RIGHT */}
          <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
            {footerRight}
          </div>
        </div>
      </div>
    </div>
  );
};

/* 

<DetailControlled
    headerText=""
    closeModal={() => {
        
    }}
    footerRight={
        <>
        
        </>
    }
    >
    {...}
</DetailControlled>

*/
