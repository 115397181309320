import React from "react";

import { Snackbar, Alert } from "@mui/material";

const GlobalSnackbar = (props) => {
  const { openSnack, handleCloseSnack, severity, messaggio } = props;

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        style={{ marginTop: "50px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {messaggio}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GlobalSnackbar;

/*
Da usare :

import--> import GlobalSnackbar from "./Snackbar";

variabili da dichiarare per lo snack -> 
const [openSnack, setOpenSnack] = useState(false);
const handleCloseSnack = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnack(false);
}
const [snack,setSnack] = useState({severity:"success",messaggio:"Salvataggio Effettuato correttamente",open:false});

il componente ->  <GlobalSnackbar severity={snack.severity} messaggio={snack.messaggio} openSnack={openSnack} handleCloseSnack={handleCloseSnack}/>

funzioni per aprire e modificare messaggio / severity -> 
setSnack({severity:"success",messaggio:"Cliente creato con successo"}) ;setOpenSnack(true);

SUCCESS=> 
setSnack({ severity: "success", messaggio: "*** creato con successo" });
setOpenSnack(true);

ERROR=> 
setSnack({ severity: "error", messaggio: "Errore durante la creazione" });
setOpenSnack(true);

QUANDO DEVI PASSARE I VALORI COME PROPS:
<ModalLavoratori
  id="add"
  mansioni={mansioni}
  sedi={sedi}
  saveFunction={handleSave}
  snack={snack}
  openSnack={openSnack}
  handleCloseSnack={handleCloseSnack}
  setSnack={setSnack}
  setOpenSnack={setOpenSnack}
/>

<GlobalSnackbar
  severity={props.snack.severity}
  messaggio={props.snack.messaggio}
  openSnack={props.openSnack}
  handleCloseSnack={props.handleCloseSnack}
/>

*/
//DIALOG TITLE
/* 
sx={{mx: 'auto'}}
*/

//FOOTER BUTTONS
/* 
<DialogActions sx={{display: 'flex', justifyContent: 'space-between', mx: 2}}>
          <Button variant="contained"
              className="ButtonMiller"
              startIcon={<Close />} onClick={handleClose}>annulla</Button>
          <Button variant="contained"
              className="ButtonMiller"
              startIcon={<Check />} onClick={handleSave}>Conferma</Button>
        </DialogActions>
 */
