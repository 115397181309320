import React, { useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  ReferenceArea,
} from "recharts";

import TextField from "@mui/material/TextField";
import moment from "moment";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import "../index.scss";
import api from "./api";
import { useEffect } from "react";
import enLocale from "date-fns/locale/en-IE";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ReusableButton from "./Reusable/Buttons";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import {
  DataGridPro,
  itIT,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

const columnsNote = [
  {
    field: "data",
    headerName: "Data",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lav",
    headerName: "Lavorazioni",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "note",
    headerName: "Note",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

const Home = () => {
  const group = localStorage.getItem("Group");
  const nome = localStorage.getItem("nomeUtente");

  const [dataAtt, setDataAtt] = useState([]);
  const [dataAttBackup, setDataAttBackup] = useState([]);
  const [elementiAtt, setElementiAtt] = useState([]);
  const [elementiAttrezzatureAtt, setElementiAttrezzatureAtt] = useState([]);

  const [dataCan, setDataCan] = useState([]);
  const [dataCanBackup, setDataCanBackup] = useState([]);
  const [elementiCan, setElementiCan] = useState([]);
  const [elementiCanCommesse, setElementiCanCommesse] = useState([]); // secondo filtro COMMESSE

  const [dataOpe, setDataOpe] = useState([]);
  const [dataOpeBackup, setDataOpeBackup] = useState([]);
  const [elementiOperatoriLav, setElementiOperatoriLav] = useState([]);
  const [elementiOperatoriOpe, setElementiOperatoriOpe] = useState([]);

  const [dataAttrezzature, setDataAttrezzature] = useState([]);
  const [dataAttrezzatureBackup, setDataAttrezzatureBackup] = useState([]);
  const [elementiDataAttrezzature, setElementiDataAttrezzature] = useState([]);

  const [dataData, setDataData] = useState([]);
  const [dataDataBackup, setDataDataBackup] = useState([]);
  const [elementiData, setElementiData] = useState([]);
  const [elementiDataCommesse, setElementiDataCommesse] = useState([]);
  const [elementiDataWbs, setElementiDataWbs] = useState([]);
  const [committenteCantieri, setCommittenteCantieri] = useState([]);
  const [viaCantieri, setViaCantieri] = useState([]);
  const [noteCantieri, setNoteCantieri] = useState(false);
  const [noteCantieriDati, setNoteCantieriDati] = useState([]);
  const [tabella, setTabella] = useState(false);
  const [tabellaDati, setTabellaDati] = useState([]);
  const [header, setHeader] = useState([]);

  const [dataLav, setDataLav] = useState([]);
  const [dataLavBackup, setDataLavBackup] = useState([]);
  const [elementiLavorazioniCom, setElementiLavorazioniCom] = useState([]);
  const [elementiLavorazioniLav, setElementiLavorazioniLav] = useState([]);

  const [mediaProd, setMediaProd] = useState([]);
  const [mediaProdBackup, setMediaProdBackup] = useState([]);
  const [maxProd, setMaxProd] = useState([]);
  const [maxProdBackup, setMaxProdBackup] = useState([]);
  const [minProd, setMinProd] = useState([]);
  const [minProdBackup, setMinProdBackup] = useState([]);
  const [sumProd, setSumProd] = useState([]);
  const [sumProdBackup, setSumProdBackup] = useState([]);
  const [mediaProduzione, setMediaProduzione] = useState([]);
  const [mediaProduzioneBackup, setMediaProduzioneBackup] = useState([]);
  const [prodLav, setProdLav] = useState([]);
  const [prodLavBackup, setProdLavBackup] = useState([]);
  const [prodColore, setProdColore] = useState([]);
  const [prodColoreBackup, setProdColoreBackup] = useState([]);

  const [filtroOperatoreLav, setFiltroOperatoreLav] = useState([]);
  const [filtroOperatoreOpe, setFiltroOperatoreOpe] = useState([]);

  const [filtroLavorazioniLav, setFiltroLavorazioniLav] = useState([]);
  const [filtroLavorazioniCom, setFiltroLavorazioniCom] = useState([]);

  const [filterCan, setFilterCan] = useState([]);
  const [filterCanCommesse, setFilterCanCommesse] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterDataCan, setFilterDataCan] = useState([]);
  const [filterDataWbs, setFilterDataWbs] = useState([]);
  const [filtroAttrezzatureAtt, setFiltroAttrezzatureAtt] = useState([]);
  const [filtroAttrezzature, setFiltroAttrezzature] = useState([]); //ogni volta che utilizzo tasto filtro svuoterò

  const [allAttrezzature, setAllAttrezzature] = useState([]);
  const [allLavorazioni, setAllLavorazioni] = useState([]);

  const dayINeed = 1; // for Thursday
  const today = moment().isoWeekday(dayINeed).format("YYYY-MM-DD");
  const [filtroDa, setFiltroDa] = useState(today);
  const [filtroFino, setFiltroFino] = useState(
    moment(today).add(1, "weeks").format("YYYY-MM-DD")
  );
  const handleChangeCantieriGiornaliero = (event) => {
    const {
      target: { value },
    } = event;
    setFilterDataCan(typeof value === "number" ? value.split(",") : value);
    applyFilterGiornaliero(value, "cantieri");
  };
  const handleChangeWBSGiornaliero = (event) => {
    const {
      target: { value },
    } = event;
    setFilterDataWbs(typeof value === "string" ? value.split(",") : value);
    applyFilterGiornaliero(value, "wbs");
  };
  const handleChangeFilterData = (event) => {
    const {
      target: { value },
    } = event;

    setFilterData(typeof value === "string" ? value.split(",") : value);
    applyFilterGiornaliero(value, "lavorazioni");
  };

  const applyFilterGiornaliero = (arr, tipologia) => {
    switch (tipologia) {
      case "lavorazioni":
        let lavFiltroLavorazioni = [];
        let wbsFiltroLavorazioni = [];
        let canFiltroLavorazioni = [];

        //controllo se il filtro cantieri e pieno
        if (filterDataCan.length === 0) {
          let allElementsCan = [...elementiDataCommesse];
          canFiltroLavorazioni = allElementsCan;
        } else {
          let elementsCan = [...filterDataCan];
          canFiltroLavorazioni = elementsCan;
        }

        //controllo se il filtro wbs e pieno
        if (filterDataWbs.length === 0) {
          let allElementsWbs = [...elementiDataWbs];
          wbsFiltroLavorazioni = allElementsWbs;
        } else {
          let elementsWbs = [...filterDataWbs];
          wbsFiltroLavorazioni = elementsWbs;
        }

        //controllo se il filtro lavorazioni e pieno
        if (arr.length === 0) {
          let allElementsLav = elementiData.map((el) => el.lavorazioni);
          lavFiltroLavorazioni = allElementsLav;
        } else {
          let elementsLav = [...arr];
          lavFiltroLavorazioni = elementsLav;
        }

        //richiamo la funzione che aggiorna i calcoli nell'intestazione
        FiltroProduzione(
          aggiuntaApiciElmArr(lavFiltroLavorazioni),
          canFiltroLavorazioni,
          aggiuntaApiciElmArr(wbsFiltroLavorazioni)
        );

        let dataGraficoLav = [];
        let noteLav = [];
        for (let j = 0; j < allLavorazioni.length; j++) {
          let currentData = allLavorazioni[j].date;
          let currentLav = eval(allLavorazioni[j].lavorazioni);
          let temp = {};
          for (let i = 0; i < currentLav.length; i++) {
            if (currentLav[i].quantita !== 0) {
              //qui inserirò i filtri
              if (
                canFiltroLavorazioni.indexOf(currentLav[i].commessa) >= 0 &&
                wbsFiltroLavorazioni.indexOf(currentLav[i].wbs) >= 0 &&
                lavFiltroLavorazioni.indexOf(currentLav[i].lavorazione) >= 0
              ) {
                if (temp.hasOwnProperty(currentLav[i].lavorazione)) {
                  //se quantità è a zero tolgo
                  //se le lavorazioni sono uguali sommo la quantità
                  temp[currentLav[i].lavorazione] += currentLav[i].quantita;
                } else {
                  temp[currentLav[i].lavorazione] = currentLav[i].quantita;
                }
                noteLav.push({
                  note: currentLav[i].note,
                  lav: currentLav[i].lavorazione,
                  data: currentData,
                });

                temp.name = currentData;
              }
            }
          }
          dataGraficoLav.push(temp);
        }
        dataTable(dataGraficoLav, lavFiltroLavorazioni, false);
        setNoteCantieriDati(noteLav);
        setDataData(dataGraficoLav);
        break;

      case "cantieri": // qua è tutto come le lavorazioni, ma cambia l'array perchè quello passato è legato ai cantieri
        let lavFiltroCantieri = [];
        let wbsFiltroCantieri = [];
        let canFiltroCantieri = [];

        //controllo se il filtro cantieri e pieno
        if (arr.length === 0) {
          let allElementsCan = [...elementiDataCommesse];
          canFiltroCantieri = allElementsCan;
        } else {
          let elementsCan = [...arr];
          canFiltroCantieri = elementsCan;
        }

        //controllo se il filtro wbs e pieno
        if (filterDataWbs.length === 0) {
          let allElementsWbs = [...elementiDataWbs];
          wbsFiltroCantieri = allElementsWbs;
        } else {
          let elementsWbs = [...filterDataWbs];
          wbsFiltroCantieri = elementsWbs;
        }

        //controllo se il filtro lavorazioni e pieno
        if (filterData.length === 0) {
          let allElementsLav = elementiData.map((el) => el.lavorazioni);
          lavFiltroCantieri = allElementsLav;
        } else {
          let elementsLav = [...filterData];
          lavFiltroCantieri = elementsLav;
        }

        //richiamo la funzione che aggiorna i calcoli nell'intestazione
        FiltroProduzione(
          aggiuntaApiciElmArr(lavFiltroCantieri),
          canFiltroCantieri,
          aggiuntaApiciElmArr(wbsFiltroCantieri)
        );

        let dataGraficoCan = [];
        let attGraficoCan = [];
        let noteCan = [];

        for (let j = 0; j < allLavorazioni.length; j++) {
          let currentData = allLavorazioni[j].date;
          let currentLav = eval(allLavorazioni[j].lavorazioni);
          let temp = {};
          for (let i = 0; i < currentLav.length; i++) {
            if (currentLav[i].quantita !== 0) {
              //qui inserirò i filtri
              if (
                canFiltroCantieri.indexOf(currentLav[i].commessa) >= 0 &&
                wbsFiltroCantieri.indexOf(currentLav[i].wbs) >= 0 &&
                lavFiltroCantieri.indexOf(currentLav[i].lavorazione) >= 0
              ) {
                if (temp.hasOwnProperty(currentLav[i].lavorazione)) {
                  //se quantità è a zero tolgo
                  //se le lavorazioni sono uguali sommo la quantità
                  temp[currentLav[i].lavorazione] += currentLav[i].quantita;
                } else {
                  temp[currentLav[i].lavorazione] = currentLav[i].quantita;
                }
                temp.name = currentData;
                noteCan.push({
                  note: currentLav[i].note,
                  lav: currentLav[i].lavorazione,
                  data: currentData,
                });
              }
            }
          }
          dataGraficoCan.push(temp);
        }

        for (let j = 0; j < allAttrezzature.length; j++) {
          let currentData = allAttrezzature[j].date;
          let currentAttr = eval(allAttrezzature[j].lavorazioni);
          let temp = {};
          for (let i = 0; i < currentAttr.length; i++) {
            if (currentAttr[i].quantita !== 0) {
              //qui inserirò i filtri
              if (
                canFiltroCantieri.indexOf(currentAttr[i].commessa) >= 0 &&
                wbsFiltroCantieri.indexOf(currentAttr[i].wbs) >= 0 /* &&
                lavFiltroCantieri.indexOf(currentAttr[i].lavorazione) >= 0 */
              ) {
                if (temp.hasOwnProperty(currentAttr[i].lavorazione)) {
                  //se quantità è a zero tolgo
                  //se le lavorazioni sono uguali sommo la quantità
                  temp[currentAttr[i].lavorazione] += currentAttr[i].quantita;
                } else {
                  temp[currentAttr[i].lavorazione] = currentAttr[i].quantita;
                }
                temp.name = currentData;
              }
            }
          }
          attGraficoCan.push(temp);
        }
        dataTable(dataGraficoCan, lavFiltroCantieri, false);
        setNoteCantieriDati(noteCan);
        setDataData(dataGraficoCan);
        setDataAttrezzature(attGraficoCan);

        break;
      case "wbs":
        let lavFiltroWbs = [];
        let wbsFiltroWbs = [];
        let canFiltroWbs = [];

        //controllo se il filtro cantieri e pieno
        if (filterDataCan.length === 0) {
          let allElementsCan = [...elementiDataCommesse];
          canFiltroWbs = allElementsCan;
        } else {
          let elementsCan = [...filterDataCan];
          canFiltroWbs = elementsCan;
        }

        //controllo se il filtro wbs e pieno
        if (arr.length === 0) {
          let allElementsWbs = [...elementiDataWbs];
          wbsFiltroWbs = allElementsWbs;
        } else {
          let elementsWbs = [...arr];
          wbsFiltroWbs = elementsWbs;
        }

        //controllo se il filtro lavorazioni e pieno
        if (filterData.length === 0) {
          let allElementsLav = elementiData.map((el) => el.lavorazioni);
          lavFiltroWbs = allElementsLav;
        } else {
          let elementsLav = [...filterData];
          lavFiltroWbs = elementsLav;
        }

        //richiamo la funzione che aggiorna i calcoli nell'intestazione
        FiltroProduzione(
          aggiuntaApiciElmArr(lavFiltroWbs),
          canFiltroWbs,
          aggiuntaApiciElmArr(wbsFiltroWbs)
        );

        let dataGraficoWbs = [];
        let attGraficoWbs = [];
        let noteWbs = [];

        for (let j = 0; j < allLavorazioni.length; j++) {
          let currentData = allLavorazioni[j].date;
          let currentLav = eval(allLavorazioni[j].lavorazioni);
          let temp = {};
          for (let i = 0; i < currentLav.length; i++) {
            if (currentLav[i].quantita !== 0) {
              //qui inserirò i filtri
              if (
                canFiltroWbs.indexOf(currentLav[i].commessa) >= 0 &&
                wbsFiltroWbs.indexOf(currentLav[i].wbs) >= 0 &&
                lavFiltroWbs.indexOf(currentLav[i].lavorazione) >= 0
              ) {
                if (temp.hasOwnProperty(currentLav[i].lavorazione)) {
                  //se quantità è a zero tolgo
                  //se le lavorazioni sono uguali sommo la quantità
                  temp[currentLav[i].lavorazione] += currentLav[i].quantita;
                } else {
                  temp[currentLav[i].lavorazione] = currentLav[i].quantita;
                }
                temp.name = currentData;
                noteWbs.push({
                  note: currentLav[i].note,
                  lav: currentLav[i].lavorazione,
                  data: currentData,
                });
              }
            }
          }
          dataGraficoWbs.push(temp);
        }

        for (let j = 0; j < allAttrezzature.length; j++) {
          let currentData = allAttrezzature[j].date;
          let currentAttr = eval(allAttrezzature[j].lavorazioni);
          let temp = {};
          for (let i = 0; i < currentAttr.length; i++) {
            if (currentAttr[i].quantita !== 0) {
              //qui inserirò i filtri
              if (
                canFiltroWbs.indexOf(currentAttr[i].commessa) >= 0 &&
                wbsFiltroWbs.indexOf(currentAttr[i].wbs) >= 0 /* &&
                lavFiltroWbs.indexOf(currentAttr[i].lavorazione) >= 0 */
              ) {
                if (temp.hasOwnProperty(currentAttr[i].lavorazione)) {
                  //se quantità è a zero tolgo
                  //se le lavorazioni sono uguali sommo la quantità
                  temp[currentAttr[i].lavorazione] += currentAttr[i].quantita;
                } else {
                  temp[currentAttr[i].lavorazione] = currentAttr[i].quantita;
                }
                temp.name = currentData;
              }
            }
          }
          attGraficoWbs.push(temp);
        }
        setDataAttrezzature(attGraficoWbs);
        dataTable(dataGraficoWbs, lavFiltroWbs, false);
        setNoteCantieriDati(noteWbs);
        setDataData(dataGraficoWbs);
        break;
    }
  };

  //#region FUNZIONI

  const handleChangeFiltroAttrezzature = (event) => {
    const {
      target: { value },
    } = event;

    setFiltroAttrezzature(typeof value === "string" ? value.split(",") : value);
    handleApplyFilterAttrezzature(value, "lavorazioni");
  };
  const handleChangeFilterCan = (event) => {
    const {
      target: { value },
    } = event;
    setFilterCan(typeof value === "string" ? value.split(",") : value);

    handleApplyFilterCommesse(value, "lavorazioni");
  };
  const handleChangeFilterCanCommesse = (event) => {
    const {
      target: { value },
    } = event;

    setFilterCanCommesse(typeof value === "number" ? value.split(",") : value);
    handleApplyFilterCommesse(value, "cantieri");
  };
  const handleApplyFilterCommesse = (arr, filtro) => {
    //APPLICHI I FILTRI AL GRAFICO COMMESSE
    let d = [...dataCanBackup];

    switch (filtro) {
      case "lavorazioni":
        if (arr.length === 0 && filterCanCommesse.length === 0) {
          //se è vuoto reimposto
          setDataCan(dataCanBackup);
          return;
        }

        let res = [];
        let allElements = elementiCan.map((el) => el.lavorazioni); //tutte le lavorazioni
        let difference = allElements.filter((x) => !arr.includes(x)); //trovo quelli da eliminare
        if (arr.length > 0) {
          // se non ho nulla selezionato perchè il menù a tendina delle lavorazioni torna vuoto vado all'else
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], difference);
            res.push(result);
          }

          if (filterCanCommesse.length > 0) {
            //anche qui devo assicurarmi che ci siano dati sul quale filtrare perchè se fosse vuoto, si azzererebbe l'array
            res = res.filter((r) => filterCanCommesse.indexOf(r.name) >= 0);
          }
        } else {
          //essendo vuoto riparto assegnando a res tutti i dati
          res = [...dataCanBackup];
          if (filterCanCommesse.length > 0) {
            res = res.filter((r) => filterCanCommesse.indexOf(r.name) >= 0);
          }
        }
        setDataCan(res);

        break;

      case "cantieri": // qua è tutto come le lavorazioni, ma cambia l'array perchè quello passato è legato ai cantieri
        if (arr.length === 0 && filterCan.length === 0) {
          setDataCan(dataCanBackup);
          return;
        }

        let resCantieri = [];
        let allElementsCantieri = elementiCan.map((el) => el.lavorazioni);
        let differenceCantieri = allElementsCantieri.filter(
          (x) => !filterCan.includes(x)
        );

        if (filterCan.length > 0) {
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], differenceCantieri);
            resCantieri.push(result);
          }
          if (arr.length > 0) {
            resCantieri = resCantieri.filter((r) => arr.indexOf(r.name) >= 0);
          }
        } else {
          resCantieri = [...dataCanBackup];
          if (arr.length > 0) {
            resCantieri = resCantieri.filter((r) => arr.indexOf(r.name) >= 0);
          }
        }

        setDataCan(resCantieri);

        break;
    }
  };
  const handleApplyFilterAttrezzature = (arr, filtro) => {
    //APPLICHI I FILTRI AL GRAFICO COMMESSE
    let d = [...dataAttBackup];

    switch (filtro) {
      case "lavorazioni":
        if (arr.length === 0 && filtroAttrezzatureAtt.length === 0) {
          //se è vuoto reimposto
          setDataAtt(dataAttBackup);
          return;
        }

        let res = [];

        let allElements = elementiAtt.map((el) => el.lavorazioni); //tutte le lavorazioni
        let difference = allElements.filter((x) => !arr.includes(x)); //trovo quelli da eliminare

        if (arr.length > 0) {
          // se non ho nulla selezionato perchè il menù a tendina delle lavorazioni torna vuoto vado all'else
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], difference);
            res.push(result);
          }
          if (filtroAttrezzatureAtt.length > 0) {
            //anche qui devo assicurarmi che ci siano dati sul quale filtrare perchè se fosse vuoto, si azzererebbe l'array
            res = res.filter((r) => filtroAttrezzatureAtt.indexOf(r.name) >= 0);
          }
        } else {
          //essendo vuoto riparto assegnando a res tutti i dati
          res = [...dataAttBackup];
          if (filtroAttrezzatureAtt.length > 0) {
            res = res.filter((r) => filtroAttrezzatureAtt.indexOf(r.name) >= 0);
          }
        }
        setDataAtt(res);

        break;

      case "attrezzature": // qua è tutto come le lavorazioni, ma cambia l'array perchè quello passato è legato ai cantieri
        if (arr.length === 0 && filtroAttrezzature.length === 0) {
          setDataAtt(dataAttBackup);
          return;
        }

        let resAttrezzature = [];
        let allElementsAttrezzature = elementiAtt.map((el) => el.lavorazioni);
        let differenceAttrezzature = allElementsAttrezzature.filter(
          (x) => !filtroAttrezzature.includes(x)
        );

        if (filtroAttrezzature.length > 0) {
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], differenceAttrezzature);
            resAttrezzature.push(result);
          }
          if (arr.length > 0) {
            resAttrezzature = resAttrezzature.filter(
              (r) => arr.indexOf(r.name) >= 0
            );
          }
        } else {
          resAttrezzature = [...dataAttBackup];
          if (arr.length > 0) {
            resAttrezzature = resAttrezzature.filter(
              (r) => arr.indexOf(r.name) >= 0
            );
          }
        }

        setDataAtt(resAttrezzature);

        break;
    }
  };
  const handleChangeFiltroAttrezzatureAtt = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroAttrezzatureAtt(
      typeof value === "string" ? value.split(",") : value
    );
    handleApplyFilterAttrezzature(value, "attrezzature");
  };
  const handleApplyFilterOperatori = (arr, filtro) => {
    //APPLICHI I FILTRI AL GRAFICO COMMESSE
    let d = [...dataOpeBackup];

    switch (filtro) {
      case "lavorazioni":
        if (arr.length === 0 && filtroOperatoreOpe.length === 0) {
          //se è vuoto reimposto
          setDataOpe(dataOpeBackup);
          return;
        }

        let res = [];

        let allElements = elementiOperatoriLav.map((el) => el.lavorazioni); //tutte le lavorazioni
        let difference = allElements.filter((x) => !arr.includes(x)); //trovo quelli da eliminare

        if (arr.length > 0) {
          // se non ho nulla selezionato perchè il menù a tendina delle lavorazioni torna vuoto vado all'else
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], difference);
            res.push(result);
          }
          if (filtroOperatoreOpe.length > 0) {
            //anche qui devo assicurarmi che ci siano dati sul quale filtrare perchè se fosse vuoto, si azzererebbe l'array
            res = res.filter((r) => filtroOperatoreOpe.indexOf(r.name) >= 0);
          }
        } else {
          //essendo vuoto riparto assegnando a res tutti i dati
          res = [...dataOpeBackup];
          if (filtroOperatoreOpe.length > 0) {
            res = res.filter((r) => filtroOperatoreOpe.indexOf(r.name) >= 0);
          }
        }
        setDataOpe(res);

        break;

      case "operatori": // qua è tutto come le lavorazioni, ma cambia l'array perchè quello passato è legato ai cantieri
        if (arr.length === 0 && filtroOperatoreLav.length === 0) {
          setDataOpe(dataOpeBackup);
          return;
        }

        let resOperatore = [];
        let allElementsOperatore = elementiOperatoriOpe.map(
          (el) => el.operatore
        );
        let differenceOperatore = allElementsOperatore.filter(
          (x) => !filtroOperatoreLav.includes(x)
        );

        if (filtroOperatoreLav.length > 0) {
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], differenceOperatore);
            resOperatore.push(result);
          }
          if (arr.length > 0) {
            resOperatore = resOperatore.filter((r) => arr.indexOf(r.name) >= 0);
          }
        } else {
          resOperatore = [...dataOpeBackup];
          if (arr.length > 0) {
            resOperatore = resOperatore.filter((r) => arr.indexOf(r.name) >= 0);
          }
        }
        setDataOpe(resOperatore);

        break;
    }
  };
  const handleChangeFiltroOperaotreOpe = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroOperatoreOpe(typeof value === "string" ? value.split(",") : value);
    handleApplyFilterOperatori(value, "operatori");
    /* let allElements = elementiOperatoriLav.map((el) => el.lavorazioni);
    let difference = allElements.filter((x) => !value.includes(x));

    setFiltroOperatore(typeof value === "string" ? value.split(",") : value);
    let d = [...dataOpeBackup];
    let arr = [];
    if (value.length === 0) {
      setDataOpe(dataOpeBackup);
    }

    for (let i = 0; i < d.length; i++) {
      let result = without(d[i], difference);
      arr.push(result);
    }

    setDataOpe(arr);
    valueOpe = value; */
  };
  const handleChangeFiltroOperaotreLav = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroOperatoreLav(typeof value === "string" ? value.split(",") : value);
    handleApplyFilterOperatori(value, "lavorazioni");
  };
  const handleChangeFiltroLavorazioniCom = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroLavorazioniCom(
      typeof value === "number" ? value.split(",") : value
    );
    handleApplyFilterLavorazioni(value, "cantieri");
  };
  const handleChangeFiltroLavorazioniLav = (event) => {
    const {
      target: { value },
    } = event;
    setFiltroLavorazioniLav(
      typeof value === "string" ? value.split(",") : value
    );
    handleApplyFilterLavorazioni(value, "lavorazioni");
  };
  const handleApplyFilterLavorazioni = (arr, filtro) => {
    let d = [...dataLavBackup];

    switch (filtro) {
      case "cantieri":
        if (arr.length === 0 && filtroLavorazioniLav.length === 0) {
          setDataLav(dataLavBackup);
          return;
        }

        let res = [];

        let allElements = elementiLavorazioniCom.map((el) => el.cantieri);
        let difference = allElements.filter((x) => !arr.includes(x));

        if (arr.length > 0) {
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], difference);
            res.push(result);
          }
          if (filtroLavorazioniLav.length > 0) {
            res = res.filter((r) => filtroLavorazioniLav.indexOf(r.name) >= 0);
          }
        } else {
          res = [...dataLavBackup];
          if (filtroLavorazioniLav.length > 0) {
            res = res.filter((r) => filtroLavorazioniLav.indexOf(r.name) >= 0);
          }
        }
        setDataLav(res);

        break;

      case "lavorazioni":
        if (arr.length === 0 && filtroLavorazioniCom.length === 0) {
          setDataLav(dataLavBackup);
          return;
        }

        let resLavorazione = [];
        let allElementsLavorazione = elementiLavorazioniCom.map(
          (el) => el.cantieri
        );
        let differenceLavorazione = allElementsLavorazione.filter(
          (x) => !filtroLavorazioniCom.includes(x)
        );

        if (filtroLavorazioniCom.length > 0) {
          for (let i = 0; i < d.length; i++) {
            let result = without(d[i], differenceLavorazione);
            resLavorazione.push(result);
          }

          if (arr.length > 0) {
            resLavorazione = resLavorazione.filter(
              (r) => arr.indexOf(r.name) >= 0
            );
          }
          //secondo controllo
          /* if (filtroLavorazioniCom.length > 0) {
            resLavorazione = resLavorazione.filter((r) => arr.indexOf(r.name) >= 0);
          } */
        } else {
          resLavorazione = [...dataLavBackup];
          if (arr.length > 0) {
            resLavorazione = resLavorazione.filter(
              (r) => arr.indexOf(r.name) >= 0
            );
          }
        }
        setDataLav(resLavorazione);

        break;
    }
  };
  const [ready, setReady] = useState(false);

  //FILTRO COMMESSA
  const fetchCantieriLavorazioni = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "commesseNomeUtente",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    } else {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "cantieriLavorazioni",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    }
    let dataGrafico = [];
    let cantieri = [];
    let committenteCantieri = [];
    let viaCantieri = [];
    let lavorazioni = [];
    let colore = [];
    let elementi = [];
    for (let i = 0; i < rows.length; i++) {
      if (lavorazioni.indexOf(rows[i].lavorazioni) < 0) {
        lavorazioni.push(rows[i].lavorazioni);
        colore.push(rows[i].colore);
        elementi.push({
          lavorazioni: rows[i].lavorazioni,
          colore: rows[i].colore,
        });
      }
      let currentCantiere = rows[i].cantieri;
      let index = 0;
      if (cantieri.indexOf(currentCantiere) < 0) {
        cantieri.push(currentCantiere);
        committenteCantieri.push(rows[i].committente);
        viaCantieri.push(rows[i].via);
        dataGrafico.push({ name: currentCantiere });
        index = cantieri.indexOf(currentCantiere);
      } else {
        index = cantieri.indexOf(currentCantiere);
      }
      dataGrafico[index][rows[i].lavorazioni] = rows[i].somma;
    }

    let arrCan = [];
    cantieri.forEach((el, index) => {
      let singoliCan = {
        cantiere: el,
        descrizione:
          " - " + committenteCantieri[index] + ", " + viaCantieri[index],
      };
      arrCan.push(singoliCan);
    });
    setElementiCanCommesse(arrCan);
    setElementiCan(elementi);
    setDataCan(dataGrafico);
    setDataCanBackup(dataGrafico);
  };

  //FILTRO GIORNALIERO
  const fetchDataLavorazioni = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "giornataNomeUtente",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    } else {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "dataLavorazioni",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    }

    let rowsSenzaZeri = rows.map((el) => {
      el["date"] = moment(el.date).local().format("YYYY-MM-DD");
      return el;
    });

    setAllLavorazioni(rowsSenzaZeri);
    let dataGrafico = [];
    let colore = [];
    let elementi = [];
    let cantieri = [];
    let committenteCantieri = [];
    let viaCantieri = [];
    let lavorazioni = [];
    let wbs = [];
    let note = [];
    let head = [];

    for (let j = 0; j < rowsSenzaZeri.length; j++) {
      let currentData = rowsSenzaZeri[j].date;
      let currentLav = eval(rowsSenzaZeri[j].lavorazioni);
      let temp = {};
      let headers = {};
      for (let i = 0; i < currentLav.length; i++) {
        if (currentLav[i].quantita !== 0) {
          //filtro wbs
          if (wbs.length > 0) {
            if (wbs.indexOf(currentLav[i].wbs) < 0) {
              wbs.push(currentLav[i].wbs);
            }
          } else {
            wbs.push(currentLav[i].wbs);
          }
          if (note.length > 0) {
            if (note.indexOf(currentLav[i].note) < 0) {
              note.push({
                note: currentLav[i].note,
                lav: currentLav[i].lavorazione,
                data: currentData,
              });
            }
          } else {
            note.push({
              note: currentLav[i].note,
              lav: currentLav[i].lavorazione,
              data: currentData,
            });
          }
          //filtro cantieri
          if (cantieri.length > 0) {
            if (cantieri.indexOf(currentLav[i].commessa) < 0) {
              cantieri.push(currentLav[i].commessa);
              committenteCantieri.push(currentLav[i].committente);
              viaCantieri.push(currentLav[i].via);
            }
          } else {
            cantieri.push(currentLav[i].commessa);
            committenteCantieri.push(currentLav[i].committente);
            viaCantieri.push(currentLav[i].via);
          }
          //filtro lavorazioni
          if (lavorazioni.length > 0) {
            if (lavorazioni.indexOf(currentLav[i].lavorazione) < 0) {
              lavorazioni.push(currentLav[i].lavorazione);
              colore.push(currentLav[i].colore);
              elementi.push({
                lavorazioni: currentLav[i].lavorazione,
                colore: currentLav[i].colore,
              });
            }
          } else {
            if (lavorazioni.indexOf(currentLav[i].lavorazione) < 0) {
              lavorazioni.push(currentLav[i].lavorazione);
              colore.push(currentLav[i].colore);
              elementi.push({
                lavorazioni: currentLav[i].lavorazione,
                colore: currentLav[i].colore,
              });
            }
          }
          //se quantità è a zero tolgo
          if (temp.hasOwnProperty(currentLav[i].lavorazione)) {
            //se le lavorazioni sono uguali sommo la quantità
            temp[currentLav[i].lavorazione] += currentLav[i].quantita;
          } else {
            temp[currentLav[i].lavorazione] = currentLav[i].quantita;
          }
          temp.name = currentData; //mi salvo le date per utilizzarle nell'header della tabella
          let stringToArray = currentData.split("-");
          stringToArray.shift();
          let senzaAnno = stringToArray.join("-");
          headers.data = senzaAnno;
        }
      }
      head.push(headers);
      dataGrafico.push(temp);
    }

    dataTable(dataGrafico, elementi, true);
    setHeader(head);
    setNoteCantieriDati(note);
    setViaCantieri(viaCantieri);
    setCommittenteCantieri(committenteCantieri);
    setElementiData(elementi); //carico dati filtro Lavoraizoni
    setElementiDataCommesse(cantieri); //carico dati filtro Cantiere
    setElementiDataWbs(wbs); //carico dati filtro Wbs

    setDataDataBackup(dataGrafico);
    setDataData(dataGrafico);
  };
  //FILTRO GIORNALIERO ATTREZZATURE
  const fetchDataAttrezzature = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "giornataAttrezzatureNomeUtente",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    } else {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "dataAttrezzatura",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    }

    let rowsSenzaZeri = rows.map((el) => {
      el["date"] = moment(el.date).local().format("YYYY-MM-DD");
      return el;
    });

    setAllAttrezzature(rowsSenzaZeri);
    let dataGrafico = [];
    let colore = [];
    let elementi = [];
    let cantieri = [];
    let committenteCantieri = [];
    let viaCantieri = [];
    let lavorazioni = [];
    let wbs = [];
    let note = [];
    let head = [];

    for (let j = 0; j < rowsSenzaZeri.length; j++) {
      let currentData = rowsSenzaZeri[j].date;
      let currentLav = eval(rowsSenzaZeri[j].lavorazioni);
      let temp = {};
      let headers = {};
      for (let i = 0; i < currentLav.length; i++) {
        if (currentLav[i].quantita !== 0) {
          //filtro wbs
          if (wbs.length > 0) {
            if (wbs.indexOf(currentLav[i].wbs) < 0) {
              wbs.push(currentLav[i].wbs);
            }
          } else {
            wbs.push(currentLav[i].wbs);
          }
          if (note.length > 0) {
            if (note.indexOf(currentLav[i].note) < 0) {
              note.push({
                note: currentLav[i].note,
                lav: currentLav[i].lavorazione,
                data: currentData,
              });
            }
          } else {
            note.push({
              note: currentLav[i].note,
              lav: currentLav[i].lavorazione,
              data: currentData,
            });
          }
          //filtro cantieri
          if (cantieri.length > 0) {
            if (cantieri.indexOf(currentLav[i].commessa) < 0) {
              cantieri.push(currentLav[i].commessa);
              committenteCantieri.push(currentLav[i].committente);
              viaCantieri.push(currentLav[i].via);
            }
          } else {
            cantieri.push(currentLav[i].commessa);
            committenteCantieri.push(currentLav[i].committente);
            viaCantieri.push(currentLav[i].via);
          }
          //filtro lavorazioni
          if (lavorazioni.length > 0) {
            if (lavorazioni.indexOf(currentLav[i].lavorazione) < 0) {
              lavorazioni.push(currentLav[i].lavorazione);
              colore.push(currentLav[i].colore);
              elementi.push({
                lavorazioni: currentLav[i].lavorazione,
                colore: currentLav[i].colore,
              });
            }
          } else {
            if (lavorazioni.indexOf(currentLav[i].lavorazione) < 0) {
              lavorazioni.push(currentLav[i].lavorazione);
              colore.push(currentLav[i].colore);
              elementi.push({
                lavorazioni: currentLav[i].lavorazione,
                colore: currentLav[i].colore,
              });
            }
          }
          //se quantità è a zero tolgo
          if (temp.hasOwnProperty(currentLav[i].lavorazione)) {
            //se le lavorazioni sono uguali sommo la quantità
            temp[currentLav[i].lavorazione] += currentLav[i].quantita;
          } else {
            temp[currentLav[i].lavorazione] = currentLav[i].quantita;
          }
          temp.name = currentData; //mi salvo le date per utilizzarle nell'header della tabella
          let stringToArray = currentData.split("-");
          stringToArray.shift();
          let senzaAnno = stringToArray.join("-");
          headers.data = senzaAnno;
        }
      }
      head.push(headers);
      dataGrafico.push(temp);
    }

    setElementiDataAttrezzature(elementi); //carico dati filtro Lavoraizoni
    setDataAttrezzature(dataGrafico);
    setDataAttrezzatureBackup(dataGrafico);
  };

  const dataTable = (arr, lav, inizio) => {
    let onlyLav = [];
    if (inizio) {
      //array con solo i nomi delle lavorazioni
      onlyLav = lav.map((l) => l.lavorazioni);
    } else {
      onlyLav = lav;
    }

    //l'obbiettivo è quello di avere la struttura seguente :
    /*
  [
  "Diaframmi":[{"data":"28/01/01","val":100},{"data":"28/01/01","val":100}]    
  
      ]
  */

    //inizio ad impostare l'array
    var res = {};
    for (let i = 0; i < onlyLav.length; i++) {
      res[onlyLav[i]] = [];
    }

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < onlyLav.length; j++) {
        let jsonOBj = {}; //oggetto vuoto

        jsonOBj.data = arr[i].name;

        if (arr[i][onlyLav[j]]) {
          //Se nell'elemento corrente c'è un valore per quella lavorazione, prendo il valore
          jsonOBj.val = arr[i][onlyLav[j]];
        } else {
          //altrimenti metto 0
          jsonOBj.val = 0;
        }
        res[onlyLav[j]].push(jsonOBj);
      }
    }
    setTabellaDati(res);
  };

  //FILTRO ATTREZZATURA
  const fetchAttrezzaturaLavorazioni = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "attrezzatureNomeUtente",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    } else {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "attrezzatureLavorazioni",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    }
    let dataGrafico = []; // array da mettere poi nel grafico
    let attrezzature = []; //array di controllo per attrezzature doppie
    let descrizione = []; //array di controllo per attrezzature doppie
    let lavorazioni = []; //array di controllo per attrezzature doppie
    let colore = [];
    let elementi = [];
    for (let i = 0; i < rows.length; i++) {
      if (lavorazioni.indexOf(rows[i].lavorazioni) < 0) {
        lavorazioni.push(rows[i].lavorazioni);
        colore.push(rows[i].colore);
        elementi.push({
          lavorazioni: rows[i].lavorazioni,
          colore: rows[i].colore,
        });
      }

      let currentAttrezzatura = rows[i].attrezzatura; //seleziono l'attrezzatura in base all'indice ( sto scorrendo l'array)
      let index = 0; // inizializzo l'indice
      if (attrezzature.indexOf(currentAttrezzatura) < 0) {
        //controllo che l'attrezzatura non sia già stata inserita. Se non è inserita ottengo -1 e quindi entro nell'if
        attrezzature.push(currentAttrezzatura); //inserisco nell'array di controllo l'attrezzatura, in modo tale che se la ritrovo non rientra più qui
        descrizione.push(rows[i].descrizione); //inserisco nell'array di controllo l'attrezzatura, in modo tale che se la ritrovo non rientra più qui

        dataGrafico.push({
          name: currentAttrezzatura,
          descrizione: rows[i].descrizione,
        }); //inserisco nell'array che andrà poi nel grafico l'attrezzatura
        index = attrezzature.indexOf(currentAttrezzatura); // cerco l'elemento appena inserito nell'array di controllo in modo tale da ottenere la sua posizione
      } else {
        index = attrezzature.indexOf(currentAttrezzatura); // se entro qui vuol dire che ho già inserito l'elemento nell'array di controllo quindi NON devo reinserirlo
      }
      dataGrafico[index][rows[i].lavorazioni] = rows[i].somma; // mi posizione nell'array che andrà nel grafico nella posizione dell'elemento (dataGrafico[index]) e ci aggiungo la proprietà con [nomeProprietà]  = valore
    }
    let arrAtt = [];
    attrezzature.forEach((el, index) => {
      let singoliAtt = {
        attrezzatura: el,
        descrizione: ` - ${descrizione[index]}`,
      };
      arrAtt.push(singoliAtt);
    });
    setElementiAttrezzatureAtt(arrAtt);
    setElementiAtt(elementi);
    setDataAtt(dataGrafico);
    setDataAttBackup(dataGrafico);
  };

  //FILTRO OPERATORE
  const fetchOperatoriLavorazioni = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "operatoreNomeUtente",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    } else {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "operatoreLavorazioni",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    }
    let dataGrafico = [];
    let operatori = [];
    let lavorazioni = [];
    let colore = [];
    let elementi = [];
    for (let i = 0; i < rows.length; i++) {
      if (lavorazioni.indexOf(rows[i].lavorazioni) < 0) {
        lavorazioni.push(rows[i].lavorazioni);
        colore.push(rows[i].colore);
        elementi.push({
          lavorazioni: rows[i].lavorazioni,
          colore: rows[i].colore,
        });
      }
      let currentAttrezzatura = rows[i].operatore;
      let index = 0;
      if (operatori.indexOf(currentAttrezzatura) < 0) {
        operatori.push(currentAttrezzatura);
        dataGrafico.push({ name: currentAttrezzatura });
        index = operatori.indexOf(currentAttrezzatura);
      } else {
        index = operatori.indexOf(currentAttrezzatura);
      }
      dataGrafico[index][rows[i].lavorazioni] = rows[i].somma;
    }
    let arrOpe = [];
    operatori.forEach((el) => {
      let singoliOpe = { operatore: el };
      arrOpe.push(singoliOpe);
    });
    setElementiOperatoriOpe(arrOpe);
    setElementiOperatoriLav(elementi);
    setDataOpe(dataGrafico);
    setDataOpeBackup(dataGrafico);
  };

  //FILTRO LAVORAZIONI
  const fetchLavorazioniCommesse = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "lavorazioniNomeUtente",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    } else {
      let response = await api.get("api/analysis", {
        params: {
          operazione: "lavorazioniCommesse",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = response.data.data;
    }
    let dataGrafico = [];
    let lavorazioni = [];
    let cantieri = [];
    let colore = [];
    let elementi = [];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].somma !== "0") {
        if (cantieri.indexOf(rows[i].cantiere) < 0) {
          cantieri.push(rows[i].cantiere);
          if (rows[i].colore === "") {
            colore.push(
              `#${Math.floor(Math.random() * 16777215).toString(16)}`
            );
          } else {
            colore.push(rows[i].colore);
          }
          if (rows[i].colore === "") {
            elementi.push({
              cantieri: rows[i].cantiere,
              colore: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              descrizione: " - " + rows[i].committente + ", " + rows[i].via,
            });
          } else {
            elementi.push({
              cantieri: rows[i].cantiere,
              colore: rows[i].colore,
              descrizione: " - " + rows[i].committente + ", " + rows[i].via,
            });
          }
        }
        let currentAttrezzatura = rows[i].lavorazioni;
        let index = 0;

        if (lavorazioni.indexOf(currentAttrezzatura) < 0) {
          lavorazioni.push(currentAttrezzatura);
          dataGrafico.push({ name: currentAttrezzatura });
          index = lavorazioni.indexOf(currentAttrezzatura);
        } else {
          index = lavorazioni.indexOf(currentAttrezzatura);
        }
        dataGrafico[index][rows[i].cantiere] = rows[i].somma;
      }
    }
    let arrLav = [];
    lavorazioni.forEach((el) => {
      let singoliLav = { lavorazione: el };
      arrLav.push(singoliLav);
    });
    setElementiLavorazioniLav(arrLav);
    setElementiLavorazioniCom(elementi);

    setDataLav(dataGrafico);
    setDataLavBackup(dataGrafico);
  };

  //FILTRO INTESTAZIONE
  const fetchLavorazioniProd = async () => {
    let rows = [];
    if (group !== "2" && group !== "9") {
      let prod = await api.get("api/analysis", {
        params: {
          operazione: "UtenteProduzionePROD",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = prod.data.data;
      let dataGrafico = [];
      let lav = [];
      let colors = [];
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].value !== "0") {
          let currentAttrezzatura = rows[i].lavorazioni;
          if (lav.indexOf(currentAttrezzatura) < 0) {
            dataGrafico.push({
              name: currentAttrezzatura,
              value: Number(rows[i].value),
            });
            colors.push(rows[i].colore);
          }
        }
        setProdLav(dataGrafico);
        setProdLavBackup(dataGrafico);
        setProdColore(colors);
        setProdColoreBackup(colors);
      }

      let avg = await api.get("api/analysis", {
        params: {
          operazione: "UtenteProduzioneAVG",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMediaProd(avg.data.data);
      setMediaProdBackup(avg.data.data);

      let max = await api.get("api/analysis", {
        params: {
          operazione: "UtenteProduzioneMAX",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMaxProd(max.data.data);
      setMaxProdBackup(max.data.data);

      let min = await api.get("api/analysis", {
        params: {
          operazione: "UtenteProduzioneMIN",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMinProd(min.data.data);
      setMinProdBackup(min.data.data);

      let sum = await api.get("api/analysis", {
        params: {
          operazione: "UtenteProduzioneSUM",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setSumProd(sum.data.data);
      setSumProdBackup(sum.data.data);

      let mediaProd = await api.get("api/analysis", {
        params: {
          operazione: "UtenteProduzioneMediaAVG",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMediaProduzione(mediaProd.data.data);
      setMediaProduzioneBackup(mediaProd.data.data);

      setReady(true);
    } else {
      let prod = await api.get("api/analysis", {
        params: {
          operazione: "ProduzionePROD",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      rows = prod.data.data;
      let dataGrafico = [];
      let lav = [];
      let colors = [];
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].value !== "0") {
          let currentAttrezzatura = rows[i].lavorazioni;
          if (lav.indexOf(currentAttrezzatura) < 0) {
            dataGrafico.push({
              name: currentAttrezzatura,
              value: Number(rows[i].value),
            });
            colors.push(rows[i].colore);
          }
        }
        setProdLav(dataGrafico);
        setProdLavBackup(dataGrafico);
        setProdColore(colors);
        setProdColoreBackup(colors);
      }

      let avg = await api.get("api/analysis", {
        params: {
          operazione: "ProduzioneAVG",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      //let filterZero = avg.data.data.filter((el) => el.produzione !== "0");
      setMediaProd(avg.data.data);
      setMediaProdBackup(avg.data.data);

      let media = await api.get("api/analysis", {
        params: {
          operazione: "ProduzioneMedieAVG",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setMediaProduzione(media.data.data);
      setMediaProduzioneBackup(media.data.data);

      let max = await api.get("api/analysis", {
        params: {
          operazione: "ProduzioneMAX",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMaxProd(max.data.data);
      setMaxProdBackup(max.data.data);

      let min = await api.get("api/analysis", {
        params: {
          operazione: "ProduzioneMIN",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMinProd(min.data.data);
      setMinProdBackup(min.data.data);

      let sum = await api.get("api/analysis", {
        params: {
          operazione: "ProduzioneSUM",
          dal: filtroDa,
          al: filtroFino,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setSumProd(sum.data.data);
      setSumProdBackup(sum.data.data);

      setReady(true);
    }
  };

  const aggiuntaApiciElmArr = (arr) => {
    let arrForFilter = [];
    for (let i = 0; i < arr.length; i++) {
      //aggiungo le virgolette per far funzionare la chiamata
      arrForFilter.push(`"${arr[i]}"`);
    }
    return arrForFilter;
  };

  //CHIAMATA MEDIA PRODUZIONE CON FILTRI
  const FiltroProduzione = async (lavorazioni, cantieri, wbsArr) => {
    setReady(false);
    if (group === "12") {
      let avg = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneAVG",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMediaProd(avg.data.data);

      let media = await api.get("api/analysis", {
        params: {
          operazione: "FiltroMediaProduzioneAVG",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMediaProduzione(media.data.data);

      let min = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneMIN",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMinProd(min.data.data);

      let max = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneMAX",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMaxProd(max.data.data);
      let prod = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneSUM",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setSumProd(prod.data.data);

      let torta = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneTorta",
          dal: filtroDa,
          al: filtroFino,
          utente: nome,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let rows = [];
      rows = torta.data.data;
      let dataGrafico = [];
      let lav = [];
      let colors = [];
      if (rows.length !== 0) {
        //controllo se è pieno
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].value !== "0") {
            let currentAttrezzatura = rows[i].lavorazioni;
            if (lav.indexOf(currentAttrezzatura) < 0) {
              dataGrafico.push({
                name: currentAttrezzatura,
                value: Number(rows[i].value),
              });
              colors.push(rows[i].colore);
            }
          }
          setProdLav(dataGrafico);

          setProdColore(colors);
        }
      } else {
        //se è vuoto cancello i colori e i dati
        dataGrafico.push({
          name: null,
          value: 0,
        });
        setProdLav(dataGrafico);
      }
    } else {
      let avg = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneAVG",
          dal: filtroDa,
          al: filtroFino,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMediaProd(avg.data.data);

      let media = await api.get("api/analysis", {
        params: {
          operazione: "FiltroMediaProduzioneAVG",
          dal: filtroDa,
          al: filtroFino,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMediaProduzione(media.data.data);

      let min = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneMIN",
          dal: filtroDa,
          al: filtroFino,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMinProd(min.data.data);

      let max = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneMAX",
          dal: filtroDa,
          al: filtroFino,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMaxProd(max.data.data);
      let prod = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneSUM",
          dal: filtroDa,
          al: filtroFino,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setSumProd(prod.data.data);

      let torta = await api.get("api/analysis", {
        params: {
          operazione: "FiltroProduzioneTorta",
          dal: filtroDa,
          al: filtroFino,
          lav: [...lavorazioni],
          can: [...cantieri],
          wbs: [...wbsArr],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let rows = [];
      rows = torta.data.data;
      let dataGrafico = [];
      let lav = [];
      let colors = [];
      if (rows.length !== 0) {
        //controllo se è pieno
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].value !== "0") {
            let currentAttrezzatura = rows[i].lavorazioni;
            if (lav.indexOf(currentAttrezzatura) < 0) {
              dataGrafico.push({
                name: currentAttrezzatura,
                value: Number(rows[i].value),
              });
              colors.push(rows[i].colore);
            }
          }
          setProdLav(dataGrafico);
          setProdColore(colors);
        }
      } else {
        //se è vuoto cancello i colori e i dati
        dataGrafico.push({
          name: null,
          value: 0,
        });
        setProdLav(dataGrafico);
      }
    }
    setReady(true);
  };

  //CALCOLO LARGHEZZA TOTALE WIDTH
  const [screenSize, getDimension] = useState(window.innerWidth);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  const handleFilter = async () => {
    //AGGIORNAMENTO DATA
    fetchDataLavorazioni();
    fetchDataAttrezzature();
    fetchAttrezzaturaLavorazioni();
    fetchCantieriLavorazioni();
    fetchOperatoriLavorazioni();
    fetchLavorazioniCommesse();
    fetchLavorazioniProd();

    //RESET VALORI FILTRI
    setFiltroOperatoreLav([]);
    setFiltroOperatoreOpe([]);
    setFiltroLavorazioniLav([]);
    setFiltroLavorazioniCom([]);
    setFilterCan([]);
    setFilterCanCommesse([]);
    setFilterData([]);
    setFilterDataCan([]);
    setFilterDataWbs([]);
    setFiltroAttrezzatureAtt([]);
    setFiltroAttrezzature([]);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  useEffect(() => {
    const fetchData = async () => {
      //INIZIALIZZAZIONE
      fetchAttrezzaturaLavorazioni();
      fetchCantieriLavorazioni();
      fetchOperatoriLavorazioni();
      fetchDataLavorazioni();
      fetchDataAttrezzature();
      fetchLavorazioniCommesse();
      fetchLavorazioniProd();
    };
    fetchData();
  }, []);
  //#endregion
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light d-flex flex-wrap col-6 mx-auto p-2 z-index">
          <p
            className={"label col-12"}
          >{`${label} - ${payload[0].payload.descrizione}`}</p>
          <span
            style={{ color: `${payload[0].fill}` }}
            className={"label col-12"}
          >{`${payload[0].name} : ${payload[0].value}`}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="mt-5">-</div>
      {/*  <div className="position-absolute end-0" style={{top: '200px', borderRadius: "50%"}}>ICON</div> */}
      <div className="bg-light mx-md-3 mx-0  rounded ">
        <div className="d-flex">
          <div
            className={`col-10 flex-wrap flex-md-nowrap mx-auto gap-2 d-flex p-2 justify-content-center`} //${window.scrollTo(0, 0) ? "shadow-black position-fixed" : ""}
            style={{ zIndex: "100" }}
          >
            <div className="col-md-3 col-12 d-flex justify-content-center">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={enLocale}
              >
                <DatePicker
                  label="data inizio"
                  value={filtroDa}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) => {
                    setFiltroDa(moment(newValue).format("YYYY-MM-DD"));
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-md-3 col-12 d-flex justify-content-center">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={enLocale}
              >
                <DatePicker
                  label="data fine"
                  value={filtroFino}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) => {
                    setFiltroFino(moment(newValue).format("YYYY-MM-DD"));
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="col-md-3 col-12 d-flex justify-content-center">
              <ReusableButton
                onClick={handleFilter}
                text="FILTRA"
                icon="funnel"
                size="xs"
                id="btnAgg"
                className="my-auto"
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap flex-md-nowrap justify-content-md-between justify-content-center ">
          <div
            className="bg-light rounded col-9 row d-flex flex-md-nowrap flex-wrap justify-content-center"
            style={{ padding: 0, margin: 0 }}
          >
            {/* Media Produzione */}
            {/* <div className="d-flex card m-2 p-2 rounded col-md-3 col-11">
              <span className="fw-bold fs-6 d-flex flex-wrap justify-content-center justify-content-md-start">
                Media:
                {mediaProd.map((el) => {
                  return (
                    <p className="my-1 fs-6 w-100 mx-auto text-center text-md-start ">
                      <span className="fs-6" style={{ color: `${el.colore}` }}>
                        {el.lavorazioni}:
                      </span>{" "}
                      <span className="fw-normal">{el.produzione}</span>
                    </p>
                  );
                })}
              </span>
            </div> */}
            {/* Min e Max */}
            <div className="d-flex card m-2 mx-0 p-2 rounded col-md-7 col-11 row flex-md-nowrap flex-md-row justify-content-center justify-content-md-start">
              <span className="h-25 fw-bold fs-6 d-flex col flex-wrap justify-content-center justify-content-md-start">
                Min:{" "}
                {minProd.map((el) => {
                  return (
                    <p className="my-1 fs-6 w-100 text-center text-md-start">
                      <span style={{ color: `${el.colore}` }}>
                        {el.lavorazioni}:
                      </span>{" "}
                      <span className="fw-normal">{el.produzione}</span>
                    </p>
                  );
                })}
              </span>
              <span className="h-25 fw-bold fs-6 d-flex col flex-wrap justify-content-center justify-content-md-start">
                Max:{" "}
                {maxProd.map((el) => {
                  return (
                    <p className="my-1 fs-6 w-100 text-center text-md-start">
                      <span style={{ color: `${el.colore}` }}>
                        {el.lavorazioni}:
                      </span>{" "}
                      <span className="fw-normal">{el.produzione}</span>
                    </p>
                  );
                })}
              </span>
              <span className="h-25 fw-bold fs-6 d-flex col flex-wrap justify-content-center justify-content-md-start">
                Media:
                {mediaProd.map((el) => {
                  return (
                    <p className="my-1 fs-6 w-100 mx-auto text-center text-md-start ">
                      <span className="fs-6" style={{ color: `${el.colore}` }}>
                        {el.lavorazioni}:
                      </span>{" "}
                      <span className="fw-normal">{el.produzione}</span>
                    </p>
                  );
                })}
              </span>
            </div>
            {/* Valore Produzione */}
            <div className="d-flex card m-2 mx-0 p-2 rounded col-md-5 col-11 row flex-md-nowrap flex-md-row justify-content-center justify-content-md-start">
              <span className="h-25 fw-bold fs-6 d-flex col flex-wrap justify-content-center justify-content-md-start">
                Valore Produzione:{" "}
                {sumProd.map((el) => {
                  return (
                    <p className="my-1 fs-6 w-100 text-center text-md-start">
                      <span style={{ color: `${el.colore}` }}>
                        {el.lavorazioni}:
                      </span>{" "}
                      <span className="fw-normal">{el.produzione}€</span>
                    </p>
                  );
                })}
              </span>
              <span className="h-25 fw-bold fs-6 d-flex col flex-wrap justify-content-center justify-content-md-start">
                Media Produzione:{" "}
                {mediaProduzione.map((el) => {
                  return (
                    <p className="my-1 fs-6 w-100 text-center text-md-start">
                      <span style={{ color: `${el.colore}` }}>
                        {el.lavorazioni}:
                      </span>{" "}
                      <span className="fw-normal">{el.produzione}€</span>
                    </p>
                  );
                })}
              </span>
            </div>
          </div>
          <div className="row-md my-auto col-md-3 mx-auto">
            <PieChart
              width={screenSize < 768 ? screenSize - 50 : screenSize / 6 + 40}
              height={250}
              className="card m-2"
            >
              {ready ? (
                <>
                  <Legend
                    verticalAlign="bottom"
                    align="center"
                    /* payload={prodLav.map((item, index) => ({
                      id: item.name,
                      type: "square",
                      value: `${item.name} ${(item.value * 100).toFixed(0)}%`,
                      //color: colors[index % colors.length],
                    }))} */
                  />
                  <Pie
                    data={prodLav}
                    cx={
                      screenSize < 768
                        ? (screenSize - 50) / 2
                        : screenSize / 6 / 2 + 20
                    }
                    cy="40%"
                    innerRadius={45}
                    outerRadius={65}
                    paddingAngle={3}
                    dataKey="value"
                    //label={renderCustomizedLabel}
                  >
                    {prodColore.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={prodColore[index % prodColore.length]}
                      />
                    ))}
                  </Pie>
                </>
              ) : (
                ""
              )}
            </PieChart>
          </div>
        </div>

        {/* GIORNALIERO */}
        <div className="d-flex mt-2 flex-wrap">
          {/* FILTRI */}
          <div className="col-12 mx-auto  h-25 d-flex justify-content-center align-items-center flex-wrap flex-md-nowrap row my-auto px-md-1 px-0">
            <FormControl className="col-md-3">
              <div className="d-flex justify-content-evenly align-items-center flex-wrap mb-3 mb-md-0">
                <ReusableButton
                  onClick={() => setNoteCantieri(!noteCantieri)}
                  text="NOTE"
                  icon="journal-bookmark-fill"
                  size="xs"
                  id="btnAgg"
                  className="my-auto"
                />
                <ReusableButton
                  onClick={() => setTabella(!tabella)}
                  text={tabella ? "GRAFICO" : "TABELLA"}
                  icon="table"
                  size="xs"
                  id="btnAgg"
                  className="my-auto"
                />
              </div>
            </FormControl>
            <FormControl className="col-md-3">
              <InputLabel id="demo-multiple-checkbox-label">
                Lavorazioni
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterData}
                onChange={handleChangeFilterData}
                input={<OutlinedInput label="Lavorazioni" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiData.map((el) => (
                  <MenuItem key={el.lavorazioni} value={el.lavorazioni}>
                    <Checkbox
                      checked={filterData.indexOf(el.lavorazioni) > -1}
                    />
                    <ListItemText primary={el.lavorazioni} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="col-md-3">
              <InputLabel id="demo-multiple-checkbox-label">
                Cantieri
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterDataCan}
                onChange={handleChangeCantieriGiornaliero}
                input={<OutlinedInput label="Cantieri" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiDataCommesse.map((el, index) => (
                  <MenuItem key={el} value={el}>
                    <Checkbox checked={filterDataCan.indexOf(el) > -1} />
                    <ListItemText
                      primary={
                        el +
                        " - " +
                        committenteCantieri[index] +
                        ", " +
                        viaCantieri[index]
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="col-md-3">
              <InputLabel id="demo-multiple-checkbox-label">Wbs</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterDataWbs}
                onChange={handleChangeWBSGiornaliero}
                input={<OutlinedInput label="Wbs" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiDataWbs.map((el) => (
                  <MenuItem key={el} value={el}>
                    <Checkbox checked={filterDataWbs.indexOf(el) > -1} />
                    <ListItemText primary={el} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {noteCantieri && noteCantieriDati ? (
            <div className="p-3 col-md-10 mx-auto" style={{ height: 300 }}>
              <DataGridPro
                components={{ Toolbar: CustomToolbar }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={noteCantieriDati}
                rowHeight={25}
                columns={columnsNote}
                getRowId={(row) => row.note}
                pageSize={10}
                disableSelectionOnClick
              />
            </div>
          ) : (
            <></>
          )}
          {/* CONTRNITORE GRAFICO */}
          <div className="col-12 h-25 my-auto card mt-2">
            <span className="mx-auto fs-4">GIORNALIERO</span>
            {tabella && tabellaDati ? (
              <>
                <div
                  className="p-3 col-md-10 mx-auto overflow-auto container-fluid table-responsive"
                  style={{ height: 300, overflowX: "auto" }}
                >
                  <div className="d-flex row justify-content-center">
                    <ReusableButton
                      text="EXCEL"
                      icon="file-earmark-spreadsheet"
                      size="xxs"
                      className="mt-md-0 mt-2"
                      onClick={() => {
                        document.getElementById("printpProduzione").click();
                      }}
                    />
                  </div>
                  <div style={{ display: "none" }}>
                    <ReactHtmlTableToExcel
                      id="printpProduzione"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename={"Note"}
                      sheet={"Note"}
                      buttonText="Note"
                    />
                  </div>
                  <table className="table table-striped" id="table-to-xls">
                    <thead>
                      <tr>
                        <th scope="col">LAVORAZIONE</th>
                        {header?.map((values) => (
                          <th>{values.data}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(tabellaDati).map((el) => (
                        <tr>
                          <th scope="row">{el}</th>
                          {tabellaDati[el].map((values) => (
                            <td>{values.val}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <BarChart
                  className="mx-auto"
                  width={screenSize - 50}
                  height={300}
                  data={dataData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {dataData.map((d) => (
                    <ReferenceArea
                      fill={"#ffffff00"}
                      x1={d.name}
                      x2={d.name}
                      stroke="#009a3d"
                      strokeOpacity={1}
                    />
                  ))}
                  {elementiData.map((el) => (
                    <Bar dataKey={el.lavorazioni} fill={el.colore} />
                  ))}
                </BarChart>
              </>
            )}
          </div>
          <div className="col-12 h-25 my-auto card mt-2">
            <span className="mx-auto fs-4">ATTREZZATURA</span>

            <BarChart
              className="mx-auto"
              width={screenSize - 50}
              height={300}
              data={dataAttrezzature}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                wrapperStyle={{
                  zIndex: 1000,
                }}
              />
              <Legend
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
                wrapperStyle={{
                  padding: "0 5px",
                  height: "75px",
                  overflow: "auto",
                }}
              />
              {dataAttrezzature.map((d) => (
                <ReferenceArea
                  fill={"#ffffff00"}
                  x1={d.name}
                  x2={d.name}
                  stroke="#009a3d"
                  strokeOpacity={1}
                />
              ))}
              {elementiDataAttrezzature.map((el) => (
                <Bar dataKey={el.lavorazioni} fill={el.colore} />
              ))}
            </BarChart>
          </div>
        </div>
      </div>
      {/* COMMESSE E LAVORAZIONI */}
      <div className="d-flex mt-4 flex-md-nowrap flex-wrap p-2 bg-light rounded  mx-md-3 mx-0">
        <div className="col-md col-12 h-25 rounded mx-2 my-2">
          <div className="col-10 mx-auto h-25 gap-2 d-flex justify-content-center flex-wrap flex-md-nowrap row my-auto">
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Lavorazioni
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterCan}
                onChange={handleChangeFilterCan}
                input={<OutlinedInput label="Lavorazioni" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiCan.map((el) => (
                  <MenuItem key={el.lavorazioni} value={el.lavorazioni}>
                    <Checkbox
                      checked={filterCan.indexOf(el.lavorazioni) > -1}
                    />
                    <ListItemText primary={el.lavorazioni} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Cantieri
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filterCanCommesse}
                onChange={handleChangeFilterCanCommesse}
                input={<OutlinedInput label="Cantieri" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiCanCommesse.map((el) => (
                  <MenuItem key={el.cantiere} value={el.cantiere}>
                    <Checkbox
                      checked={filterCanCommesse.indexOf(el.cantiere) > -1}
                    />
                    <ListItemText primary={el.cantiere + el.descrizione} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="card mt-2">
            <span className="mx-auto fs-4">COMMESSE</span>
            <BarChart
              className="mx-auto"
              width={screenSize < 768 ? screenSize - 50 : screenSize / 2 - 50}
              height={300}
              data={dataCan}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {elementiCan.map((el) => (
                <Bar dataKey={el.lavorazioni} fill={el.colore} />
              ))}
            </BarChart>
          </div>
        </div>
        <div className="col-md col-12 h-25 rounded mx-2 my-2">
          <div className="col-10 mx-auto h-25 gap-2 d-flex justify-content-center flex-wrap flex-md-nowrap row my-auto">
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Cantieri
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filtroLavorazioniCom}
                onChange={handleChangeFiltroLavorazioniCom}
                input={<OutlinedInput label="Cantieri" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiLavorazioniCom.map((el) => (
                  <MenuItem key={el.cantieri} value={el.cantieri}>
                    <Checkbox
                      checked={filtroLavorazioniCom.indexOf(el.cantieri) > -1}
                    />
                    <ListItemText primary={el.cantieri + el.descrizione} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Lavorazioni
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filtroLavorazioniLav}
                onChange={handleChangeFiltroLavorazioniLav}
                input={<OutlinedInput label="Lavorazioni" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiLavorazioniLav.map((el) => (
                  <MenuItem key={el.lavorazione} value={el.lavorazione}>
                    <Checkbox
                      checked={
                        filtroLavorazioniLav.indexOf(el.lavorazione) > -1
                      }
                    />
                    <ListItemText primary={el.lavorazione} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="card mt-2">
            <span className="mx-auto fs-4">PRODUZIONE</span>
            <BarChart
              className="mx-auto"
              width={screenSize < 768 ? screenSize - 50 : screenSize / 2 - 50}
              height={300}
              data={dataLav}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => value + " €"} />
              <Tooltip />
              <Legend />
              {elementiLavorazioniCom.map((el) => (
                <Bar dataKey={el.cantieri} fill={el.colore} />
              ))}
            </BarChart>
          </div>
        </div>
      </div>
      {/* ATTREZZATURE */}
      <div className="d-flex flex-md-nowrap flex-wrap p-2 rounded mx-md-3 mx-0 bg-light mt-4 ">
        <div className="col-md col-12 h-25 my-2">
          <div className="col-10 mx-auto h-25 gap-2 d-flex justify-content-center flex-wrap flex-md-nowrap row my-auto">
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Lavorazioni
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filtroAttrezzature}
                onChange={handleChangeFiltroAttrezzature}
                input={<OutlinedInput label="Lavorazioni" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiAtt.map((el) => (
                  <MenuItem key={el.lavorazioni} value={el.lavorazioni}>
                    <Checkbox
                      checked={filtroAttrezzature.indexOf(el.lavorazioni) > -1}
                    />
                    <ListItemText primary={el.lavorazioni} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Attrezzature
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filtroAttrezzatureAtt}
                onChange={handleChangeFiltroAttrezzatureAtt}
                input={<OutlinedInput label="Attrezzature" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiAttrezzatureAtt.map((el) => (
                  <MenuItem key={el.attrezzatura} value={el.attrezzatura}>
                    <Checkbox
                      checked={
                        filtroAttrezzatureAtt.indexOf(el.attrezzatura) > -1
                      }
                    />
                    <ListItemText primary={el.attrezzatura + el.descrizione} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="card mt-2">
            <span className="mx-auto fs-4">ATTREZZATURE</span>
            <BarChart
              className="mx-auto"
              width={screenSize - 50}
              height={300}
              data={dataAtt}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {elementiAtt.map((el) => (
                <Bar dataKey={el.lavorazioni} fill={el.colore} />
              ))}
            </BarChart>
          </div>
        </div>
      </div>
      {/* OPERATORI */}
      <div className="d-flex flex-md-nowrap flex-wrap p-2 rounded mx-md-3 mx-0 bg-light mt-4 ">
        <div className="col-md col-12 h-25 my-2">
          <div className="col-10 mx-auto h-25 gap-2 d-flex justify-content-center flex-wrap flex-md-nowrap row my-auto">
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Lavorazioni
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filtroOperatoreLav}
                onChange={handleChangeFiltroOperaotreLav}
                input={<OutlinedInput label="Lavorazioni" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiOperatoriLav.map((el) => (
                  <MenuItem key={el.lavorazioni} value={el.lavorazioni}>
                    <Checkbox
                      checked={filtroOperatoreLav.indexOf(el.lavorazioni) > -1}
                    />
                    <ListItemText primary={el.lavorazioni} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="col-md-6">
              <InputLabel id="demo-multiple-checkbox-label">
                Operatori
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={filtroOperatoreOpe}
                onChange={handleChangeFiltroOperaotreOpe}
                input={<OutlinedInput label="Operatori" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {elementiOperatoriOpe.map((el) => (
                  <MenuItem key={el.operatore} value={el.operatore}>
                    <Checkbox
                      checked={filtroOperatoreOpe.indexOf(el.operatore) > -1}
                    />
                    <ListItemText primary={el.operatore} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="card mt-2">
            <span className="mx-auto fs-4">OPERATORI</span>
            <BarChart
              className="mx-auto"
              width={screenSize - 50}
              height={300}
              data={dataOpe}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {elementiOperatoriLav.map((el) => (
                <Bar dataKey={el.lavorazioni} fill={el.colore} />
              ))}
            </BarChart>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

const without = (object, keys) =>
  keys.reduce((o, k) => {
    const { [k]: _, ...p } = o;
    return p;
  }, object);

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
