import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CButton,
  CCollapse,
  CCol,
  CFormSwitch,
  CFormTextarea,
} from "@coreui/react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import enLocale from "date-fns/locale/en-IE";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from "moment";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Backdrop, CircularProgress } from "@mui/material";

import { saveAs } from "file-saver";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import FotoNoleggi from "./FotoNoleggi";

const DettaglioNoleggi = () => {
  const [cantieri, setCantieri] = useState([]);
  const [valueTop, setValueTop] = useState(1);
  const [noleggio, setNoleggio] = useState({
    id: "",
    matricola: "",
    marca: "",
    modello: "",
    descrizione: "",
    targa: "",
    noleggiatore: "",
    data_inizio: null,
    data_fine: null,
    chassis: "",
    tipologia: "",
    cantiere: null,
    n_matricola: 0,
    note: "",
    scheda_tecnica: "",
    certificato_ce: "",
    carta_circolazione: "",
    assicurazione: "",
    manuale_uso_manutenzione: "",
    catalogo_ricambi: "",
    verifica_funi_catene: "",
    visita_periodica: "",
    scadenza_circolazione: null,
    scadenza_assicurazione: null,
    scadenza_funicatene: null,
    scadenza_periodica: null,
  });
  const [noleggioBackup, setNoleggioBackup] = useState({
    id: "",
    matricola: "",
    marca: "",
    modello: "",
    descrizione: "",
    targa: "",
    noleggiatore: "",
    data_inizio: null,
    data_fine: null,
    chassis: "",
    tipologia: "",
    cantiere: null,
    n_matricola: 0,
    note: "",
    scheda_tecnica: "",
    certificato_ce: "",
    carta_circolazione: "",
    assicurazione: "",
    manuale_uso_manutenzione: "",
    catalogo_ricambi: "",
    verifica_funi_catene: "",
    visita_periodica: "",
    scadenza_circolazione: null,
    scadenza_assicurazione: null,
    scadenza_funicatene: null,
    scadenza_periodica: null,
  });

  const [valueUpload, setValueUpload] = useState("");
  const [colonnaDb, setColonnaDb] = useState("");

  const [circolazione, setCircolazione] = useState(false);
  const [assicurazione, setAssicurazione] = useState(false);
  const [verifica_funi_catene, setFunicat] = useState(false);
  const [periodica, setPeriodica] = useState(false);

  const [storicoNoleggi, setStoricoNoleggi] = useState([]);

  const [visibile, setVisibile] = useState({
    scheda_tecnica: false,
    certificato_ce: false,
    carta_circolazione: false,
    assicurazione: false,
    manuale_uso_manutenzione: false,
    catalogo_ricambi: false,
    verifica_funi_catene: false,
    visita_periodica: false,
  });

  const [nomiDocumenti, setNomiDocumenti] = useState({ from: "", to: "" });
  const [pathDocumenti, setPathDocumenti] = useState([]);
  const [fotoNoleggi, setFotoNoleggi] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [tipologiaMacchina, setTipologiaMacchina] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [openSnack, setOpenSnack] = useState(false);

  const columnsStorico = [
    {
      field: "cantiere",
      headerName: "Cantiere",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tariffa",
      headerName: "Tariffa Mensile",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "data_inizio",
      headerName: "Data Inizio",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.data_inizio
          ? moment(params.row.data_inizio).format("YYYY-MM-DD")
          : null,
    },
    {
      field: "data_fine",
      headerName: "Data Fine",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.data_fine
          ? moment(params.row.data_fine).format("YYYY-MM-DD")
          : null,
    },
    {
      field: "contratto",
      headerName: "Contratto",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.contratto !== null &&
        params.row.contratto !== undefined &&
        params.row.contratto !== "" ? (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() => manageFilesStorico(params.row)}
          ></div>
        ) : (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap m-3">
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const changeDocumentPath = (path) => {
    let newPath = { ...path };

    let arrOfPath = [...pathDocumenti];
    if (arrOfPath.length > 0) {
      let elIndex = arrOfPath.findIndex((e) => e.from === newPath.from);

      if (elIndex !== -1) {
        arrOfPath[elIndex].to = newPath.to;
      } else {
        arrOfPath.push(newPath);
      }
    } else {
      arrOfPath.push(newPath);
    }
    setPathDocumenti(arrOfPath);
  };

  const handleChange = (event) => {
    if (
      event.target.name === "scheda_tecnica" ||
      event.target.name === "carta_circolazione" ||
      event.target.name === "assicurazione" ||
      event.target.name === "manuale_uso_manutenzione" ||
      event.target.name === "catalogo_ricambi" ||
      event.target.name === "verifica_funi_catene" ||
      event.target.name === "visita_periodica" ||
      event.target.name === "certificato_ce"
    ) {
      let valDocumenti = { ...nomiDocumenti };
      valDocumenti.from = noleggioBackup[event.target.name];
      valDocumenti.to = event.target.value;
      changeDocumentPath(valDocumenti);
    }
    let nol = { ...noleggio };
    nol[event.target.name] = event.target.value;
    setNoleggio(nol);
  };
  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare il Noleggio?"
    );
    if (isExecuted) {
      await api.delete("/api/noleggi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: noleggio.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Noleggio eliminato con successo",
      });
      setOpenSnack(true);
      //alert("Noleggio eliminato con successo");
      //navigate(-1);
    }
  };
  const handleChangeValueTop = (event, newValue) => {
    setValueTop(newValue);
  };
  const handleConfirmButton = async () => {
    let nol = { ...noleggio };
    nol["data_inizio"] = noleggio.data_inizio
      ? moment(noleggio.data_inizio).format("YYYY-MM-DD")
      : null;
    nol["data_fine"] = noleggio.data_fine
      ? moment(noleggio.data_fine).format("YYYY-MM-DD")
      : null;
    nol["scadenza_circolazione"] = noleggio.scadenza_circolazione
      ? moment(noleggio.scadenza_circolazione).format("YYYY-MM-DD")
      : null;
    nol["scadenza_assicurazione"] = noleggio.scadenza_assicurazione
      ? moment(noleggio.scadenza_assicurazione).format("YYYY-MM-DD")
      : null;
    nol["scadenza_funicatene"] = noleggio.scadenza_funicatene
      ? moment(noleggio.scadenza_funicatene).format("YYYY-MM-DD")
      : null;
    nol["scadenza_periodica"] = noleggio.scadenza_periodica
      ? moment(noleggio.scadenza_periodica).format("YYYY-MM-DD")
      : null;

    if (location.state.operazione === "Nuovo") {
      let s = "N";
      let n = 0;
      api
        .get("api/noleggi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then(async (res) => {
          let noleggi = res.data.data;
          //prendo il valore maggiore e aggiungo 1
          n = Math.max(...noleggi.map((el) => el.n_matricola)) + 1;
          n = n.toString();
          for (let i = 0; i < 5 - n.length; i++) {
            s = s + "0";
          }
          s = s + n;

          //let nol = { ...noleggio };
          nol.matricola = s;
          nol.n_matricola = parseInt(n);

          const response = await api.post(
            "api/noleggi",
            { auth: "a5d42c3ed7977afa2d8e922569e7940b", noleggio: nol },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          location.state.operazione = "Modifica";
          nol["id"] = response.data.message.insertId; //ottengo l'id dell'elemento appena inserito
          setSnack({
            severity: "success",
            messaggio: "Inserimento effettuato",
          });
          setOpenSnack(true);
        });
    } else {
      if (pathDocumenti.length > 0) {
        try {
          //let nol = { ...noleggio };

          let all = await api.post(
            "api/allegati",
            { rename: true, path: pathDocumenti },
            {
              responseType: "blob",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          await api.put(
            "api/noleggi",
            { auth: "a5d42c3ed7977afa2d8e922569e7940b", noleggio: nol },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );

          setSnack({ severity: "success", messaggio: "Modifica effettuata" });
          setOpenSnack(true);
          setTimeout(() => {
            navigate("/home/noleggi", {
              state: {
                search: "",
              },
            });
          }, 500);
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Errore durante la modifica",
          });
          setOpenSnack(true);
        }
      } else {
        /* let nol = { ...noleggio };
        nol["data_inizio"] = moment(noleggio.data_inizio).format("YYYY-MM-DD");
        nol["data_fine"] = moment(noleggio.data_fine).format("YYYY-MM-DD"); */
        await api.put(
          "api/noleggi",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", noleggio: nol },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({ severity: "success", messaggio: "Modifica effettuata" });
        setOpenSnack(true);
        setTimeout(() => {
          navigate("/home/noleggi", {
            state: {
              search: "",
            },
          });
        }, 500);
      }
    }
  };

  const manageFilesStorico = async (storico) => {
    let perc = storico.contratto;
    let fileName =
      storico.matricola +
      "_contratto_" +
      moment(storico.data_inizio).format("YYYY-MM-DD") +
      ".pdf";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        //window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
        var oReq = new XMLHttpRequest();
        // The Endpoint of your server
        var URLToPDF = "https://portale.fondamentasrl.it/file?file=" + fileName;
        // Configure XMLHttpRequest
        oReq.open("GET", URLToPDF, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
          // Once the file is downloaded, open a new window with the PDF
          // Remember to allow the POP-UPS in your browser
          var filee = new Blob([oReq.response], {
            type: "application/pdf",
          });

          // Generate file download directly in the browser !
          saveAs(filee, fileName);
        };

        oReq.send();
      });
  };

  const zipAllFiles = async () => {
    api
      .post(
        "api/allegati",
        { macchina: noleggio.matricola },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", noleggio.matricola + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  };
  const manageFiles = async (name) => {
    let perc =
      noleggio.matricola + "/" + noleggio.matricola + "_" + name + ".pdf";
    let fileName = noleggio.matricola + "_" + name + ".pdf";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        //window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
        var oReq = new XMLHttpRequest();
        // The Endpoint of your server
        var URLToPDF = "https://portale.fondamentasrl.it/file?file=" + fileName;
        // Configure XMLHttpRequest
        oReq.open("GET", URLToPDF, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
          // Once the file is downloaded, open a new window with the PDF
          // Remember to allow the POP-UPS in your browser
          var filee = new Blob([oReq.response], {
            type: "application/pdf",
          });

          // Generate file download directly in the browser !
          saveAs(filee, fileName);
        };

        oReq.send();
      });
  };
  const viewFiles = async (name) => {
    let perc =
      noleggio.matricola + "/" + noleggio.matricola + "_" + name + ".pdf";
    let fileName = noleggio.matricola + "_" + name + ".pdf";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
      });
  };
  const deleteFiles = async (name, colonnaDb) => {
    let perc =
      noleggio.matricola + "/" + noleggio.matricola + "_" + name + ".pdf";
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare il documento " +
        noleggio.matricola +
        "_" +
        name +
        ".pdf" +
        "?"
    );
    if (isExecuted) {
      try {
        await api.post(
          "api/ftp",
          { percorso: perc, operazione: "elimina" },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        let nol = { ...noleggio };
        nol["data_inizio"] = noleggio.data_inizio
          ? moment(noleggio.data_inizio).format("YYYY-MM-DD")
          : null;
        nol["data_fine"] = noleggio.data_fine
          ? moment(noleggio.data_fine).format("YYYY-MM-DD")
          : null;
        nol["scadenza_circolazione"] = noleggio.scadenza_circolazione
          ? moment(noleggio.scadenza_circolazione).format("YYYY-MM-DD")
          : null;
        nol["scadenza_assicurazione"] = noleggio.scadenza_assicurazione
          ? moment(noleggio.scadenza_assicurazione).format("YYYY-MM-DD")
          : null;
        nol["scadenza_funicatene"] = noleggio.scadenza_funicatene
          ? moment(noleggio.scadenza_funicatene).format("YYYY-MM-DD")
          : null;
        nol["scadenza_periodica"] = noleggio.scadenza_periodica
          ? moment(noleggio.scadenza_periodica).format("YYYY-MM-DD")
          : null;
        nol[colonnaDb] = "";

        switch (colonnaDb) {
          case "carta_circolazione":
            nol.scadenza_circolazione = null;
            setCircolazione(false);
            break;
          case "assicurazione":
            nol.scadenza_assicurazione = null;
            setAssicurazione(false);
            break;
          case "verifica_funi_catene":
            nol.scadenza_funicatene = null;
            setFunicat(false);
            break;
          case "visita_periodica":
            nol.scadenza_periodica = null;
            setPeriodica(false);
            break;

          default:
            break;
        }

        try {
          await api.put(
            "api/noleggi",
            { auth: "a5d42c3ed7977afa2d8e922569e7940b", noleggio: nol },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSnack({
            severity: "success",
            messaggio: "File Eliminato con successo",
          });
          setOpenSnack(true);
          await reloadData();
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Eliminazione persorso file fallito",
          });
          setOpenSnack(true);
        }
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Eliminazione fallita, controllare presenza del file",
        });
        setOpenSnack(true);
      }
    }
  };
  const displayNone = () => {
    if (
      JSON.parse(localStorage.getItem("permission")).noleggi_int.allowView === 0
    ) {
      return "none";
    } else {
      return "block";
    }
  };

  const loadFile = async (filex, matricola, nome, nomeColonnaDb) => {
    setOpenBackdrop(true);

    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", matricola);
    //formData.append("data", data);
    formData.append("nomeManutenzione", matricola + "_" + nome + ".pdf");
    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        setOpenBackdrop(false);
        setSnack({
          severity: "success",
          messaggio: "Documento caricato con successo!",
        });
        setOpenSnack(true);
        let nol = { ...noleggio };
        nol[`${nomeColonnaDb}`] =
          "/" + matricola + "/" + matricola + "_" + nome + ".pdf";
        nol["data_inizio"] = noleggio.data_inizio
          ? moment(noleggio.data_inizio).format("YYYY-MM-DD")
          : null;
        nol["data_fine"] = noleggio.data_fine
          ? moment(noleggio.data_fine).format("YYYY-MM-DD")
          : null;

        await api.put(
          "api/noleggi",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", noleggio: nol },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        await reloadData();
      });
  };

  const reloadData = async () => {
    const res = await api.get("api/noleggi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const noleggiFiltered = res.data.data.filter(
      (el) => el.matricola === noleggio.matricola
    );
    setNoleggio(noleggiFiltered[0]);
    setNoleggioBackup(noleggiFiltered[0]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get("api/tipologia", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const tipologia = res.data.data.map((tipo) => tipo.tipologia);
      setTipologiaMacchina(tipologia);

      if (location.state.operazione === "Nuovo") {
        let nol = { ...noleggio };
        setNoleggio(nol);
        setNoleggioBackup(nol);
        const resNol = await api.get("api/noleggi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
      } else {
        let nol = location.state.noleggi;
        if (
          nol.data_inizio === null ||
          nol.data_inizio === "1899-11-29T23:00:00.000Z" ||
          nol.data_inizio === "0000-00-00"
        ) {
          nol.data_inizio = null;
        }
        if (
          nol.data_fine === null ||
          nol.data_fine === "1899-11-29T23:00:00.000Z" ||
          nol.data_fine === "0000-00-00"
        ) {
          nol.data_fine = null;
        }
        setNoleggio(nol);
        setNoleggioBackup(nol);

        nol.scadenza_circolazione !== null
          ? setCircolazione(true)
          : setCircolazione(false);
        nol.scadenza_assicurazione !== null
          ? setAssicurazione(true)
          : setAssicurazione(false);
        nol.scadenza_funicatene !== null ? setFunicat(true) : setFunicat(false);
        nol.scadenza_periodica !== null
          ? setPeriodica(true)
          : setPeriodica(false);
        const resStoricoNol = await api.get("api/storicoNoleggi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: nol.id },
        });
        setStoricoNoleggi(resStoricoNol.data.data);

        api
          .get("api/fotoNoleggi", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { id: nol.id },
          })
          .then((res) => {
            setFotoNoleggi(res.data.data);
          });
      }
      const resCant = await api.get("api/cantieri", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const cantieri = resCant.data.data.map((cantiere) => cantiere.commessa);
      setCantieri(cantieri);
    };
    fetchData();
  }, []);

  const reloadFoto = async () => {
    await api
      .get("api/fotoNoleggi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: noleggio.id },
      })
      .then((res) => {
        setFotoNoleggi(res.data.data);
      });
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Noleggi</span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>
          <TabContext value={valueTop}>
            <div className="card-header bg-light d-flex">
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChangeValueTop}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Tab
                  icon={
                    <>
                      <i className="bi bi-clipboard-data fs-4"></i>
                      <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                        DETTAGLI
                      </span>
                    </>
                  }
                  className="row justify-content-center"
                  value={1}
                ></Tab>
                <Tab
                  icon={
                    <>
                      <i className="bi bi-file-earmark-pdf fs-4"></i>
                      <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                        DOCUMENTI
                      </span>
                    </>
                  }
                  className="row justify-content-center"
                  value={2}
                ></Tab>
                <Tab
                  icon={
                    <>
                      <i className="bi bi-file-image fs-4"></i>
                      <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                        FOTO
                      </span>
                    </>
                  }
                  className="row justify-content-center"
                  value={3}
                ></Tab>
              </TabList>
            </div>
            {/* BODY */}
            <div className="card-body">
              <TabPanel value={1} className="row flex-wrap flex-md-nowrap">
                <div className="col">
                  <div className="d-flex justify-content-center col-md-12 flex-md-nowrap flex-wrap">
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="DescrizioneInput"
                        name="matricola"
                        value={noleggio.matricola}
                        onChange={handleChange}
                        disabled={true}
                      />
                      <CFormLabel>Matricola</CFormLabel>
                    </CFormFloating>
                    {/* <CFormFloating
                      className={`mb-3 col-12 ${
                        location.state.operazione === "Nuovo"
                          ? "col-md-6"
                          : "col-md-3"
                      }`}
                    >
                      <Autocomplete
                        value={noleggio.cantiere}
                        onChange={(event, newVal) => {
                          let nol = { ...noleggio };
                          nol["cantiere"] = newVal;
                          setNoleggio(nol);
                        }}
                        getRowId={(row) => row.id}
                        options={cantieri}
                        getOptionLabel={(option) => `${option}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={validation.cantiere}
                            helperText={
                              validation.cantiere ? "Cantiere obbligatorio" : ""
                            }
                            label="Cantiere*"
                          />
                        )}
                      />
                    </CFormFloating> */}
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="TargaInput"
                        name="noleggiatore"
                        value={noleggio.noleggiatore}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Noleggiatore</CFormLabel>
                    </CFormFloating>
                  </div>

                  {/* <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
                    <CFormFloating className="col-md-6 col-12 mb-3">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={enLocale}
                      >
                        <DatePicker
                          label="Data inizio noleggio"
                          value={
                            noleggio.data_inizio === null
                              ? null
                              : noleggio.data_inizio
                          }
                          renderInput={(params) => (
                            <TextField {...params} className="col-12" />
                          )}
                          onChange={(newValue) => {
                            let nol = { ...noleggio };
                            nol["data_inizio"] = moment(newValue)
                              .local()
                              .format("YYYY-MM-DD");
                            setNoleggio(nol);
                          }}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </LocalizationProvider>
                    </CFormFloating>
                    <CFormFloating className="col-md-6 col-12 mb-3">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={enLocale}
                      >
                        <DatePicker
                          label="Data fine noleggio"
                          value={
                            noleggio.data_fine === null
                              ? null
                              : noleggio.data_fine
                          }
                          renderInput={(params) => (
                            <TextField {...params} className="col-12" />
                          )}
                          onChange={(newValue) => {
                            let nol = { ...noleggio };
                            nol["data_fine"] = moment(newValue)
                              .local()
                              .format("YYYY-MM-DD");
                            setNoleggio(nol);
                          }}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </LocalizationProvider>
                    </CFormFloating>
                  </div> */}

                  <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <Autocomplete
                        value={noleggio.tipologia}
                        onChange={(event, newValue) => {
                          let tipo = { ...noleggio };
                          tipo["tipologia"] = newValue;
                          setNoleggio(tipo);
                        }}
                        id="controllable-states-demo"
                        options={tipologiaMacchina}
                        getOptionLabel={(option) => `${option}`}
                        renderInput={(params) => (
                          <TextField {...params} label="Tipologia" />
                        )}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                        }
                      />
                    </CFormFloating>
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="TargaInput"
                        name="modello"
                        value={noleggio.modello}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Modello</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="TargaInput"
                        name="descrizione"
                        value={noleggio.descrizione}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Descrizione</CFormLabel>
                    </CFormFloating>

                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="ChassisInput"
                        name="marca"
                        value={noleggio.marca}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Marca</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="TargaInput"
                        name="chassis"
                        value={noleggio.chassis}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Chassis</CFormLabel>
                    </CFormFloating>
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="text"
                        id="TargaInput"
                        name="targa"
                        value={noleggio.targa}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Targa</CFormLabel>
                    </CFormFloating>
                  </div>
                  {/* <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="number"
                        id="TargaInput"
                        name="contratto"
                        value={noleggio.contratto}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Contratto</CFormLabel>
                    </CFormFloating>
                    <CFormFloating className="mb-3 col-md-6 col-12">
                      <CFormInput
                        type="number"
                        id="TargaInput"
                        name="tariffa"
                        value={noleggio.tariffa}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Tariffa</CFormLabel>
                    </CFormFloating>
                  </div> */}
                  <CFormFloating className="mb-3 col-12">
                    <CFormTextarea
                      type="text"
                      id="TargaInput"
                      name="note"
                      style={{ height: 80 }}
                      value={noleggio.note}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .noleggi_int.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Note</CFormLabel>
                  </CFormFloating>
                </div>
              </TabPanel>
              <TabPanel value={2} className="p-0">
                <div className="d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                  <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.scheda_tecnica
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Scheda Tecnica
                    </CFormLabel>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => manageFiles("scheda_tecnica")}
                      style={{
                        display: noleggio.scheda_tecnica ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("scheda_tecnica")}
                      style={{
                        display: noleggio.scheda_tecnica ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) =>
                          deleteFiles("scheda_tecnica", "scheda_tecnica")
                        }
                        style={{
                          display: noleggio.scheda_tecnica ? "block" : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      className={`btn btn-secondary rounded ${
                        noleggio.scheda_tecnica ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          scheda_tecnica: !visibile.scheda_tecnica,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("scheda_tecnica");
                        setColonnaDb("scheda_tecnica");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.scheda_tecnica
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    <CCollapse
                      visible={visibile.scheda_tecnica}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="scheda_tecnica"
                          name="scheda_tecnica"
                          defaultValue={noleggio.scheda_tecnica}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>

                  <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.certificato_ce
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Certificato CE
                    </CFormLabel>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("ce")}
                      style={{
                        display: noleggio.certificato_ce ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("ce")}
                      style={{
                        display: noleggio.certificato_ce ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) => deleteFiles("ce", "certificato_ce")}
                        style={{
                          display: noleggio.certificato_ce ? "block" : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      type="button"
                      className={`btn btn-secondary rounded ${
                        noleggio.certificato_ce ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          certificato_ce: !visibile.certificato_ce,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    {/* Import */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("ce");
                        setColonnaDb("certificato_ce");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.certificato_ce
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    <CCollapse
                      visible={visibile.certificato_ce}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="certificato_ce"
                          name="certificato_ce"
                          defaultValue={noleggio.certificato_ce}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>
                </div>
                <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                  {/* Metà sinistra */}
                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.carta_circolazione
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Carta di Circolazione
                    </CFormLabel>
                    {/* Download */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("cdc")}
                      style={{
                        display: noleggio.carta_circolazione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("cdc")}
                      style={{
                        display: noleggio.carta_circolazione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) =>
                          deleteFiles("cdc", "carta_circolazione")
                        }
                        style={{
                          display: noleggio.carta_circolazione
                            ? "block"
                            : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      className={`btn btn-secondary rounded ${
                        noleggio.carta_circolazione ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          carta_circolazione: !visibile.carta_circolazione,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("cdc");
                        setColonnaDb("carta_circolazione");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.carta_circolazione
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    <CCollapse
                      visible={visibile.carta_circolazione}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="CartaDiCircolazione"
                          className="col"
                          name="carta_circolazione"
                          defaultValue={noleggio.carta_circolazione}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>

                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                    {/* On/Off */}
                    <CFormFloating className="col-4 col-form-label">
                      <CFormSwitch
                        label="Scadenza"
                        checked={circolazione}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                        }
                        id="formSwitch1"
                        style={{
                          backgroundColor:
                            moment(new Date()).local().format("YYYY-MM-DD") >=
                            moment(noleggio.scadenza_circolazione)
                              .local()
                              .format("YYYY-MM-DD")
                              ? "red"
                              : "",
                          borderColor: "grey",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCircolazione(true);
                          } else {
                            let nol = { ...noleggio };
                            nol.scadenza_circolazione = null;
                            setNoleggio(nol);
                            setCircolazione(false);
                          }
                        }}
                      />
                    </CFormFloating>
                    <CCollapse className="col-6" visible={circolazione}>
                      <CCol>
                        <CFormFloating>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="Scadenza"
                              value={noleggio.scadenza_circolazione}
                              renderInput={(params) => (
                                <TextField {...params} className="p-0" />
                              )}
                              onChange={(newValue) => {
                                let can = { ...noleggio };
                                can["scadenza_circolazione"] = moment(newValue);
                                setNoleggio(can);
                                setCircolazione(true);
                              }}
                              disabled={
                                JSON.parse(localStorage.getItem("permission"))
                                  .noleggi_int.allowEdit === 0
                                  ? true
                                  : false
                              }
                            />
                          </LocalizationProvider>
                        </CFormFloating>
                      </CCol>
                    </CCollapse>
                  </div>
                </div>
                <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                  {/* Metà sinistra */}
                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.assicurazione
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Assicurazione
                    </CFormLabel>
                    {/* Download */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("ass")}
                      style={{
                        display: noleggio.assicurazione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("ass")}
                      style={{
                        display: noleggio.assicurazione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) => deleteFiles("ass", "assicurazione")}
                        style={{
                          display: noleggio.assicurazione ? "block" : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      className={`btn btn-secondary rounded ${
                        noleggio.assicurazione ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          assicurazione: !visibile.assicurazione,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("ass");
                        setColonnaDb("assicurazione");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.assicurazione
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    <CCollapse
                      visible={visibile.assicurazione}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="CartaDiCircolazione"
                          className="col"
                          name="assicurazione"
                          defaultValue={noleggio.assicurazione}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>

                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                    {/* On/Off */}
                    <CFormFloating className="col-4 col-form-label">
                      <CFormSwitch
                        label="Scadenza"
                        checked={assicurazione}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                        }
                        id="formSwitch2"
                        style={{
                          backgroundColor:
                            moment(new Date()).local().format("YYYY-MM-DD") >=
                            moment(noleggio.scadenza_assicurazione)
                              .local()
                              .format("YYYY-MM-DD")
                              ? "red"
                              : "",
                          borderColor: "grey",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAssicurazione(true);
                          } else {
                            let nol = { ...noleggio };
                            nol.scadenza_assicurazione = null;
                            setNoleggio(nol);
                            setAssicurazione(false);
                          }
                        }}
                      />
                    </CFormFloating>
                    {/* collapse Scadenza */}
                    <CCollapse className="col-6" visible={assicurazione}>
                      <CCol>
                        <CFormFloating>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="Scadenza"
                              value={noleggio.scadenza_assicurazione}
                              renderInput={(params) => (
                                <TextField {...params} className="p-0" />
                              )}
                              onChange={(newValue) => {
                                let can = { ...noleggio };
                                can["scadenza_assicurazione"] =
                                  moment(newValue);
                                setNoleggio(can);
                                setCircolazione(true);
                              }}
                              disabled={
                                JSON.parse(localStorage.getItem("permission"))
                                  .noleggi_int.allowEdit === 0
                                  ? true
                                  : false
                              }
                            />
                          </LocalizationProvider>
                        </CFormFloating>
                      </CCol>
                    </CCollapse>
                  </div>
                </div>
                <div className="d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                  <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.manuale_uso_manutenzione
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Manuale Uso e Manutenzione
                    </CFormLabel>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("uso_manutenzione")}
                      style={{
                        display: noleggio.manuale_uso_manutenzione
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("uso_manutenzione")}
                      style={{
                        display: noleggio.manuale_uso_manutenzione
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) =>
                          deleteFiles(
                            "uso_manutenzione",
                            "manuale_uso_manutenzione"
                          )
                        }
                        style={{
                          display: noleggio.manuale_uso_manutenzione
                            ? "block"
                            : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      className={`btn btn-secondary rounded ${
                        noleggio.manuale_uso_manutenzione ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          manuale_uso_manutenzione:
                            !visibile.manuale_uso_manutenzione,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    {/* Import */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("uso_manutenzione");
                        setColonnaDb("manuale_uso_manutenzione");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.manuale_uso_manutenzione
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    <CCollapse
                      visible={visibile.manuale_uso_manutenzione}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="manuale_uso_manutenzione"
                          name="manuale_uso_manutenzione"
                          defaultValue={noleggio.manuale_uso_manutenzione}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>

                  <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.catalogo_ricambi
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Catalogo Ricambi
                    </CFormLabel>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("catalogo_ricambi")}
                      style={{
                        display: noleggio.catalogo_ricambi ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("catalogo_ricambi")}
                      style={{
                        display: noleggio.catalogo_ricambi ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) =>
                          deleteFiles("catalogo_ricambi", "catalogo_ricambi")
                        }
                        style={{
                          display: noleggio.catalogo_ricambi ? "block" : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      type="button"
                      className={`btn btn-secondary rounded ${
                        noleggio.catalogo_ricambi ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          catalogo_ricambi: !visibile.catalogo_ricambi,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("catalogo_ricambi");
                        setColonnaDb("catalogo_ricambi");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.catalogo_ricambi
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    <CCollapse
                      visible={visibile.catalogo_ricambi}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="catalogo_ricambi"
                          name="catalogo_ricambi"
                          defaultValue={noleggio.catalogo_ricambi}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>
                </div>
                <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                  {/* Metà sinistra */}
                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.verifica_funi_catene
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Verifica funi e catene
                    </CFormLabel>
                    {/* Download */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("verificafuni")}
                      style={{
                        display: noleggio.verifica_funi_catene
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("verificafuni")}
                      style={{
                        display: noleggio.verifica_funi_catene
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) =>
                          deleteFiles("verificafuni", "verifica_funi_catene")
                        }
                        style={{
                          display: noleggio.verifica_funi_catene
                            ? "block"
                            : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      className={`btn btn-secondary rounded ${
                        noleggio.verifica_funi_catene ? "d-block" : "d-none"
                      }`}
                      style={{ display: displayNone() }}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          verifica_funi_catene: !visibile.verifica_funi_catene,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("verificafuni");
                        setColonnaDb("verifica_funi_catene");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.verifica_funi_catene
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    {/* collapse documento */}
                    <CCollapse
                      visible={visibile.verifica_funi_catene}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="CartaDiCircolazione"
                          className="col"
                          name="verifica_funi_catene"
                          defaultValue={noleggio.verifica_funi_catene}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>

                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                    {/* On/Off */}
                    <CFormFloating className="col-4 col-form-label">
                      <CFormSwitch
                        label="Scadenza"
                        checked={verifica_funi_catene}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                        }
                        id="formSwitch3"
                        style={{
                          backgroundColor:
                            moment(new Date()).local().format("YYYY-MM-DD") >=
                            moment(noleggio.scadenza_funicatene)
                              .local()
                              .format("YYYY-MM-DD")
                              ? "red"
                              : "",
                          borderColor: "grey",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFunicat(true);
                          } else {
                            let nol = { ...noleggio };
                            nol.scadenza_funicatene = null;
                            setNoleggio(nol);
                            setFunicat(false);
                          }
                        }}
                      />
                    </CFormFloating>
                    {/* collapse Scadenza */}
                    <CCollapse className="col-6" visible={verifica_funi_catene}>
                      <CCol>
                        <CFormFloating>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="Scadenza"
                              value={noleggio.scadenza_funicatene}
                              renderInput={(params) => (
                                <TextField {...params} className="p-0" />
                              )}
                              onChange={(newValue) => {
                                let can = { ...noleggio };
                                can["scadenza_funicatene"] = moment(newValue);
                                setNoleggio(can);
                                setCircolazione(true);
                              }}
                              disabled={
                                JSON.parse(localStorage.getItem("permission"))
                                  .noleggi_int.allowEdit === 0
                                  ? true
                                  : false
                              }
                            />
                          </LocalizationProvider>
                        </CFormFloating>
                      </CCol>
                    </CCollapse>
                  </div>
                </div>
                <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                  {/* Metà sinistra */}
                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                    <CFormLabel
                      className={`col-form-label text-start ${
                        visibile.visita_periodica
                          ? "col-md-2 col-8"
                          : "col-md-5 col-8"
                      }`}
                    >
                      Verifica periodica
                    </CFormLabel>
                    {/* Download */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={() => manageFiles("verificaperiodica")}
                      style={{
                        display: noleggio.visita_periodica ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => viewFiles("verificaperiodica")}
                      style={{
                        display: noleggio.visita_periodica ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </CButton>
                    {JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowDelete !== 0 ? (
                      <CButton
                        className="btn btn-secondary rounded"
                        onClick={(e) =>
                          deleteFiles("verificaperiodica", "visita_periodica")
                        }
                        style={{
                          display: noleggio.visita_periodica ? "block" : "none",
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </CButton>
                    ) : (
                      <></>
                    )}

                    <CButton
                      className={`btn btn-secondary rounded ${
                        noleggio.visita_periodica ? "d-block" : "d-none"
                      }`}
                      onClick={() =>
                        setVisibile({
                          ...visibile,
                          visita_periodica: !visibile.visita_periodica,
                        })
                      }
                    >
                      <i className="bi bi-pen"></i>
                    </CButton>
                    {/* Import */}
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => {
                        setValueUpload("verificaperiodica");
                        setColonnaDb("visita_periodica");
                        document.getElementById("formFile").click();
                      }}
                      style={{
                        display:
                          location.state.operazione === "Nuovo"
                            ? "none"
                            : JSON.parse(localStorage.getItem("permission"))
                                .noleggi_int.allowEdit === 0
                            ? "none"
                            : !noleggio.visita_periodica
                            ? "block"
                            : "none",
                      }}
                    >
                      <i className="bi bi-upload"></i>
                    </CButton>
                    {/* collapse documento */}
                    <CCollapse
                      visible={visibile.visita_periodica}
                      className="col-md col-12"
                    >
                      <CCol>
                        <CFormInput
                          type="text"
                          id="CartaDiCircolazione"
                          className="col"
                          name="visita_periodica"
                          defaultValue={noleggio.visita_periodica}
                          onChange={handleChange}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .noleggi_int.allowEdit === 0
                          }
                        />
                      </CCol>
                    </CCollapse>
                  </div>

                  <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                    {/* On/Off */}
                    <CFormFloating className="col-4 col-form-label">
                      <CFormSwitch
                        label="Scadenza"
                        checked={periodica}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .noleggi_int.allowEdit === 0
                        }
                        id="formSwitch4"
                        style={{
                          backgroundColor:
                            moment(new Date()).local().format("YYYY-MM-DD") >=
                            moment(noleggio.scadenza_periodica)
                              .local()
                              .format("YYYY-MM-DD")
                              ? "red"
                              : "",
                          borderColor: "grey",
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setPeriodica(true);
                          } else {
                            let nol = { ...noleggio };
                            nol.scadenza_periodica = null;
                            setNoleggio(nol);
                            setPeriodica(false);
                          }
                        }}
                      />
                    </CFormFloating>
                    {/* collapse Scadenza */}
                    <CCollapse className="col-6" visible={periodica}>
                      <CCol>
                        <CFormFloating>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enLocale}
                          >
                            <DatePicker
                              label="Scadenza"
                              value={noleggio.scadenza_periodica}
                              renderInput={(params) => (
                                <TextField {...params} className="p-0" />
                              )}
                              onChange={(newValue) => {
                                let can = { ...noleggio };
                                can["scadenza_periodica"] = moment(newValue);
                                setNoleggio(can);
                                setPeriodica(true);
                              }}
                              disabled={
                                JSON.parse(localStorage.getItem("permission"))
                                  .noleggi_int.allowEdit === 0
                                  ? true
                                  : false
                              }
                            />
                          </LocalizationProvider>
                        </CFormFloating>
                      </CCol>
                    </CCollapse>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={3} className="p-0">
                <FotoNoleggi
                  noleggioSel={noleggio}
                  fotoNoleggi={fotoNoleggi}
                  reload={reloadFoto}
                />
              </TabPanel>
            </div>
          </TabContext>
          {/* FOOTER */}

          <div className="d-none mb-3">
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={(e) => {
                loadFile(
                  e.target.files[0],
                  noleggio.matricola,
                  //moment(noleggio.data_inizio).format("YYMMDD"),
                  valueUpload,
                  colonnaDb
                );
              }}
            />
          </div>
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
            <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap">
              <ReusableButton
                text="STAMPA"
                icon="printer"
                size="xxs"
                onClick={() => {
                  navigate(`/home/macchinari/stampa/${noleggio.id}`, {
                    state: {
                      noleggio: noleggio,
                      stampa: "NoleggioDettaglio",
                    },
                  });
                }}
              />
              <ReusableButton
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                text="ESPORTA"
                icon="cloud-download"
                size="xxs"
                onClick={() => zipAllFiles()}
              />
            </div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                className={
                  JSON.parse(localStorage.getItem("permission")).noleggi_int
                    .allowDelete === 0
                    ? "d-none"
                    : "d-flex bg-red"
                }
                icon="trash"
                size="xs"
                onClick={() => handleDeleteButton()}
              />
              <ReusableButton
                text="SALVA"
                className={
                  JSON.parse(localStorage.getItem("permission")).noleggi_int
                    .allowEdit === 0
                    ? "d-none"
                    : "d-flex"
                }
                icon="check"
                size="xs"
                onClick={() => handleConfirmButton()}
              />
            </div>
          </div>
        </div>

        {/* ASSOCIATI */}
        <div className="card mt-4 mx-0 bg-white rounded border-classic">
          <div className="card-header fs-4 col d-flex justify-content-center">
            Storico Noleggi
          </div>
          <div className="card-body d-flex justify-content-start m-2">
            {JSON.parse(localStorage.getItem("permission")).noleggi_int
              .allowEdit !== 0 ? (
              <>
                <ReusableButton
                  text="AGGIUNGI"
                  icon="plus-circle"
                  size="xxs"
                  className="mt-md-0 mt-2"
                  onClick={() =>
                    navigate(`/home/noleggi/dettaglio-noleggi/:id`, {
                      state: {
                        noleggio: noleggio,
                        operazione: "Nuovo",
                      },
                    })
                  }
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div style={{ height: 400, width: "100%" }} id="accisaCorrelata">
            <DataGridPro
              components={{ Toolbar: CustomToolbar }}
              rows={storicoNoleggi}
              columns={columnsStorico}
              pageSize={5}
              checkboxSelection={false}
              disableSelectionOnClick
              onRowClick={(paramsAccise) => {
                const noleggio = storicoNoleggi.filter(
                  (nol) => nol.id === paramsAccise.row.id
                );
                navigate(`/home/noleggi/dettaglio-noleggi/${noleggio[0].id}`, {
                  state: { noleggio: noleggio[0], operazione: "Modifica" },
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DettaglioNoleggi;
