import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { CImage, CButton, CCard, CCardHeader } from "@coreui/react";
import QRCode from "qrcode";
import moment from "moment";

import logo from "../img/logo.png";

const PrintMacchinario = () => {
  const [macchinario, setMacchinario] = useState([]);
  const [accisa, setAccisa] = useState([]);
  const [manutenzione, setManutenzione] = useState([]);
  const [cantiere, setCantiere] = useState([]);
  const [lavorazione, setLavorazione] = useState([]);
  const [noleggio, setNoleggio] = useState([]);
  const [produzione, setProduzione] = useState([]);
  const [qrcode, setQrcode] = useState("");
  const [ready, setReady] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      if (location.state.stampa === "MacchinarioDettaglio") {
        let datiMacchinario = location.state.macchinario;

        setMacchinario(datiMacchinario);
        let url = `https://portale.fondamentasrl.it/home/macchinari/qrcode/${location.state.macchinario.matricola}`;
        QRCode.toDataURL(url).then((data) => setQrcode(data));
        setReady(true);
      } else if (location.state.stampa === "AccisaDettaglio") {
        let datiMacchinario = location.state.accisa;

        setAccisa(datiMacchinario);
        setReady(true);
      } else if (location.state.stampa === "ManutenzioneDettaglio") {
        let datiMacchinario = location.state.manutenzione;
        let manutenzione = location.state.manutenzione.manutenzione;
        let manut = "";
        for (let i = 0; i < manutenzione.length; i++) {
          manut += manutenzione[i];

          if (i !== manutenzione.length - 1) {
            manut += ", ";
          }
          datiMacchinario.manutenzione = manut;
        }

        setManutenzione(datiMacchinario);
        setReady(true);
      } else if (location.state.stampa === "CantiereDettaglio") {
        let datiMacchinario = location.state.cantiere;

        setCantiere(datiMacchinario);
        setReady(true);
      } else if (location.state.stampa === "LavorazioneDettaglio") {
        let datiMacchinario = location.state.lavorazione;

        setLavorazione(datiMacchinario);
        setReady(true);
      } else if (location.state.stampa === "NoleggioDettaglio") {
        let datiMacchinario = location.state.noleggio;

        setNoleggio(datiMacchinario);
        setReady(true);
      } else if (location.state.stampa === "ProduzioneDettaglio") {
        let datiMacchinario = location.state.produzione;

        setProduzione(datiMacchinario);
        setReady(true);
      }
    };
    fetchData();
  }, []);

  const printPage = () => {
    window.print();
  };

  const printData = () => {
    switch (location.state.stampa) {
      case "MacchinarioDettaglio":
        return (
          <>
            <div id="card print-container">
              <CCardHeader className="py-3">
                <div className="row d-flex align-content-center">
                  <h6 className="col-4 align-self-center m-0">
                    {macchinario.matricola}
                  </h6>
                  <div
                    className="d-flex  justify-content-end col-8 d-print-none"
                    id="buttons"
                  >
                    {/* <PrintComponent/> */}
                    <CButton
                      className="hide-print align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                      onClick={() => printPage()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-printer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                      </svg>
                      <span className="mx-2 fs-6">Print</span>
                    </CButton>
                    <CButton
                      className="hide-print align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x-octagon"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                      <span className="mx-2 fs-6">Cancelling Printing</span>
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <div className="clearfix d-flex justify-content-start">
                <img
                  width="400px"
                  src={logo}
                  alt="Logo Fondamenta"
                  className="px-5 pt-3 border-none"
                />
              </div>
              <div className="clearfix d-flex flex-wrap py-3">
                <img
                  align="start"
                  src={
                    macchinario.foto
                      ? "https://portale.fondamentasrl.it/file?file=" +
                        macchinario.foto.split("/")[1]
                      : ""
                  }
                  width="auto"
                  height={216}
                  className={`${macchinario.foto ? "mx-5" : " "}`}
                />

                <img
                  align="end"
                  src={qrcode}
                  width={216}
                  height={216}
                  className="mx-5"
                />
              </div>
              <div className="row d-flex mt-3">
                <label className="col-4 d-flex justify-content-end fs-4">
                  {" "}
                  <b>Matricola</b>{" "}
                </label>
                <span className="col-8 fs-4">{macchinario.matricola}</span>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Matricola costr</b>{" "}
                </label>
                <div className="col-8">{macchinario.matricola_costr}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Costruttore</b>{" "}
                </label>
                <div className="col-8">{}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Tipologia</b>{" "}
                </label>
                <div className="col-8">{macchinario.tipologia}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Descrizione</b>{" "}
                </label>
                <div className="col-8">{macchinario.descrizione}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Anno acquisto</b>{" "}
                </label>
                <div className="col-8">{macchinario.anno_acquisto}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Costo</b>{" "}
                </label>
                <div className="col-8">{macchinario.costo}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Qrcode</b>{" "}
                </label>
                <div className="col-8">{}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Documenti cdc</b>{" "}
                </label>
                <div className="col-8">{macchinario.documenti_cdc}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Documenti cdp</b>{" "}
                </label>
                <div className="col-8">{macchinario.documenti_cdp}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Documenti ass</b>{" "}
                </label>
                <div className="col-8">{macchinario.documenti_ass}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Documenti usoman</b>{" "}
                </label>
                <div className="col-8">{macchinario.documenti_usoman}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Fotoindirizzo</b>{" "}
                </label>
                <div className="col-8">{}</div>
              </div>
              <Divider />
              <div className="row d-flex">
                <label className="col-4 d-flex justify-content-end">
                  {" "}
                  <b>Field 17</b>{" "}
                </label>
                <div className="col-8">{}</div>
              </div>
            </div>
          </>
        );
        break;
      case "AccisaDettaglio":
        return (
          <>
            <CCardHeader>
              <div className="row d-flex align-content-center">
                <h6 className="col-4 align-self-center m-0">
                  Inserimento Accise
                </h6>
                <div
                  className="d-flex  justify-content-end col-8 d-print-none"
                  id="buttons"
                >
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => printPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <span className="mx-2 fs-6">Print</span>
                  </CButton>
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-octagon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="mx-2 fs-6">Cancelling Printing</span>
                  </CButton>
                </div>
              </div>
            </CCardHeader>
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Data</b>{" "}
              </label>
              <div className="col-8">
                {accisa.data}
              </div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Cantiere</b>{" "}
              </label>
              <div className="col-8">{accisa.cantiere}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Matricola</b>{" "}
              </label>
              <div className="col-8">{accisa.matricola}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>M giri</b>{" "}
              </label>
              <div className="col-8">{accisa.m_giri}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>K giri</b>{" "}
              </label>
              <div className="col-8">{accisa.k_giri}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Ore</b>{" "}
              </label>
              <div className="col-8">{accisa.ore}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Minuti</b>{" "}
              </label>
              <div className="col-8">{accisa.minuti}</div>
            </div>
          </>
        );
        break;
      case "ManutenzioneDettaglio":
        return (
          <>
            <CCardHeader>
              <div className="row d-flex align-content-center">
                <h6 className="col-4 align-self-center m-0">
                  Inserimento Manutenzioni
                </h6>
                <div
                  className="d-flex  justify-content-end col-8 d-print-none"
                  id="buttons"
                >
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => printPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <span className="mx-2 fs-6">Print</span>
                  </CButton>
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-octagon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="mx-2 fs-6">Cancelling Printing</span>
                  </CButton>
                </div>
              </div>
            </CCardHeader>
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Data</b>{" "}
              </label>
              <div className="col-8">
                {moment(manutenzione.data).format("YYYY-MM-DD")}
              </div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Cantiere</b>{" "}
              </label>
              <div className="col-8">{manutenzione.cantiere}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Matricola</b>{" "}
              </label>
              <div className="col-8">{manutenzione.matricola}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Descrizione</b>{" "}
              </label>
              <div className="col-8">{manutenzione.descrizione}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Tipo Manutenzione</b>{" "}
              </label>
              <div className="col-8">{manutenzione.manutenz_tipo}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Ore/Km</b>{" "}
              </label>
              <div className="col-8">{manutenzione.ore_km}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Note</b>{" "}
              </label>
              <div className="col-8">{manutenzione.note}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Manutenzione</b>{" "}
              </label>
              <div className="col-8">{manutenzione.manutenzione}</div>
            </div>
          </>
        );
        break;
      case "CantiereDettaglio":
        return (
          <>
            <CCardHeader>
              <div className="row d-flex align-content-center">
                <h6 className="col-4 align-self-center m-0">
                  Inserimento Cantieri
                </h6>
                <div
                  className="d-flex  justify-content-end col-8 d-print-none"
                  id="buttons"
                >
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => printPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <span className="mx-2 fs-6">Print</span>
                  </CButton>
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-octagon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="mx-2 fs-6">Cancelling Printing</span>
                  </CButton>
                </div>
              </div>
            </CCardHeader>
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end fs-4">
                {" "}
                <b>Commessa:</b>{" "}
              </label>
              <span className="col-8 fs-4">{cantiere.commessa}</span>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Committente:</b>{" "}
              </label>
              <div className="col-8">{cantiere.committente}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Comune:</b>{" "}
              </label>
              <div className="col-8">{cantiere.comune}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Via:</b>{" "}
              </label>
              <div className="col-8">{cantiere.indirizzo}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Stato:</b>{" "}
              </label>
              <div className="col-8">{cantiere.stato}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Provincia:</b>{" "}
              </label>
              <div className="col-8">{cantiere.provincia}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Referente:</b>{" "}
              </label>
              <div className="col-8">{cantiere.referente}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Data inizio:</b>{" "}
              </label>
              <div className="col-8">
                {moment(cantiere.data_inizio).format("YYYY-MM-DD")}
              </div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Data fine:</b>{" "}
              </label>
              <div className="col-8">
                {moment(cantiere.data_fine).format("YYYY-MM-DD")}
              </div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>CIG:</b>{" "}
              </label>
              <div className="col-8">{cantiere.cig}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>CUP:</b>{" "}
              </label>
              <div className="col-8">{cantiere.cup}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Google map:</b>{" "}
              </label>
              <div className="col-8">{cantiere.googleIDE}</div>
            </div>
          </>
        );
        break;
      case "LavorazioneDettaglio":
        return (
          <>
            <CCardHeader>
              <div className="row d-flex align-content-center">
                <h6 className="col-4 align-self-center m-0">
                  Inserimento Lavorazioni
                </h6>
                <div
                  className="d-flex  justify-content-end col-8 d-print-none"
                  id="buttons"
                >
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => printPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <span className="mx-2 fs-6">Print</span>
                  </CButton>
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-octagon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="mx-2 fs-6">Cancelling Printing</span>
                  </CButton>
                </div>
              </div>
            </CCardHeader>
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Descrizione</b>{" "}
              </label>
              <div className="col-8">{lavorazione.descrizione}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Um</b>{" "}
              </label>
              <div className="col-8">{lavorazione.um}</div>
            </div>
          </>
        );
        break;
      case "NoleggioDettaglio":
        return (
          <>
            <CCardHeader>
              <div className="row d-flex align-content-center">
                <h6 className="col-4 align-self-center m-0">
                  Inserimento Noleggi
                </h6>
                <div
                  className="d-flex  justify-content-end col-8 d-print-none"
                  id="buttons"
                >
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => printPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <span className="mx-2 fs-6">Print</span>
                  </CButton>
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-octagon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="mx-2 fs-6">Cancelling Printing</span>
                  </CButton>
                </div>
              </div>
            </CCardHeader>
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>ID</b>{" "}
              </label>
              <div className="col-8">{noleggio.id}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Commessa</b>{" "}
              </label>
              <div className="col-8">{noleggio.commessa}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Settimana</b>{" "}
              </label>
              <div className="col-8">{noleggio.settimana}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Vasconi</b>{" "}
              </label>
              <div className="col-8">{noleggio.n_int1}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Cisterna Gasolio</b>{" "}
              </label>
              <div className="col-8">{noleggio.n_int2}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Impianto di miscelazione</b>{" "}
              </label>
              <div className="col-8">{noleggio.n_int3}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Silos</b>{" "}
              </label>
              <div className="col-8">{noleggio.n_int4}</div>
            </div>
          </>
        );
        break;
      case "ProduzioneDettaglio":
        return (
          <>
            <CCardHeader>
              <div className="row d-flex align-content-center">
                <h6 className="col-4 align-self-center m-0">
                  Inserimento Produzione
                </h6>
                <div
                  className="d-flex  justify-content-end col-8 d-print-none"
                  id="buttons"
                >
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => printPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <span className="mx-2 fs-6">Print</span>
                  </CButton>
                  <CButton
                    className="align-self-center btn btn-green mx-2 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-x-octagon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span className="mx-2 fs-6">Cancelling Printing</span>
                  </CButton>
                </div>
              </div>
            </CCardHeader>
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Attrezzatura</b>{" "}
              </label>
              <div className="col-8">{produzione.attrezzatura1}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Cantiere</b>{" "}
              </label>
              <div className="col-8">{produzione.cantiere}</div>
            </div>
            <Divider />
            <div className="row d-flex">
              <label className="col-4 d-flex justify-content-end">
                {" "}
                <b>Quantita 1</b>{" "}
              </label>
              <div className="col-8">{produzione.quantita1}</div>
            </div>
          </>
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="container mt-5">
      <Divider variant="middle" className="mt-5 mx-4" />
      <div className="my-5"></div>
      <div>
        <CCard card="id">
          {ready ? printData() : console.log("non pronto per stampa")}
        </CCard>
      </div>
    </div>
  );
};

export default PrintMacchinario;
