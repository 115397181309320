import React, { useEffect, useState } from "react";
import api from "./api";
import { useLocation, useNavigate } from "react-router-dom";
import { CButton, CSpinner, CFormSwitch, CRow } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import moment from "moment";

const manageFiles = async (e, cFile) => {
  let f = cFile.split("/");
  let file = f[1];
  await api
    .post(
      "api/ftp",
      { file: file, percorso: cFile, operazione: "prendi" },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    )
    .then((res) => {
      window.open("https://portale.fondamentasrl.it/file?file=" + file);
    });
};

const columns = [
  {
    field: "matricola",
    headerName: "Matricola",
    width: 90,

    sort: "asc",
  },
  {
    field: "tipologia",
    headerName: "Tipologia",
    width: 200,
  },
  {
    field: "marca",
    headerName: "Marca",
    width: 150,
  },
  {
    field: "modello",
    headerName: "Modello",
    width: 150,
  },
  {
    field: "chassis",
    headerName: "Chassis",
    width: 150,
  },
  {
    field: "targa",
    headerName: "Targa",
    width: 80,
  },
  {
    field: "anno_acquisto",
    headerName: "Anno acquisto",
    width: 80,
  },
  {
    field: "ore_tot",
    headerName: "Ore/Km",
    width: 80,
  },
  {
    field: "varie1",
    headerName: "ST",
    width: 30,
    renderCell: (params) =>
      params.row.varie1 !== null &&
      params.row.varie1 !== undefined &&
      params.row.varie1 !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.varie1)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "documenti_ce",
    headerName: "CE",
    width: 30,
    renderCell: (params) =>
      params.row.documenti_ce !== null &&
      params.row.documenti_ce !== undefined &&
      params.row.documenti_ce !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.documenti_ce)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "documenti_cdc",
    headerName: "cdc",
    width: 50,
    renderCell: (params) => {
      let scadenza_circ = params.row.scadenza_circolazione;
      if (moment(scadenza_circ).local().format("YYYY-MM-DD") === "1899-11-30") {
        scadenza_circ = null;
      }
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(scadenza_circ).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.documenti_cdc)}
          ></div>
        );
      } else if (
        params.row.documenti_cdc !== null &&
        params.row.documenti_cdc !== undefined &&
        params.row.documenti_cdc !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.documenti_cdc)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "documenti_ass",
    headerName: "ass",
    width: 50,
    renderCell: (params) => {
      let scadenza_ass = params.row.scadenza_assicurazione;
      if (moment(scadenza_ass).local().format("YYYY-MM-DD") === "1899-11-30") {
        scadenza_ass = null;
      }
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(scadenza_ass).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.documenti_cdc)}
          ></div>
        );
      } else if (
        params.row.documenti_ass !== null &&
        params.row.documenti_ass !== undefined &&
        params.row.documenti_ass !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.documenti_ass)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "documenti_usoman",
    headerName: "mum",
    width: 60,
    align: "center",
    renderCell: (params) =>
      params.row.documenti_usoman !== null &&
      params.row.documenti_usoman !== undefined &&
      params.row.documenti_usoman !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.documenti_usoman)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "documenti_catric",
    headerName: "cr",
    width: 20,
    align: "center",
    renderCell: (params) =>
      params.row.documenti_catric !== null &&
      params.row.documenti_catric !== undefined &&
      params.row.documenti_catric !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.documenti_catric)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "documenti_schel",
    headerName: "Sch_el",
    width: 70,
    align: "center",
    renderCell: (params) =>
      params.row.documenti_schel !== null &&
      params.row.documenti_schel !== undefined &&
      params.row.documenti_schel !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.documenti_schel)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "documenti_schidr",
    headerName: "Sch_id",
    width: 70,
    align: "center",
    renderCell: (params) =>
      params.row.documenti_schidr !== null &&
      params.row.documenti_schidr !== undefined &&
      params.row.documenti_schidr !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.documenti_schidr)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "documenti_schpneu",
    headerName: "Sch_pn",
    width: 70,
    align: "center",
    renderCell: (params) =>
      params.row.documenti_schpneu !== null &&
      params.row.documenti_schpneu !== undefined &&
      params.row.documenti_schpneu !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row.documenti_schpneu)}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "varie2",
    headerName: "VFC",
    width: 60,
    align: "center",
    renderCell: (params) => {
      let scadenza_var2 = params.row.scadenza_varie2;
      if (moment(scadenza_var2).local().format("YYYY-MM-DD") === "1899-11-30") {
        scadenza_var2 = null;
      }
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(scadenza_var2).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.varie2)}
          ></div>
        );
      } else if (
        params.row.varie2 !== null &&
        params.row.varie2 !== undefined &&
        params.row.varie2 !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.varie2)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
  },
  {
    field: "varie3",
    headerName: "VP",
    width: 50,
    align: "center",
    renderCell: (params) => {
      let scadenza_var3 = params.row.scadenza_varie3;
      if (moment(scadenza_var3).local().format("YYYY-MM-DD") === "1899-11-30") {
        scadenza_var3 = null;
      }
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(scadenza_var3).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.varie3)}
          ></div>
        );
      } else if (
        params.row.varie3 !== null &&
        params.row.varie3 !== undefined &&
        params.row.varie3 !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.varie3)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
  },
  {
    field: "packingList",
    headerName: "PL",
    width: 50,
    align: "center",
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.packingList).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.packingList)}
          ></div>
        );
      } else if (
        params.row.packingList !== null &&
        params.row.packingList !== undefined &&
        params.row.packingList !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.packingList)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
  },
];

const Macchinari = (props) => {
  const [macchinari, setMacchinari] = useState([]);
  const [macchinariVisibili, setMacchinariVisibili] = useState([]);
  const [macchinariVisibiliSearch, setMacchinariVisibiliSearch] = useState([]);
  const [macchinariSenzaVenduti, setMacchinariSenzaVenduti] = useState([]);
  const [macchinariSel, setMacchinariSel] = useState(null);
  const [macchinariScaduti, setMacchinariScaduti] = useState([]);
  const [macchinariManutScaduta, setMacchinariManutScaduta] = useState([]);
  const [macchinariCaricati, setMacchinariCaricati] = useState(false);
  const [qrcode, setQrcode] = useState([]);
  const [scaduti, setScaduti] = useState(false);
  const [venduti, setVenduti] = useState(false);
  const [qrcodePrint, setQrcodePrint] = useState(false);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const zipAllFiles = async (matricola) => {
    api
      .post(
        "api/allegati",
        {
          all: true,
          macchina: matricola,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", matricola + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  };
  const applyFilter = (e, val, macch) => {
    var macchinarioX = [];
    var i;
    for (i = 0; i < macch.length; i++) {
      if (
        macch[i].matricola?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].tipologia?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].marca?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].modello?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].chassis?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].targa?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].stato_attuale?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].anno_acquisto?.toString().includes(val.toLowerCase())
      ) {
        macchinarioX.push(macch[i]);
      }
    }
    setMacchinariVisibili(macchinarioX);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setSearchValue("");
    }
    var macchinarioX = [];
    var i;
    if (document.getElementById("flexCheckVenduti").checked) {
      for (i = 0; i < macchinari.length; i++) {
        if (
          macchinari[i].matricola
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].tipologia
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].marca
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].modello
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].chassis
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].targa
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].stato_attuale
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].anno_acquisto
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinari[i].cespite
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          macchinarioX.push(macchinari[i]);
        }
      }
    } else {
      for (i = 0; i < macchinariSenzaVenduti.length; i++) {
        if (
          macchinariSenzaVenduti[i].matricola
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].tipologia
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].marca
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].modello
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].chassis
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].targa
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].stato_attuale
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].anno_acquisto
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          macchinariSenzaVenduti[i].cespite
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          macchinarioX.push(macchinariSenzaVenduti[i]);
        }
      }
      setMacchinariVisibili(macchinarioX);
    }
  };
  const handleVendutiClick = (e) => {
    if (scaduti) {
      setScaduti(false);
      if (e.target.checked) {
        setMacchinariVisibili(macchinari);
      } else {
        setMacchinariVisibili(macchinariSenzaVenduti);
      }
      setVenduti(!venduti);
    } else {
      if (e.target.checked) {
        setMacchinariVisibili(macchinari);
      } else {
        setMacchinariVisibili(macchinariSenzaVenduti);
      }
      setVenduti(!venduti);
    }
  };
  const handleScadutiClick = (e) => {
    if (venduti) {
      setVenduti(false);
      e.target.checked
        ? setMacchinariVisibili(macchinariScaduti)
        : setMacchinariVisibili(macchinari);
      setScaduti(!scaduti);
    } else {
      e.target.checked
        ? setMacchinariVisibili(macchinariScaduti)
        : setMacchinariVisibili(macchinariSenzaVenduti);
      setScaduti(!scaduti);
    }
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      const resMacch = await api.get("api/macchinari", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const macchinari = resMacch.data.data;

      setMacchinari(macchinari);
      setMacchinariSenzaVenduti(
        macchinari.filter((macchina) => macchina.stato_attuale !== "Venduto")
      );
      if (location.state.search === "") {
        setMacchinariVisibili(
          macchinari.filter((macchina) => macchina.stato_attuale !== "Venduto")
        );
      } else {
        setSearchValue(location.state.search);
        applyFilter(
          "search",
          location.state.search,
          macchinari.filter((macchina) => macchina.stato_attuale !== "Venduto")
        );
      }

      //CONTROLLO
      let macchinaridataCorretta = macchinari.filter(
        (macchina) =>
          moment(macchina.scadenza_circolazione)
            .local()
            .format("YYYY-MM-DD") !== "1899-11-30" ||
          moment(macchina.scadenza_assicurazione)
            .local()
            .format("YYYY-MM-DD") !== "1899-11-30" ||
          moment(macchina.scadenza_varie2).local().format("YYYY-MM-DD") !==
            "1899-11-30" ||
          moment(macchina.scadenza_varie3).local().format("YYYY-MM-DD") !==
            "1899-11-30"
      );
      setMacchinariScaduti(
        macchinaridataCorretta.filter(
          (macchina) =>
            moment(new Date()).local().format("YYYY-MM-DD") >=
              moment(macchina.scadenza_circolazione)
                .local()
                .format("YYYY-MM-DD") ||
            moment(new Date()).local().format("YYYY-MM-DD") >=
              moment(macchina.scadenza_assicurazione)
                .local()
                .format("YYYY-MM-DD") ||
            moment(new Date()).local().format("YYYY-MM-DD") >=
              moment(macchina.scadenza_varie2).local().format("YYYY-MM-DD") ||
            moment(new Date()).local().format("YYYY-MM-DD") >=
              moment(macchina.scadenza_varie3).local().format("YYYY-MM-DD")
        )
      );
      setMacchinariCaricati(true);
      const resScaden = await api.get("api/scadenzaManutenzioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMacchinariManutScaduta(resScaden.data.data);
      setReady(true);
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
        <div
          className="form-check form-switch green"
          style={{ padding: "4px 5px", marginLeft: "1.5rem" }}
        >
          <input
            className={`form-check-input input-green `}
            style={{ backgroundColor: venduti ? "#009a3d" : "" }}
            type="checkbox"
            id="flexCheckVenduti"
            onClick={handleVendutiClick}
            checked={venduti}
          />
          <label
            className="form-check-label"
            for="flexSwitchCheckDefault"
            style={{ fontSize: "0.8125rem" }}
          >
            VENDUTI/DISMESSI
          </label>
        </div>
        <div
          className="form-check form-switch green"
          style={{ padding: "4px 5px", marginLeft: "1.5rem" }}
        >
          <input
            className={`form-check-input input-green `}
            style={{ backgroundColor: scaduti ? "#009a3d" : "" }}
            type="checkbox"
            id="flexCheckVenduti"
            onClick={handleScadutiClick}
            checked={scaduti}
          />
          <label
            className="form-check-label"
            for="flexSwitchCheckDefault"
            style={{ fontSize: "0.8125rem" }}
          >
            SCADUTI
          </label>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">MACCHINARI</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center row flex-wrap flex-md-nowrap justify-content-center">
            <div className="d-flex col-md-6 col-12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  value={searchValue}
                  onChange={handleChange}
                  placeholder="Cerca…"
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
                <div
                  style={{ cursor: "pointer" }}
                  className="input-group-prepend"
                  onClick={() => {
                    setSearchValue("");
                    return applyFilter("search", "", macchinari);
                  }}
                >
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-x-lg fs-6`}></i>
                  </span>
                </div>
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              className="mx-3 mt-md-0 mt-2"
              size="xxs"
              onClick={() =>
                navigate(`/home/macchinari/:id`, {
                  state: { macchinario: [], operazione: "Nuovo" },
                })
              }
              style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowInsert === 0
                    ? "None"
                    : "Block",
              }}
            />
            <ReusableButton
              className={`${macchinariSel ? "d-block" : "d-none"}`}
              text="ESPORTA"
              icon="cloud-download"
              size="xxs"
              onClick={() => {
                zipAllFiles(macchinariSel);
              }}
            />
            <ReusableButton
              text="QR"
              icon="printer"
              size="xxs"
              onClick={() =>
                qrcode.length === 0
                  ? (setSnack({
                      severity: "error",
                      messaggio: "Selezionare un macchinario",
                    }),
                    setOpenSnack(true))
                  : navigate(`/home/macchinari/print-qrcode`, {
                      state: { macchinari: qrcode },
                    })
              }
              style={{ display: qrcodePrint ? "block" : "none" }}
              className="mt-md-0 mt-2"
            />
          </div>
        </div>
        {macchinariVisibili.length === 0 && !macchinariCaricati ? (
          <p>
            {" "}
            <center>
              <CSpinner color="primary" />
            </center>
          </p>
        ) : (
          <>
            <Box
              className="p-3"
              sx={{
                height: 650,
                width: 1,
                "& .hot": {
                  backgroundColor: "#d14d5a",
                },
                "& .purple": {
                  backgroundColor: "#BF40BF",
                },
              }}
            >
              <DataGridPro
                sx={{ fontSize: 12 }}
                components={{ Toolbar: CustomToolbar }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={macchinariVisibili}
                columns={columns}
                getRowId={(row) => row.matricola}
                pageSize={20}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "matricola", sort: "desc" }],
                  },
                }}
                checkboxSelection
                disableSelectionOnClick
                getCellClassName={(params) => {
                  let scad_circ = params.row.scadenza_circolazione;
                  if (
                    moment(scad_circ).local().format("YYYY-MM-DD") ===
                    "1899-11-30"
                  ) {
                    scad_circ = null;
                  }
                  let scad_ass = params.row.scadenza_assicurazione;
                  if (
                    moment(scad_ass).local().format("YYYY-MM-DD") ===
                    "1899-11-30"
                  ) {
                    scad_ass = null;
                  }
                  let scad_var2 = params.row.scadenza_varie2;
                  if (
                    moment(scad_var2).local().format("YYYY-MM-DD") ===
                    "1899-11-30"
                  ) {
                    scad_var2 = null;
                  }
                  let scad_var3 = params.row.scadenza_varie3;
                  if (
                    moment(scad_var3).local().format("YYYY-MM-DD") ===
                    "1899-11-30"
                  ) {
                    scad_var3 = null;
                  }
                  if (
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(scad_circ).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(scad_ass).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(scad_var2).local().format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(scad_var3).local().format("YYYY-MM-DD")
                  ) {
                    return "hot";
                  }
                  if (ready) {
                    let scad = macchinariManutScaduta.filter(
                      (macc) => macc.matricola === params.row.matricola
                    );

                    if (scad[0]) {
                      return "purple";
                    }
                  }
                }}
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = macchinariVisibili.filter((row) =>
                    selectedIDs.has(row.matricola.toString())
                  );

                  setQrcodePrint(true);
                  setMacchinariSel(ids);
                  setQrcode(selectedRowData);
                }}
                onRowClick={async (params, event) => {
                  if (
                    params.row.foto !== "" &&
                    params.row.foto !== null &&
                    params.row.foto !== undefined
                  ) {
                    let f = params.row.foto.split("/");
                    let file = f[1];
                    await api.post(
                      "api/ftp",
                      {
                        file: file,
                        percorso: params.row.foto,
                        operazione: "prendi",
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "jwt"
                          )}`,
                        },
                      }
                    );
                  }
                  const dataMacchinario = macchinari.filter(
                    (macchinario) =>
                      macchinario.matricola === params.row.matricola
                  );
                  if (searchValue !== "") {
                    navigate(
                      `/home/macchinari/${dataMacchinario[0].matricola}`,
                      {
                        state: {
                          macchinario: dataMacchinario[0],
                          operazione: "Modifica",
                          search: searchValue,
                        },
                      }
                    );
                  } else {
                    navigate(
                      `/home/macchinari/${dataMacchinario[0].matricola}`,
                      {
                        state: {
                          macchinario: dataMacchinario[0],
                          operazione: "Modifica",
                          search: searchValue,
                        },
                      }
                    );
                  }
                }}
              />
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default Macchinari;
