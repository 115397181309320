import React, { useEffect, useState } from "react";
import api from "./api";
import { useLocation, useNavigate } from "react-router-dom";
import { CButton, CSpinner } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

import ReusableButton from "./Reusable/Buttons";

let caricaLavorazioni = [];

function renderData(elemento) {
  if (caricaLavorazioni.length === 0) {
    api
      .get("api/lavorazioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((res) => {
        caricaLavorazioni = res.data.data;
      });
  }

  for (let i = 0; i < caricaLavorazioni.length; i++) {
    if (caricaLavorazioni[i].id === elemento) {
      return <p>{caricaLavorazioni[i].descrizione}</p>;
    }
  }

  return <p>{elemento}</p>;
}

const columns = [
  {
    field: "commessa",
    headerName: "Commessa",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "committente",
    headerName: "Committente",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "comune",
    headerName: "Comune",
    width: 150,
    headerAlign: "center",
    align: "center",
  },

  {
    field: "indirizzo",
    headerName: "Indirizzo",
    width: 300,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "stato",
    headerName: "Stato",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "provincia",
    headerName: "Provincia",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "referente_descrizione",
    headerName: "Referente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data_inizio",
    headerName: "Data inizio",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data_fine",
    headerName: "Data fine",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "colore",
    headerName: "Colore",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div
        className="rounded"
        style={{
          width: "20px",
          height: "20px",
          backgroundColor:
            params.row.colore === "" ? `` : `${params.row.colore}`,
        }}
      ></div>
    ),
  },
];

const Cantieri = (props) => {
  const [cantieri, setCantieri] = useState([]);
  const [lavorazioni, setLavorazioni] = useState([]);
  const [cantieriVisibili, setCantieriVisibili] = useState([]);
  const [cantieriCaricati, setCantieriCaricati] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const applyFilter = (e, val, can) => {
    var cantieriX = [];
    var i;
    for (i = 0; i < can.length; i++) {
      if (
        can[i].commessa?.toString().includes(val) ||
        can[i].indirizzo?.toLowerCase().includes(val.toLowerCase()) ||
        can[i].committente?.toLowerCase().includes(val.toLowerCase()) ||
        can[i].referente?.toLowerCase().includes(val.toLowerCase()) ||
        can[i].referente?.toLowerCase().includes(val.toLowerCase()) ||
        can[i].comune?.toLowerCase().includes(val.toLowerCase())
      ) {
        cantieriX.push(can[i]);
      }
    }
    setCantieriVisibili(cantieriX);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setSearchValue("");
      setCantieriVisibili(cantieri);
    }
    var cantieriX = [];
    var i;
    for (i = 0; i < cantieri.length; i++) {
      if (
        cantieri[i].commessa?.toString().includes(e.target.value) ||
        cantieri[i].indirizzo
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        cantieri[i].committente
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        cantieri[i].referente
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        cantieri[i].referente
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        cantieri[i].comune?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        cantieriX.push(cantieri[i]);
      }
    }
    setCantieriVisibili(cantieriX);
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/cantieri", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const cantieri = res.data.data;
          setCantieri(cantieri);
          if (location.state.search === "") {
            setCantieriVisibili(cantieri);
          } else {
            setSearchValue(location.state.search);
            applyFilter("search", location.state.search, cantieri);
          }
          setCantieriCaricati(true);
        });
    };
    fetchData();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">CANTERI</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center  col">
            <div className="d-flex col-6 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  value={searchValue}
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className={`mx-3 ${
                JSON.parse(localStorage.getItem("permission")).cantieri
                  .allowEdit === 3
                  ? "d-flex"
                  : "d-none"
              }`}
              onClick={() =>
                navigate(`/home/cantieri/dettaglio-cantiere`, {
                  state: { cantiere: [], operazione: "Nuovo" },
                })
              }
              style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).cantieri
                    .allowInsert === 0
                    ? "None"
                    : "Block",
              }}
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={cantieriVisibili}
            columns={columns}
            getRowId={(row) => row.commessa}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              const dataCantiere = cantieri.filter(
                (cantiere) => cantiere.commessa === params.row.commessa
              );
              if (searchValue !== "") {
                navigate(`/home/cantieri/dettaglio-cantiere`, {
                  state: {
                    cantiere: dataCantiere[0],
                    operazione: "Modifica",
                    search: searchValue,
                  },
                });
              } else {
                navigate(`/home/cantieri/dettaglio-cantiere`, {
                  state: {
                    cantiere: dataCantiere[0],
                    operazione: "Modifica",
                    search: searchValue,
                  },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Cantieri;
