import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../index.scss";
import { removeLoginSession } from "./SafeRoute";
import GlobalSnackbar from "./Snackbar";

import logo from "../img/iconFondamenta.png";
import profile from "../img/profile-icon.jpg";
import { useEffect } from "react";

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [showCant, setShowCant] = useState(false);
  const [showAttr, setShowAttr] = useState(false);
  const [showPers, setShowPers] = useState(false);
  const [showAnag, setShowAnag] = useState(false);
  const [showAnagCant, setShowAnagCant] = useState(false);
  const [showAnagMacc, setShowAnagMacc] = useState(false);
  const [showAnagPers, setShowAnagPers] = useState(false);
  const [showProf, setShowProf] = useState(false);
  const permission = JSON.parse(localStorage.getItem("permission"));

  const navigate = useNavigate();
  const handleClick = (page) => {
    navigate(page, {
      state: { search: "" },
    });
    setVisible(false);
    setShowAnag(false);
    setShowAnagCant(false);
    setShowAnagMacc(false);
    setShowAnagPers(false);
  };
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const session = sessionStorage.getItem("SESSION");
  const local = localStorage.getItem("LOCAL");
  const name = localStorage.getItem("nomeUtente");
  const [screenSize, getDimension] = useState(window.innerWidth);
  const [openHeader, setOpenHeader] = useState(false);
  const setDimension = () => {
    getDimension(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const handleCloseMenu = () => {
    setOpenHeader(false);
    setShowCant(false);
    setShowAttr(false);
    setShowPers(false);
    setShowAnag(false);
    setShowProf(false);
    setShowAnagCant(false);
    setShowAnagMacc(false);
    setShowAnagPers(false);
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <header className="text-blue bg-light hide-print">
        <div className="bg-light py-0 fixed-top" style={{ width: screenSize }}>
          {screenSize <= 1200 ? (
            <>
              <nav className="navbar justify-content-between bg-light py-0">
                <div className="navbar-collapse" id="navbarSupportedContent">
                  <div className="d-flex justify-content-between px-3">
                    <a
                      className="navbar-brand"
                      onClick={() => handleClick("/home")}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        className=""
                        style={{ height: "50px", width: "50px" }}
                        src={logo}
                        alt="Fondamenta"
                      />
                    </a>
                    <i
                      className={`fs-1 btn p-2 bi ${
                        openHeader ? "bi-x-lg" : "bi-list"
                      }`}
                      onClick={() => setOpenHeader(!openHeader)}
                    ></i>
                  </div>
                  {openHeader ? (
                    <>
                      <ul
                        id="main"
                        className="d-flex row"
                        style={{ padding: 0 }}
                      >
                        <li
                          className="fs-4 flex-wrap justify-content-center "
                          onClick={() => setShowCant(!showCant)}
                          style={{ padding: 0 }}
                        >
                          Cantieri
                          <ul
                            className="d-flex col-12 justify-content-center px-0"
                            onClick={() => setShowCant(!showCant)}
                          >
                            {showCant ? (
                              <div className="py-2">
                                <li
                                  className="justify-content-center"
                                  onClick={() => {
                                    handleClick("/home/produzione");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.produzione.allowView === 0
                                        ? "None"
                                        : "flex",
                                  }}
                                >
                                  Produzione
                                </li>
                                <li
                                  className="justify-content-center"
                                  onClick={() => {
                                    handleClick("/home/costi");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.costi.allowView === 0
                                        ? "None"
                                        : "flex",
                                  }}
                                >
                                  Costi
                                </li>
                              </div>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                        <li
                          className="fs-4 flex-wrap justify-content-center"
                          onClick={() => setShowAttr(!showAttr)}
                        >
                          Attrezzature
                          <ul
                            className="d-flex col-12 justify-content-center px-0"
                            onClick={() => setShowAttr(!showAttr)}
                          >
                            {showAttr ? (
                              <div className="py-2">
                                <li
                                  className="justify-content-center"
                                  onClick={() => {
                                    handleClick("/home/macchinari");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.macchinari.allowView === 0
                                        ? "None"
                                        : "",
                                  }}
                                >
                                  Macchinari
                                </li>
                                <li
                                  className="justify-content-center"
                                  hidden={!showAttr}
                                  onClick={() => {
                                    handleClick("/home/accise");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.accise.allowView === 0
                                        ? "None"
                                        : "",
                                  }}
                                >
                                  Accise
                                </li>
                                <li
                                  className="justify-content-center"
                                  hidden={!showAttr}
                                  onClick={() => {
                                    handleClick("/home/manutenzioni");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.manutenzioni.allowView === 0
                                        ? "None"
                                        : "",
                                  }}
                                >
                                  Manutenzioni
                                </li>
                                <li
                                  hidden={!showAttr}
                                  className="justify-content-center"
                                  onClick={() => {
                                    handleClick("/home/noleggi");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.noleggi_int.allowView === 0
                                        ? "None"
                                        : "flex",
                                  }}
                                >
                                  Noleggi
                                </li>
                              </div>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                        <li
                          className="fs-4 flex-wrap justify-content-center"
                          onClick={() => setShowPers(!showPers)}
                        >
                          Personale
                          <ul className="d-flex col-12 justify-content-center px-0">
                            {showPers ? (
                              <div>
                                <li
                                  onClick={() => {
                                    handleClick("/home/personale");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.report.allowView === 0
                                        ? "None"
                                        : "Block",
                                  }}
                                >
                                  Report
                                </li>
                                <li
                                  onClick={() => {
                                    handleClick("/home/trpwco");
                                    handleCloseMenu();
                                  }}
                                  style={{
                                    display:
                                      permission.report.allowView === 0
                                        ? "None"
                                        : "Block",
                                  }}
                                >
                                  Contabilita' lavoratori
                                </li>
                              </div>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                        <li
                          className="fs-4 flex-wrap justify-content-center"
                          onClick={() => setShowAnag(!showAnag)}
                        >
                          Anagrafiche
                          <ul
                            className="d-flex col-12 justify-content-center px-0"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {showAnag ? (
                              <div className="py-1">
                                <li
                                  onClick={() => setShowAnagCant(!showAnagCant)}
                                  style={{
                                    display:
                                      permission.cantieri.allowView === 0
                                        ? "None"
                                        : "Block",
                                  }}
                                >
                                  Cantieri{" "}
                                  {!showAnagCant ? (
                                    <i class="bi bi-caret-down-fill"></i>
                                  ) : (
                                    <i class="bi bi-caret-up-fill"></i>
                                  )}
                                </li>
                                {showAnagCant ? (
                                  <>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/cantieri");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          permission.cantieri.allowView === 0
                                            ? "None"
                                            : "flex",
                                      }}
                                    >
                                      Cantieri
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/lavorazioni");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          permission.lavorazioni.allowView === 0
                                            ? "None"
                                            : "flex",
                                      }}
                                    >
                                      Lavorazioni
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/wbs");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      Wbs
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/referenti");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "flex",
                                      }}
                                    >
                                      Referenti
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/tipologia-costi");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      Costi
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <li
                                  onClick={() => setShowAnagMacc(!showAnagMacc)}
                                  style={{
                                    display:
                                      permission.cantieri.allowView === 0
                                        ? "None"
                                        : "Block",
                                  }}
                                >
                                  Attrezzature{" "}
                                  {!showAnagMacc ? (
                                    <i class="bi bi-caret-down-fill"></i>
                                  ) : (
                                    <i class="bi bi-caret-up-fill"></i>
                                  )}
                                </li>
                                {showAnagMacc ? (
                                  <>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick(
                                          "/home/elenco-manutenzioni"
                                        );
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "flex",
                                      }}
                                    >
                                      Manutenzioni
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/tipologia");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "flex",
                                      }}
                                    >
                                      Tipologie
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/meccanici");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      Meccanici
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <li
                                  onClick={() => setShowAnagPers(!showAnagPers)}
                                  style={{
                                    display:
                                      permission.cantieri.allowView === 0
                                        ? "None"
                                        : "Block",
                                  }}
                                >
                                  Personale{" "}
                                  {!showAnagPers ? (
                                    <i class="bi bi-caret-down-fill"></i>
                                  ) : (
                                    <i class="bi bi-caret-up-fill"></i>
                                  )}
                                </li>
                                {showAnagPers ? (
                                  <>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/mansioni");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      Mansioni
                                    </li>
                                    <li
                                      className="nav-li-mobile"
                                      onClick={() => {
                                        handleClick("/home/operatore");
                                        handleCloseMenu();
                                      }}
                                      style={{
                                        display:
                                          session === "user" || local === "user"
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      Operatori
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>

                        <ul className="p-0">
                          <li
                            className="flex-wrap justify-content-center px-3 py-2"
                            onClick={() => setShowProf(!showProf)}
                          >
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                border: "3px solid #009a3d",
                                padding: "0px",
                              }}
                              src={profile}
                              alt="Profile img"
                            />
                            <ul className="d-flex col-12 justify-content-center px-0">
                              {showProf ? (
                                <div>
                                  <li
                                    className="justify-content-center"
                                    onClick={() => {
                                      handleClick("/membership");
                                      handleCloseMenu();
                                    }}
                                  >
                                    Profilo
                                  </li>
                                  <li
                                    className="justify-content-center"
                                    onClick={() => {
                                      handleClick("/admin-page");
                                      handleCloseMenu();
                                    }}
                                    style={{
                                      display:
                                        session === "user" || local === "user"
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    Admin Area
                                  </li>
                                  <li
                                    className="justify-content-center"
                                    onClick={() => {
                                      handleClick("/");
                                      removeLoginSession();
                                      window.location.reload(false);
                                    }}
                                  >
                                    Esci
                                  </li>
                                </div>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </li>
                        </ul>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </nav>
            </>
          ) : (
            <nav className="navbar navbar-expand-lg justify-content-between bg-light py-0">
              <div
                className="navbar-collapse flex-grow-0"
                id="navbarSupportedContent"
              >
                <a
                  className="navbar-brand"
                  onClick={() => handleClick("/home")}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="mx-4"
                    style={{ height: "50px", width: "50px" }}
                    src={logo}
                    alt="Fondamenta"
                  />
                </a>
                <ul id="main">
                  <li
                    className="fs-4 fs-sm-1"
                    onMouseEnter={() => setShowCant(true)}
                    onMouseLeave={() => setShowCant(false)}
                  >
                    Cantieri
                    <ul className="drop" onMouseEnter={() => setShowCant(true)}>
                      <div>
                        <li
                          onClick={() => handleClick("/home/produzione")}
                          style={{
                            display:
                              permission.produzione.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Produzione
                        </li>
                        <li
                          hidden={!showCant}
                          onClick={() => handleClick("/home/costi")}
                          style={{
                            display:
                              permission.costi.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Costi
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li
                    className="fs-4"
                    onMouseEnter={() => setShowAttr(true)}
                    onMouseLeave={() => setShowAttr(false)}
                  >
                    Attrezzature
                    <ul className="drop">
                      <div>
                        <li
                          onClick={() => handleClick("/home/macchinari")}
                          style={{
                            display:
                              permission.macchinari.allowView === 0
                                ? "None"
                                : "",
                          }}
                        >
                          Macchinari
                        </li>
                        <li
                          hidden={!showAttr}
                          onClick={() => handleClick("/home/accise")}
                          style={{
                            display:
                              permission.accise.allowView === 0 ? "None" : "",
                          }}
                        >
                          Accise
                        </li>
                        <li
                          hidden={!showAttr}
                          onClick={() => handleClick("/home/manutenzioni")}
                          style={{
                            display:
                              permission.manutenzioni.allowView === 0
                                ? "None"
                                : "",
                          }}
                        >
                          Manutenzioni
                        </li>
                        <li
                          hidden={!showAttr}
                          onClick={() => handleClick("/home/noleggi")}
                          style={{
                            display:
                              permission.noleggi_int.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Noleggi
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li
                    className="fs-4"
                    onMouseEnter={() => setShowPers(true)}
                    onMouseLeave={() => setShowPers(false)}
                  >
                    Personale
                    <ul className="drop">
                      <div>
                        <li
                          onClick={() => {
                            handleClick("/home/personale");
                            handleCloseMenu();
                          }}
                          style={{
                            display:
                              permission.report.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Report
                        </li>
                        <li
                          onClick={() => {
                            handleClick("/home/trpwco");
                            handleCloseMenu();
                          }}
                          style={{
                            display:
                              permission.report.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Contabilita' lavoratori
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li
                    className="fs-4"
                    onMouseEnter={() => setShowAnag(true)}
                    onMouseLeave={() => setShowAnag(false)}
                  >
                    Anagrafiche
                    <ul className="drop">
                      <div>
                        <li
                          onClick={() => setShowAnagCant(!showAnagCant)}
                          style={{
                            display:
                              permission.cantieri.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Cantieri{" "}
                          {!showAnagCant ? (
                            <i class="bi bi-caret-down-fill"></i>
                          ) : (
                            <i class="bi bi-caret-up-fill"></i>
                          )}
                        </li>
                        <ul>
                          <div>
                            <li
                              hidden={!showAnagCant}
                              onClick={() => handleClick("/home/cantieri")}
                              style={{
                                display:
                                  permission.cantieri.allowView === 0
                                    ? "None"
                                    : "Block",
                              }}
                            >
                              Cantieri
                            </li>
                            <li
                              hidden={!showAnagCant}
                              onClick={() => handleClick("/home/lavorazioni")}
                              style={{
                                display:
                                  permission.lavorazioni.allowView === 0
                                    ? "None"
                                    : "Block",
                              }}
                            >
                              Lavorazioni
                            </li>
                            <li
                              hidden={!showAnagCant}
                              onClick={() => handleClick("/home/wbs")}
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Wbs
                            </li>
                            <li
                              hidden={!showAnagCant}
                              onClick={() => handleClick("/home/referenti")}
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Referenti
                            </li>
                            <li
                              hidden={!showAnagCant}
                              onClick={() =>
                                handleClick("/home/tipologia-costi")
                              }
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Costi
                            </li>
                          </div>
                        </ul>
                        <li
                          hidden={!showAnag}
                          onClick={() => setShowAnagMacc(!showAnagMacc)}
                          style={{
                            display:
                              permission.cantieri.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Attrezzature{" "}
                          {!showAnagMacc ? (
                            <i class="bi bi-caret-down-fill"></i>
                          ) : (
                            <i class="bi bi-caret-up-fill"></i>
                          )}
                        </li>
                        <ul hidden={!showAnag}>
                          <div>
                            <li
                              hidden={!showAnagMacc}
                              onClick={() =>
                                handleClick("/home/elenco-manutenzioni")
                              }
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Manutenzioni
                            </li>
                            <li
                              hidden={!showAnagMacc}
                              onClick={() => handleClick("/home/tipologia")}
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Tipologie
                            </li>
                            <li
                              hidden={!showAnagMacc}
                              onClick={() => handleClick("/home/meccanici")}
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Meccanici
                            </li>
                          </div>
                        </ul>
                        <li
                          hidden={!showAnag}
                          onClick={() => setShowAnagPers(!showAnagPers)}
                          style={{
                            display:
                              permission.cantieri.allowView === 0
                                ? "None"
                                : "Block",
                          }}
                        >
                          Personale{" "}
                          {!showAnagPers ? (
                            <i class="bi bi-caret-down-fill"></i>
                          ) : (
                            <i class="bi bi-caret-up-fill"></i>
                          )}
                        </li>
                        <ul hidden={!showAnag}>
                          <div>
                            <li
                              hidden={!showAnagPers}
                              onClick={() => handleClick("/home/mansioni")}
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Mansioni
                            </li>
                            <li
                              hidden={!showAnagPers}
                              onClick={() => handleClick("/home/operatore")}
                              style={{
                                display:
                                  session === "user" || local === "user"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Operatori
                            </li>
                          </div>
                        </ul>
                      </div>
                    </ul>
                  </li>

                  <div id="marker"></div>
                </ul>
              </div>
              <ul id="mainProfile">
                <li
                  className="px-4"
                  onMouseEnter={() => setShowProf(true)}
                  onMouseLeave={() => setShowProf(false)}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "3px solid #009a3d",
                    }}
                    src={profile}
                    alt="Profile img"
                  />
                  <ul className="dropProfile">
                    <div>
                      <li onClick={() => handleClick("/membership")}>
                        Profilo
                      </li>
                      <li
                        hidden={!showProf}
                        onClick={() => handleClick("/admin-page")}
                        style={{
                          display:
                            session === "user" || local === "user"
                              ? "none"
                              : "",
                        }}
                      >
                        Admin Area
                      </li>
                      <li
                        hidden={!showProf}
                        /* style={{
                        display: !showProf ? "None" : "Block",
                      }} */
                        onClick={() => {
                          handleClick("/");
                          removeLoginSession();
                          window.location.reload(false);
                        }}
                      >
                        Esci
                      </li>
                    </div>
                  </ul>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
