import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CFormLabel, CFormInput, CFormFloating } from "@coreui/react";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import { ControlledButton } from "./Reusable/ControlledButton";
import { useRef } from "react";
import { DetailControlled } from "./Reusable/DetailControlled";

const updateValue = (state, action) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        [action.payload.filed]: action.payload.value,
      };
    case "loadData":
      return action.payload.data;
  }
  return state;
};

const initialState = { id: "", descrizione: "" };

const DettaglioMeccanici = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [meccanico, dispatch] = useReducer(updateValue, initialState);
  const { id, descrizione } = meccanico || {}; //è una condizione per cui -> se vuoto restituirà un oggetto vuoto
  const operazione = useRef(location.state.operazione);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la meccanici?"
    );
    if (isExecuted) {
      await api.delete("/api/meccanici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: meccanico.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Meccanico eliminato con successo",
      });
      setOpenSnack(true);
    }
  };
  const handleChange = (e) => {
    dispatch({
      type: "update",
      payload: { filed: e.target.name, value: e.target.value },
    });
  };
  const handleConfirmButton = async () => {
    if (operazione.current === "Modifica") {
      await api
        .put(
          "api/meccanici",
          {
            meccanici: meccanico,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then(() => {
          setSnack({ severity: "success", messaggio: "Modifica effettuata" });
          setOpenSnack(true);
        });
    } else {
      await api
        .post(
          "api/meccanici",
          { meccanici: meccanico },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then(() => {
          operazione.current = "Modifica";
          //ottengo l'id dell'elemento appena inserito
          setSnack({
            severity: "success",
            messaggio: "Inserimento effettuato",
          });
          setOpenSnack(true);
        });
    }
  };
  useEffect(() => {
    (async () => {
      if (operazione.current === "Modifica") {
        dispatch({
          type: "loadData",
          payload: { data: location.state.meccanici },
        });
      }
    })(); //le parentesi rotonde esattamente dopo la funzione asincrona sono per eseguirla subitos
  }, []); //funzione che permette di caricare i dati una volta che si è caricato il component

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <DetailControlled
        headerText="Inserimento meccanici"
        closeModal={() => {
          if (location.state.search !== undefined) {
            navigate("/home/meccanici", {
              state: {
                search: location.state.search,
              },
            });
          } else {
            navigate(-1);
          }
        }}
        footerRight={
          <>
            <ControlledButton
              text="ELIMINA"
              icon="trash"
              size="xs"
              className={`bg-red ${
                operazione.current === "Nuovo" ? "d-none" : "d-flex"
              }`}
              onClick={() => handleDeleteButton()}
            />
            <ReusableButton
              text="SALVA"
              icon="check"
              size="xs"
              onClick={() => handleConfirmButton()}
            />
          </>
        }
      >
        <div className="col">
          <CFormFloating className="mb-3">
            <CFormInput
              type="text"
              name="descrizione"
              id="DescrizioneInput"
              value={descrizione}
              onChange={handleChange}
            />
            <CFormLabel>Descrizione</CFormLabel>
          </CFormFloating>
        </div>
      </DetailControlled>
    </>
  );
};

export default DettaglioMeccanici;
