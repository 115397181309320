import { Component, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
  CCol,
  CCollapse,
} from "@coreui/react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import enLocale from "date-fns/locale/en-IE";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
import ReusableButton from "./Reusable/Buttons";

import { saveAs } from "file-saver";
import { Backdrop, CircularProgress } from "@mui/material";

const StoricoNoleggi = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [noleggio, setNoleggio] = useState({
    id: "",
    id_noleggi: location.state.noleggio.id,
    data_inizio: null,
    data_fine: null,
    cantiere: "",
    tariffa: "",
    contratto: "",
  });
  const [visibile, setVisibile] = useState({
    contratto: false,
  });
  const [cantieri, setCantieri] = useState([]);
  const [searchReady, setSearchReady] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const manageFiles = async (name) => {
    let perc = noleggio.contratto;
    let fileName =
      noleggio.matricola +
      "_contratto_" +
      moment(noleggio.data_inizio).format("YYYY-MM-DD") +
      ".pdf";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        //window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
        var oReq = new XMLHttpRequest();
        // The Endpoint of your server
        var URLToPDF = "https://portale.fondamentasrl.it/file?file=" + fileName;
        // Configure XMLHttpRequest
        oReq.open("GET", URLToPDF, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
          // Once the file is downloaded, open a new window with the PDF
          // Remember to allow the POP-UPS in your browser
          var filee = new Blob([oReq.response], {
            type: "application/pdf",
          });

          // Generate file download directly in the browser !
          saveAs(filee, fileName);
        };

        oReq.send();
      });
  };
  const viewFiles = async (name) => {
    let perc = noleggio.contratto;
    let fileName =
      noleggio.matricola +
      "_contratto_" +
      moment(noleggio.data_inizio).format("YYYY-MM-DD") +
      ".pdf";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
      });
  };
  const displayNone = () => {
    if (
      JSON.parse(localStorage.getItem("permission")).noleggi_int.allowView === 0
    ) {
      return "none";
    } else {
      return "block";
    }
  };
  const loadFile = async (filex, matricola) => {
    setOpenBackdrop(true);

    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", matricola);
    //formData.append("data", data);
    formData.append(
      "nomeManutenzione",
      matricola +
        "_contratto_" +
        moment(noleggio.data_inizio).format("YYYY-MM-DD") +
        ".pdf"
    );
    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        setOpenBackdrop(false);
        setSnack({
          severity: "success",
          messaggio: "Documento caricato con successo!",
        });
        setOpenSnack(true);
        let nol = { ...noleggio };
        nol.contratto =
          "/" +
          matricola +
          "/" +
          matricola +
          "_contratto_" +
          moment(noleggio.data_inizio).format("YYYY-MM-DD") +
          ".pdf";
        nol["data_inizio"] = moment(noleggio.data_inizio).format("YYYY-MM-DD");
        nol["data_fine"] = moment(noleggio.data_fine).format("YYYY-MM-DD");

        await api.put(
          "api/storicoNoleggi",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", storicoNoleggi: nol },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        //await reloadData();
      });
  };
  const reloadData = async () => {
    const res = await api.get("api/storicoNoleggi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: noleggio.id_noleggi },
    });
    const noleggiFiltered = res.data.data.filter((el) => el.id === noleggio.id);
    setNoleggio(noleggiFiltered[0]);
  };

  const handleChange = (event) => {
    let nol = noleggio;
    nol[event.target.name] = event.target.value;
    setNoleggio(nol);
  };

  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare lo Storico Noleggio?"
    );
    if (isExecuted) {
      await api.delete("/api/storicoNoleggi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: noleggio.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Noleggio eliminato con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  };

  const handleConfirmButton = async () => {
    try {
      let nol = { ...noleggio };
      nol["data_inizio"] = noleggio.data_inizio ? moment(noleggio.data_inizio).format("YYYY-MM-DD") : null;
      nol["data_fine"] = noleggio.data_fine ? moment(noleggio.data_fine).format("YYYY-MM-DD") : null;
      if (location.state.operazione === "Nuovo") {
        const response = await api.post(
          "api/storicoNoleggi",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", storicoNoleggi: nol },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      } else {
        await api.put(
          "api/storicoNoleggi",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", storicoNoleggi: nol },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      }
      setSnack({
        severity: "success",
        messaggio: "Noleggio salvato con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore nel salvataggio dei dati",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/cantieri", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const cantieri = res.data.data.map((cantiere) => cantiere.commessa);
          setCantieri(cantieri);
        });
      setSearchReady(true);

      if (location.state.operazione !== "Nuovo") {
        const noleggio = location.state.noleggio;
        setNoleggio(noleggio);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">
              {location.state.operazione === "Nuovo"
                ? "Inserimento Stoico Noleggio"
                : `Modifica Stoico Noleggio: ${location.state.noleggio?.matricola}`}
            </span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>

          {/* BODY */}
          <div className="card-body">
            <div className="d-flex col-md-12 flex-md-nowrap flex-wrap mb-md-3 mb-2">
              <CFormFloating className="col-md-6 col-12">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enLocale}
                >
                  <DatePicker
                    label="Data Inizio"
                    value={noleggio.data_inizio}
                    renderInput={(params) => (
                      <TextField {...params} className="col-12" />
                    )}
                    onChange={(newValue) => {
                      let nol = { ...noleggio };
                      nol["data_inizio"] = moment(newValue)
                        .local()
                        .format("YYYY-MM-DD");
                      setNoleggio(nol);
                    }}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).accise
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </LocalizationProvider>
              </CFormFloating>
              <CFormFloating className="col-md-6 col-12">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enLocale}
                >
                  <DatePicker
                    label="Data Fine"
                    value={noleggio.data_fine}
                    renderInput={(params) => (
                      <TextField {...params} className="col-12" />
                    )}
                    onChange={(newValue) => {
                      let nol = { ...noleggio };
                      nol["data_fine"] = moment(newValue)
                        .local()
                        .format("YYYY-MM-DD");
                      setNoleggio(nol);
                    }}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).accise
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </LocalizationProvider>
              </CFormFloating>
            </div>

            <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
              <div className="row d-flex flex-nowrap gx-0 col-md-6 col-12">
                <CFormFloating
                  className="mb-md-3 mb-2 col-12 col-md-12"
                  style={{
                    height: "100%",
                    width:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "100%"
                        : "",
                  }}
                >
                  {searchReady ? (
                    <Autocomplete
                      value={noleggio.cantiere}
                      onChange={(event, newValue) => {
                        let acc = { ...noleggio };
                        acc["cantiere"] = newValue;
                        setNoleggio(acc);
                      }}
                      id="controllable-states-demo"
                      options={cantieri}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Cantiere" />
                      )}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).accise
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  ) : (
                    ""
                  )}
                </CFormFloating>
                {/* <CButton
                  className="btn btn-green mb-md-3 mb-2 col-2 col-md-1"
                  style={{
                    display:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "none"
                        : "block",
                  }}
                  onClick={() =>
                    navigate(`/home/cantieri/dettaglio-cantiere`, {
                      state: { cantiere: [], operazione: "Nuovo" },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </CButton> */}
              </div>
              <CFormFloating className="mb-md-3 mb-2 col-md-6 col-12">
                <CFormInput
                  type="number"
                  name="tariffa"
                  defaultValue={noleggio.tariffa}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).accise
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>Tariffa Mensile</CFormLabel>
              </CFormFloating>
            </div>
            <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
              <div className="p-1 d-flex col-md-12 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                <div className="d-none mb-3">
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={(e) => {
                      loadFile(e.target.files[0], noleggio.matricola);
                    }}
                  />
                </div>
                <CFormLabel
                  className={`col-form-label text-start ${
                    visibile.contratto ? "col-md-2 col-4" : "col-md-2 col-4"
                  }`}
                >
                  Contratto
                </CFormLabel>
                <CButton
                  className="btn btn-secondary rounded"
                  onClick={() => manageFiles(noleggio.contratto)}
                  style={{
                    display: noleggio.contratto ? "block" : "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </CButton>
                <CButton
                  className="btn btn-secondary rounded"
                  onClick={() => viewFiles(noleggio.contratto)}
                  style={{
                    display: noleggio.contratto ? "block" : "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>
                </CButton>
                {/* <CButton
                  className={`btn btn-secondary rounded ${
                    noleggio.contratto ? "d-block" : "d-none"
                  }`}
                  style={{ display: displayNone() }}
                  onClick={() =>
                    setVisibile({
                      ...visibile,
                      contratto: !visibile.contratto,
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pen"
                    viewBox="0 0 16 16"
                  >
                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                  </svg>
                </CButton> */}

                {/* Import */}
                <CButton
                  className="btn btn-secondary rounded"
                  onClick={(e) => {
                    document.getElementById("formFile").click();
                  }}
                  style={{
                    display:
                      location.state.operazione === "Nuovo"
                        ? "none"
                        : JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        ? "none"
                        : !noleggio.contratto
                        ? "block"
                        : "none",
                  }}
                >
                  <i className="bi bi-upload"></i>
                </CButton>
                {/* <CCollapse
                  visible={visibile.contratto}
                  className="col-md col-12"
                >
                  <CCol>
                    <CFormInput
                      type="text"
                      name="contratto"
                      defaultValue={noleggio.contratto}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                      }
                    />
                  </CCol>
                </CCollapse> */}
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="d-flex justify-content-start gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="STAMPA"
                icon="printer"
                size="xxs"
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                onClick={() => {
                  let accDate = { ...noleggio };
                  accDate["data"] = moment(noleggio.data)
                    .local()
                    .format("YYYY-MM-DD");
                  navigate(`/home/macchinari/stampa/${noleggio.id}`, {
                    state: { noleggio: accDate, stampa: "NoleggioDettaglio" },
                  });
                }}
              />
            </div>
            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xs"
                className={`bg-red ${
                  location.state.operazione === "Nuovo"
                    ? "d-none"
                    : JSON.parse(localStorage.getItem("permission")).noleggi_int
                        .allowDelete === 1 &&
                      localStorage.getItem("nomeUtente") === noleggio.utente
                    ? "d-flex"
                    : JSON.parse(localStorage.getItem("permission")).noleggi_int
                        .allowDelete === 3
                    ? "d-flex"
                    : "d-none"
                }`}
                onClick={() => handleDeleteButton()}
              />
              {JSON.parse(localStorage.getItem("permission")).noleggi_int
                .allowEdit !== 0 ? (
                <>
                  <ReusableButton
                    text="SALVA"
                    icon="check"
                    size="xs"
                    onClick={() => handleConfirmButton()}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoricoNoleggi;
