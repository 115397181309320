import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
const TOKEN_KEY = "jwt";

const isLogged = () => {
  if (sessionStorage.getItem("SESSION") || localStorage.getItem("LOCAL")) {
    return true;
  } else {
    return false;
  }
};

const SafeRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const log = isLogged();
  if (log) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const loginSession = (token, userAdmin) => {
  if (userAdmin) {
    localStorage.setItem("jwt", token);
    sessionStorage.setItem("SESSION", "admin");
  } else {
    localStorage.setItem("jwt", token);
    sessionStorage.setItem("SESSION", "user");
  }
};

const loginLocal = (remember, token, userAdmin) => {
  if (userAdmin) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem("LOCAL", "admin");
    return remember;
  } else {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem("LOCAL", "user");
    return remember;
  }
};
const removeLoginSession = () => {
  sessionStorage.removeItem("SESSION");
  sessionStorage.clear();
  localStorage.removeItem("LOCAL");
  localStorage.clear();
};

export default SafeRoute;

export { isLogged, loginLocal, loginSession, removeLoginSession };
