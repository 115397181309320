import React, { useState } from "react";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import { DataGrid } from "@mui/x-data-grid";
import {itIT} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import ReusableButton from "./Reusable/Buttons";

// Define status colors
const statusColors = {
  "done": "status-done",
  "queued": "status-queued",
  "running": "status-running"
};

const handleDownload = async (fileName) => {
  const apiUrl = `/api/contabilita-lavoratori/download?filename=${fileName}`;
  try {
    const response = await api.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      responseType: "blob",
    })

    // Create a link to download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set the file name
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error during file download", error);
    alert("Si e' verificato un errore durante il download");
  }
}

const columns = [
  { field: 'taskName', headerName: 'Processo', width: 200 },
  {
    field: 'status',
    headerName: 'Stato',
    width: 200,
    renderCell: (params) => (
        <strong>
          <span className={`status-circle ${statusColors[params.row.status] || 'status-queued'}`}/>
        </strong>
    )
  },
  {
    field: '*',
    headerName: 'Download',
    width: 200,
    renderCell: (params) => (
          <strong>
            <Button
                variant="contained"
                size="small"
                style={{ marginLeft: 16 }}
                tabIndex={params.hasFocus ? 0 : -1}
                disabled={!params.row.filename}
                onClick={() => handleDownload(params.row.filename)}
            >
              Download
            </Button>
          </strong>
      )
  },
];

const ContabilitaLavoratori = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [tasks, setTasks] = useState([]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleFetchResult = async () => {
    try {
      const response = await api.get('/api/contabilita-lavoratori/process-result', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      if (response.status !== 200) { return alert("An error occurred while fetching the status.");}
      const result = await response.data;
      setTasks(result.message)
    } catch (error) {
      console.error("Error during fetching the results:", error);
      setSnack({
        severity: "error",
        messaggio: "Si e' verificato un errore durante il recupero dello stato dei processi.",
        open: false,
      });
      setOpenSnack(true)
    }
  };

  const handleSubmit = async () => {
    if (!file1 || !file2) {
      setSnack({
        severity: "error",
        messaggio: "Occorre caricate entrambi i file.",
        open: false,
      });
      setOpenSnack(true)
      return;
    }

    const formData = new FormData();
    formData.append('bilMovDip', file1);
    formData.append('ripartizioni', file2);

    try {
      const response = await api.post('/api/contabilita-lavoratori/process-files',
          formData,
          {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          contentType: "multipart/form-data",
        },
        });
      if (response.status === 200) {
        setOpenSnack(true)
        await handleFetchResult()
      } else {
        setSnack({
          severity: "error",
          messaggio: "Si e' verificato un errore durante il caricamento.",
          open: false,
        });
        setOpenSnack(true)
      }
    } catch (error) {
      console.error("Error during upload:", error);
      setSnack({
        severity: "error",
        messaggio: "Si e' verificato un errore durante il caricamento.",
        open: false,
      });
      setOpenSnack(true)
    }
  };

  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">Contabilita' lavoratori</span>
        </div>
        <div
            className="card-header position-relative py-3"
            style={{
              backgroundColor: "white",
              borderBottom: "0px solid rgba(0,0,0,.125)",
            }}
        >
          <div className="d-flex flex-row align-items-start">
            <div className="mb-6">
              <label htmlFor="formFile" className="form-label text-left fw-bold">
                File Bill Mov Dip
              </label>
              <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept=".csv"
                  onChange={(e) => handleFileChange(e, setFile1)}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="formFile" className="form-label text-left fw-bold">
                File Ripartizione
              </label>
              <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept=".csv"
                  onChange={(e) => handleFileChange(e, setFile2)}
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-start">
            <div className="d-flex gap-3 mb-3">
              <ReusableButton
                  text="CARICA FILES"
                  icon="upload"
                  size="xxs"
                  className=""
                  onClick={handleSubmit}
              />
              <ReusableButton
                  text="AGGIORNA STATO"
                  icon="search"
                  size="xxs"
                  className=""
                  onClick={handleFetchResult}
              />
            </div>
          </div>
          <div className="mb-12">
            <h4>Stato Processi</h4>
            <Box sx={{height: 400, width: '100%'}}>
              <DataGrid
                  localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                  rows={tasks || []}
                  columns={columns}
                  getRowId={(row) => row.taskName}
                  pageSize={10}
                  disableSelectionOnClick
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContabilitaLavoratori;
