import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CButton } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import ReusableButton from "./Reusable/Buttons";
const columns = [
  {
    field: "id",
    headerName: "Id",
    width: 50,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "produzione1",
    headerName: "Produzione 1",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];

const Report = () => {
  const [report, setReport] = useState([]);
  const navigate = useNavigate();
  const handleClick = (percorso) => {
    navigate(percorso);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">REPORT</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center  col">
            <div className="d-flex col-6 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  //onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className="mx-3"
              /* onClick={() =>
                navigate(`/home/produzione/dettaglio-produzione`, {
                  state: { produzione: [], operazione: "Nuovo" },
                })
              } */
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={report}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              /* const dataAccise = accise.filter((accisa) => accisa.matricola === params.row.matricola)
                            navigate(`/home/accise/${dataAccise[0].matricola}`, {state: {accise: dataAccise[0], operazione: "Modifica"}}) */
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Report;
