import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const GraficaNew = () => {
    const [openProfile, setOpenProfile] = useState(true);
    const [burgerMenu, setBurgerMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
  
    const handleClick = (link) => {
      navigate(`/${link}`, { state: link });
    };
    useEffect(() => {
      const fetchData = async () => {};
      fetchData();
    }, []);
    return (
      <div className="min-h-full">
        <nav className="bg-black ">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0" onClick={() => navigate("/home")}>
                  <img
                    className="h-10 w-10"
                    //src={require("../assets/images/Logo-chiaro.png")}
                    alt="Miller"
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    <a
                      onClick={() => handleClick("lavoratori")}
                      className={
                        location.state === "lavoratori"
                          ? "selectedNav"
                          : "otherNav"
                      }
                    >
                      Lavoratori
                    </a>
                    <a
                      onClick={() => handleClick("medicina")}
                      className={
                        location.state === "medicina" ? "selectedNav" : "otherNav"
                      }
                    >
                      Medicina
                    </a>
                    <a
                      onClick={() => handleClick("corsi")}
                      className={
                        location.state === "corsi" ? "selectedNav" : "otherNav"
                      }
                    >
                      Corsi
                    </a>
                    <a
                      onClick={() => handleClick("documenti")}
                      className={
                        location.state === "documenti"
                          ? "selectedNav"
                          : "otherNav"
                      }
                    >
                      Documenti
                    </a>
                    <a
                      onClick={() => handleClick("sopralluoghi")}
                      className={
                        location.state === "sopralluoghi"
                          ? "selectedNav"
                          : "otherNav"
                      }
                    >
                      Sopralluoghi
                    </a>
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {/* <!-- Profile dropdown --> */}
                  <div className="relative ml-3">
                    <div>
                      <button
                        onClick={() => setOpenProfile(!openProfile)}
                        type="button"
                        className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          //src={require("../assets/images/profile-icon.jpg")}
                          alt=""
                        />
                      </button>
                    </div>
                    <div
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-out duration-100 transform opacity-100 scale-100"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                      id="profile"
                      hidden={openProfile}
                    >
                      {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-0"
                      >
                        Profilo
                      </a>
  
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-1"
                      >
                        Impostazioni
                      </a>
  
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                        onClick={() => navigate("/login")}
                      >
                        Esci
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  
              {/* <!-- Mobile menu button --> */}
              <div className="-mr-2 flex md:hidden">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => setBurgerMenu(!burgerMenu)}
                >
                  <svg
                    hidden={burgerMenu}
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
  
                  <svg
                    hidden={!burgerMenu}
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
  
          {/* <!-- Mobile menu, show/hide based on menu state. --> */}
          <div className="md:hidden" id="mobile-menu" hidden={!burgerMenu}>
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3 ">
              {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
              <a
                onClick={() => handleClick("lavoratori")}
                className={
                  location.state === "lavoratori" ? "selectedNav" : "otherNav"
                }
              >
                Lavoratori
              </a>
              <a
                onClick={() => handleClick("medicina")}
                className={
                  location.state === "medicina" ? "selectedNav" : "otherNav"
                }
              >
                Medicina
              </a>
              <a
                onClick={() => handleClick("corsi")}
                className={
                  location.state === "corsi" ? "selectedNav" : "otherNav"
                }
              >
                Corsi
              </a>
              <a
                onClick={() => handleClick("documenti")}
                className={
                  location.state === "documenti" ? "selectedNav" : "otherNav"
                }
              >
                Documenti
              </a>
              <a
                onClick={() => handleClick("sopralluoghi")}
                className={
                  location.state === "sopralluoghi" ? "selectedNav" : "otherNav"
                }
              >
                Sopralluoghi
              </a>
            </div>
            <div className="border-t border-gray-700 pt-4 pb-3">
              <div className="flex items-center px-5 justify-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    //src={require("../assets/images/profile-icon.jpg")}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white ">
                    Tom Cook
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                <a href="#" className="otherNav">
                  Profilo
                </a>
  
                <a href="#" className="otherNav">
                  Impostazioni
                </a>
  
                <a
                  onClick={() => navigate("/login")}
                  href="#"
                  className="otherNav"
                >
                  Esci
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="p-10 w-screen"> 
        <Outlet />
  
        </div>
  
      </div>
  );
};

export default GraficaNew;
