import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./api";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CFormInput,
  CFormLabel,
  CFormFloating,
  CFormCheck,
  CFormTextarea,
} from "@coreui/react";
import { CContainer } from "@coreui/react";
import "../index.scss";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";

const DettaglioGruppi = () => {
  const [group, setGroup] = useState({});
  const [groupPermission, setGroupPermission] = useState([]);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleClick = async (page) => {
    navigate(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (location.state.operazione === "Modifica") {
        await api
          .get("api/groupsPermissions", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { id: location.state.gruppo.groupID },
          })
          .then((res) => {
            setGroupPermission(res.data.data);
          });

        setGroup(location.state.gruppo);
      } else {
        setGroupPermission([
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "accise",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "cantieri",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "produzione",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "costi",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "noleggi_int",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "macchinari",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "manutenzioni",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "report",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "lavorazioni",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "voci_costo",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "voci_noleggi",
          },
          {
            allowDelete: 0,
            allowEdit: 0,
            allowInsert: 0,
            allowView: 0,
            groupID: 0,
            permissionID: 0,
            tableName: "admin_page",
          },
        ]);

        setGroup({
          name: "",
          description: "",
          allowSignup: "",
          needsApproval: "",
        });
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    let copiaGroup = { ...group };
    copiaGroup[e.target.name] = e.target.value;

    setGroup(copiaGroup);
  };

  const handleCheckClick = (e, index) => {
    let copiaGroup = [...groupPermission];
    copiaGroup[index]["allowInsert"] = e.target.checked === false ? 0 : 1;
    setGroupPermission(copiaGroup);
  };

  const handleRadioClick = (e, index, tipologia) => {
    let copiaGroup = [...groupPermission];
    copiaGroup[index][tipologia] = parseInt(e.target.value);
    setGroupPermission(copiaGroup);
  };

  const handleSaveButton = async () => {
    try {
      if (location.state.operazione === "Modifica") {
        location.state.gruppo = group;
        await api.put(
          "api/groups",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", group: group },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        for (let i = 0; i < groupPermission.length; i++) {
          await api.put(
            "api/groupsPermissions",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              group: groupPermission[i],
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
        }
        alert("Modifica effettuata");
      } else {
        const response = await api.post(
          "api/groups",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", group: group },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        let groupID = response.data.message.insertId;

        for (let i = 0; i < groupPermission.length; i++) {
          var gp = groupPermission[i];
          gp.groupID = groupID;

          await api.post(
            "api/groupsPermissions",
            { auth: "a5d42c3ed7977afa2d8e922569e7940b", group: gp },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
        }
        alert("Inserimento effettuato");
        navigate(-1);
      }
    } catch {
      alert("Errore nel salvataggio");
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">
              {location.state.operazione} {group.name}
            </span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.state.search !== undefined) {
                  navigate("/home/macchinari", {
                    state: {
                      search: location.state.search,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>

          {/* BODY */}
          <div className="card-body">
            <CContainer>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="name"
                  value={group.name}
                />
                <CFormLabel>Group name</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormTextarea
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="description"
                  value={group.description}
                />
                <CFormLabel>Description</CFormLabel>
              </CFormFloating>
              <h4>Tabella permissioni gruppo: {group.name}</h4>
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Table</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Insert</CTableHeaderCell>
                    <CTableHeaderCell scope="col">View</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Edit</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Delete</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {groupPermission.map((group, index) => (
                    <CTableRow key={group.tableName}>
                      <CTableDataCell>{group.tableName}</CTableDataCell>
                      <CTableDataCell>
                        <CFormCheck
                          id="flexCheckDefault"
                          label=""
                          onChange={(e) => handleCheckClick(e, index)}
                          checked={group.allowInsert === 1 ? true : false}
                        />
                      </CTableDataCell>
                      <CTableDataCell>
                        <CFormCheck
                          type="radio"
                          name={`view ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowView")
                          }
                          id={`view ${group.tableName}0`}
                          value="0"
                          label="No"
                          checked={group.allowView === 0 ? true : false}
                        />
                        <CFormCheck
                          type="radio"
                          name={`view ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowView")
                          }
                          id={`view ${group.tableName}1`}
                          value="1"
                          label="Owner"
                          checked={group.allowView === 1 ? true : false}
                        />
                        {/*<CFormCheck type="radio" name={`view ${group.tableName}`} onChange={(e) => handleRadioClick(e,index,"allowView")}  id={`view ${group.tableName}2`} value="2" label="Group" checked={group.allowView===2?true:false}/>*/}
                        <CFormCheck
                          type="radio"
                          name={`view ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowView")
                          }
                          id={`view ${group.tableName}3`}
                          value="3"
                          label="All"
                          checked={group.allowView === 3 ? true : false}
                        />
                      </CTableDataCell>
                      <CTableDataCell>
                        <CFormCheck
                          type="radio"
                          name={`edit ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowEdit")
                          }
                          id={`edit ${group.tableName}0`}
                          value="0"
                          label="No"
                          checked={group.allowEdit === 0 ? true : false}
                        />
                        <CFormCheck
                          type="radio"
                          name={`edit ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowEdit")
                          }
                          id={`edit ${group.tableName}1`}
                          value="1"
                          label="Owner"
                          checked={group.allowEdit === 1 ? true : false}
                        />
                        {/*<CFormCheck type="radio" name={`edit ${group.tableName}`} onChange={(e) => handleRadioClick(e,index,"allowEdit")} id={`edit ${group.tableName}2`} value="2" label="Group" checked={group.allowEdit===2?true:false}/>*/}
                        <CFormCheck
                          type="radio"
                          name={`edit ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowEdit")
                          }
                          id={`edit ${group.tableName}3`}
                          value="3"
                          label="All"
                          checked={group.allowEdit === 3 ? true : false}
                        />
                      </CTableDataCell>
                      <CTableDataCell>
                        <CFormCheck
                          type="radio"
                          name={`delete ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowDelete")
                          }
                          id={`delete ${group.tableName}0`}
                          value="0"
                          label="No"
                          checked={group.allowDelete === 0 ? true : false}
                        />
                        <CFormCheck
                          type="radio"
                          name={`delete ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowDelete")
                          }
                          id={`delete ${group.tableName}1`}
                          value="1"
                          label="Owner"
                          checked={group.allowDelete === 1 ? true : false}
                        />
                        {/*<CFormCheck type="radio" name={`delete ${group.tableName}`} onChange={(e) => handleRadioClick(e,index,"allowDelete")} id={`delete ${group.tableName}2`} value="2" label="Group" checked={group.allowDelete===2?true:false}/>*/}
                        <CFormCheck
                          type="radio"
                          name={`delete ${group.tableName}`}
                          onChange={(e) =>
                            handleRadioClick(e, index, "allowDelete")
                          }
                          id={`delete ${group.tableName}3`}
                          value="3"
                          label="All"
                          checked={group.allowDelete === 3 ? true : false}
                        />
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CContainer>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
            <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap"></div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              {/* <button
                className="btn bg-green text-white fs-6"
                onClick={() => handleSaveButton()}
              >
                <i className="bi bi-check fs-5"></i> SALVA
              </button> */}
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleSaveButton()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DettaglioGruppi;
